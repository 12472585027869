import { Type } from '@smu-chile/pkg-unimarc-components'
import { TTokens } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ITokens'
import { TFontSize } from '@smu-chile/pkg-unimarc-components/shared/interfaces/TFontSize'
import { useContentful } from '@smu-chile/pkg-unimarc-hooks'

import {
  IMAGE_EMPTY_ORDERS,
  IMAGE_EMPTY_ORDERS_NOT_FOUND,
  IMAGE_EMPTY_ORDERS_REJECTED
} from 'shared/constants'

interface GetSettingsEmpty {
  backgroundColor?: TTokens
  borderRadius?: TTokens
  columnWidth?: string
  fontSize?: TFontSize
  imageCF?: string
  marginDesktop?: string
  paddingDesktop?: string
  textButton?: string
  textPrimary?: string
  textSecondary?: string
  typeButton?: Type
}

export const useImageOrder = () => {
  return useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-orders',
    options: {
      'sys.id': IMAGE_EMPTY_ORDERS
    },
    type: 'assets'
  })
}

export const useImageOrderNotFound = () => {
  return useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-orders-not-found',
    options: {
      'sys.id': IMAGE_EMPTY_ORDERS_NOT_FOUND
    },
    type: 'assets'
  })
}

export const useImageOrderRejected = () => {
  return useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-orders-rejected',
    options: {
      'sys.id': IMAGE_EMPTY_ORDERS_REJECTED
    },
    type: 'assets'
  })
}

export const getSettingsEmpty = (
  numberOrderMinimun: boolean,
  typeOrder: boolean,
  orderNumber: string,
  isMobile: boolean,
  { ...props }
): GetSettingsEmpty => {
  const {
    borderRadiusCondition,
    columnWidthCondition,
    fontSize,
    marginDesktopCondition,
    paddingDesktopCondition,
    isMobileSmall
  } = props

  if ((typeOrder === true && orderNumber.length > 0) || numberOrderMinimun) {
    return {
      backgroundColor: '--color-background-white',
      borderRadius: borderRadiusCondition,
      columnWidth: columnWidthCondition,
      fontSize: fontSize,
      imageCF: 'not-found',
      marginDesktop: marginDesktopCondition,
      paddingDesktop: paddingDesktopCondition,
      textButton: 'Ver todas mis compras',
      textPrimary: '¡Oh! No encontramos tu compra',
      textSecondary:
        'Revisa que esté escrito correctamente e inténtalo nuevamente.'
    }
  }

  //default settings
  return {
    backgroundColor: '--color-background-white',
    borderRadius: isMobile ? '--border-radius-none' : '--border-radius-md',
    columnWidth: isMobile ? '100' : '78',
    fontSize: isMobileSmall ? 'sm' : 'md',
    imageCF: typeOrder ? 'order' : 'rejected',
    marginDesktop: isMobile ? '0' : '18px 0px',
    paddingDesktop: isMobile ? '0' : '24px 32px 0px',
    textButton: 'Descubre las ofertas',
    textPrimary: typeOrder
      ? 'Aún no realizas compras'
      : 'No tienes compras rechazadas',
    textSecondary: typeOrder
      ? 'Compra de manera fácil y rápida y visualiza aquí el detalle de cada una de tus compras.'
      : 'Aquí podrás visualizar y repetir tus compras que no pudieron ser concretadas.',
    typeButton: 'primary-gray'
  }
}
