import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { v4 as uuidv4 } from 'uuid'
import {
  Column,
  Container,
  Divider,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { NavItem } from './components/NavItem'
import {
  orderEntriesById,
  useSession,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import { getCFOptions } from './helper/getCFOptions'
import { SideNavLoader } from './SideNavLoader'
import { ConnectionToCFSidenav } from 'shared/utils/connectionToCFSidenav'

export const SideNav = (): React.ReactElement => {
  const router = useRouter()
  const [sideNavOptions, setSideNavOptions] = useState([])
  const [showMembershipMenu, setShowMembershipMenu] = useState(true)
  const { isLoggedIn, isLoading } = useSession()
  const { data: dataMembership, isLoading: isLoadingMembership } =
    useUserWithMembership()

  useEffect(() => {
    if (
      dataMembership?.error === 'not found' ||
      dataMembership?.status === 404 ||
      dataMembership?.status === 400 ||
      dataMembership?.status.toString() === '404' ||
      dataMembership?.status.toString() === '400' ||
      (!dataMembership?.membership && !isLoadingMembership)
    ) {
      setShowMembershipMenu(false)
    }
  }, [dataMembership, isLoadingMembership])

  const { cfData, isFetchedCF } = ConnectionToCFSidenav()

  const entriesSorted = orderEntriesById(cfData)

  useEffect(() => {
    if (entriesSorted?.length > 0) {
      const leakedData = getCFOptions(entriesSorted, router.pathname)
      setSideNavOptions(leakedData)
    }
  }, [cfData, isFetchedCF])

  if ((isLoading && !isFetchedCF) || sideNavOptions?.length === 0) {
    return <SideNavLoader />
  }

  return (
    <>
      {sideNavOptions?.length > 0 &&
        sideNavOptions?.map(({ title, description, options }, idx) => {
          return title?.toUpperCase() === 'LOGOUT' && !isLoggedIn ? null : (
            <Container
              backgroundColor={getGlobalStyle('--color-background-white')}
              border={`0.3px solid ${getGlobalStyle(
                '--color-neutral-gray-divider'
              )}`}
              borderRadius={getGlobalStyle('--border-radius-md')}
              id={getBemId('sideNav', 'optionContainer', idx.toString())}
              justifyContent='center'
              key={uuidv4()}
              margin={idx === 0 ? '16px 0 0 0' : '24px 0 0 0'}
              maxWidth='90%'
              minWidth='253px'
              padding={title === 'logout' ? '0' : '20px 0 16px'}
            >
              <Row id={getBemId('sideNav', 'optionRow', idx.toString())}>
                <Column
                  id={getBemId('sideNav', 'optionColumn', idx.toString())}
                >
                  {title && title !== 'logout' && (
                    <Column
                      id={getBemId('sideNav', 'titleColumn', idx.toString())}
                      padding='0 12px'
                    >
                      <Text
                        color='black'
                        fontSize='xl'
                        fontWeight='medium'
                        id={getBemId('sideNav', 'titleText', idx.toString())}
                        underline='none'
                      >
                        {title}
                      </Text>
                      {description && (
                        <>
                          <Spacer.Horizontal size={12} />
                          <Text
                            customColor={getGlobalStyle(
                              '--color-neutral-gray-dark'
                            )}
                            fontSize='sm'
                            fontWeight='regular'
                            id={getBemId(
                              'sideNav',
                              'descriptionText',
                              idx.toString()
                            )}
                          >
                            {description}
                          </Text>
                        </>
                      )}
                      <Spacer.Horizontal size={16} />
                    </Column>
                  )}
                  {options.length &&
                    options.map((fields, index) => {
                      return (fields?.iconText?.toUpperCase() ===
                        'DIAMONDMEMBERSHIP' &&
                        !showMembershipMenu) ||
                        (fields?.iconText?.toUpperCase() === 'LOGOUT2' &&
                          !isLoggedIn) ? null : (
                        <React.Fragment key={uuidv4()}>
                          <NavItem
                            active={fields?.active}
                            icon={fields?.iconText}
                            isLogout={title === 'logout'}
                            labelColorBackground={fields?.labelColorBackground}
                            labelColorText={fields?.labelColorText}
                            labelText={fields?.labelNewText}
                            sendEvent={fields?.eventClick}
                            showLabel={fields?.showLabel}
                            strokeWidthIcon='1.2'
                            text={fields?.label}
                            url={fields?.url}
                          />
                          {index + 1 !== options.length && (
                            <Container
                              id={getBemId(
                                'sideNav',
                                'dividerContainer',
                                `${idx}-${index}`
                              )}
                              padding='0 12px'
                            >
                              <Divider
                                color={getGlobalStyle(
                                  '--color-neutral-gray-light'
                                )}
                                thickness={0}
                                verticalMargin={0}
                              />
                            </Container>
                          )}
                        </React.Fragment>
                      )
                    })}
                </Column>
              </Row>
            </Container>
          )
        })}
    </>
  )
}
