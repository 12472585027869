import { Column } from '@smu-chile/pkg-unimarc-components'

import { ProductDetails } from './ProductDetails'

import { ItemsSent } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

interface ShelfProps {
  brandName: string
  item: ItemsSent
  latest?: boolean
  price: number
}

export const Shelf = ({
  brandName,
  item,
  latest,
  price
}: ShelfProps): React.ReactElement => {
  return (
    <Column
      backgroundColor={getGlobalStyle('--color-base-white')}
      position='relative'
      tagName='article'
    >
      {/* Product Details */}
      <ProductDetails
        brandName={brandName}
        item={item}
        latest={latest}
        price={price}
      />
    </Column>
  )
}
