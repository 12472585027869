import { Column, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { eventClick } from '@smu-chile/pkg-unimarc-hooks'
import { IOptionSelect, SelectFilter } from 'components/SelectFilter'

interface OrderByOptions {
  label: string
  placeholder: string
  value: number
}
interface FiltersProps {
  orderBy: IOptionSelect
  orderByOptions: OrderByOptions[]
  onChangeOrder?: (order: 0 | 1) => void
  setOrderBy: (value: IOptionSelect) => void
}

export const FiltersMobile = ({
  orderBy,
  orderByOptions,
  onChangeOrder,
  setOrderBy
}: FiltersProps) => {
  const changeOrder = (value: IOptionSelect) => {
    onChangeOrder(value.value as 0 | 1)
    setOrderBy(value)
  }

  return (
    <Row
      alignItems='end'
      justifyContent='end'
      maxWidth='100'
      padding='0 0 12px'
    >
      <Column maxWidth='100'>
        <Row justifyContent='end'>
          <Column
            alignItems='center'
            borderEdge='right'
            maxWidth='98px'
          >
            <Text
              color='gray'
              fontSize='sm'
            >
              Ordenar por
            </Text>
          </Column>
          <Column
            maxWidth='130px'
            padding='0 5px'
          >
            <SelectFilter
              onChange={(option) => {
                eventClick({
                  event: 'interacciones-mis-compras',
                  eventCategory: 'ordenar-por',
                  event_label: option.value ? 'mas-antiguas' : 'mas-recientes'
                })
                return changeOrder(option)
              }}
              options={orderByOptions}
              value={orderBy}
            />
          </Column>
        </Row>
      </Column>
    </Row>
  )
}
