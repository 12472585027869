import { Text } from '@smu-chile/pkg-unimarc-components'

import { currencyFormat } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export interface PriceProps {
  price?: string
  totalPrice: number
}

export const Price = ({
  totalPrice
}: PriceProps): React.ReactElement<PriceProps> => {
  const { isMobile } = useMobile()
  const fontSizePrice = isMobile ? 'md' : 'lg'
  return (
    <>
      <Text
        fontSize={fontSizePrice}
        fontWeight='medium'
      >
        {currencyFormat({ number: totalPrice })}
      </Text>
    </>
  )
}
