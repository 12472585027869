export const PAYMENT_SYSTEMS = {
  WEB_PAY_PLUS: '30',
  DEBIT: '204',
  CREDIT: '202',
  GIFT_CARD: '16'
}

export const PAYMENT_UNIPAY = ['206', '207', '210']

export const PAYMENT_CARD_TYPE = [
  'redcompra',
  'prepago',
  'mastercard',
  'visa',
  'americanexpress',
  'Unipay con cuota',
  'Unipay sin cuota'
]

export const PAYMENT_WEB_PAY_NAMES = [
  'Webpay',
  'OneClick Crédito',
  'OneClick Débito'
]

export const KLAP_PAY_NAMES = [
  'Klap Debito',
  'Klap Débito',
  'Klap Credito',
  'Klap Crédito'
]

export const PAYMENT_GIFT_CARD_NAME = ['GiftCard', 'Vale']

export const BANK_STATE_PAYMENT = ['Banco Estado']
