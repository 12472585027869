import { ButtonProps, Container } from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { ButtonPagination } from './ButtonPagination'

interface ButtonsPaginationProps {
  buttons: number[]
  buttonsType?: ButtonProps['type']
  customClass?: string
  customClassSelected?: string
  selectedPage: number
  margin?: boolean
  onChangePage: (numberPage: number) => void
}

export const ButtonsPagination = ({
  buttons,
  buttonsType,
  customClass,
  customClassSelected,
  selectedPage,
  margin = false,
  onChangePage
}: ButtonsPaginationProps) => {
  const { isMobile, innerWidth } = useMobile()

  const logicButton = (): (number | string)[] => {
    if (buttons.length <= 5) {
      return buttons
    }
    if (selectedPage <= 3) {
      return [1, 2, 3, 0, buttons.length]
    }
    if (selectedPage >= buttons.length - 2) {
      return [1, 0, buttons.length - 2, buttons.length - 1, buttons.length]
    }
    return [
      1,
      '...',
      selectedPage - 1,
      selectedPage,
      selectedPage + 1,
      0,
      buttons.length
    ]
  }

  return (
    <>
      {logicButton().map((button) => {
        const threeDots = '0 7.5px'
        const lastButton = button === buttons.length ? '0 0 0 7.5px' : threeDots
        const firstButton = button === 1 ? '0 7.5px 0 0' : lastButton
        const marginMobile = isMobile ? '0 7.5px' : firstButton
        const marginMobileMedium = innerWidth <= 375 ? '0 5.5px' : marginMobile
        const marginMobileSmall =
          innerWidth <= 320 ? '0 3.5px' : marginMobileMedium
        const marginButton = margin ? marginMobileSmall : ''
        return (
          <Container
            key={button}
            margin={marginButton}
          >
            <ButtonPagination
              buttonsType={buttonsType}
              customClass={customClass}
              customClassSelected={customClassSelected}
              onClick={onChangePage}
              page={button}
              selected={selectedPage === button}
            />
          </Container>
        )
      })}
    </>
  )
}
