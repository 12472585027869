import { Text } from '@smu-chile/pkg-unimarc-components'

interface GetSettingsOrderDetailProps {
  addWebviewParamsInUrl: string
  marginMobile: string
  textTooltip: string | React.ReactElement
}

export const getSettingsOrderDetail = (
  innerWidth: number,
  openFeedbackReceipt: boolean,
  paramsApp: boolean | string,
  status: string
): GetSettingsOrderDetailProps => {
  const Tooltip = () => {
    if (openFeedbackReceipt)
      return (
        <TextTooltip
          nestedText='"En preparación".'
          text='Descarga tu boleta desde el estado '
        />
      )

    if (status === 'window-to-cancel')
      return (
        <TextTooltip
          nestedText='"Confirmada".'
          text='Podrás cancelar tu compra cuando se encuentre '
        />
      )

    return <TextTooltip text='¡Lo sentimos! Ya no puedes cancelar tu compra.' />
  }

  return {
    addWebviewParamsInUrl: !paramsApp ? '' : '&source=APP',
    marginMobile: paramsApp ? '-75px 14px' : '',
    textTooltip: <Tooltip />
  }
}

interface TextToolip {
  text: string
  nestedText?: string
}

const TextTooltip = ({ text = '', nestedText = '' }: TextToolip) => {
  return (
    <Text
      color='black'
      display='inline-block'
      fontSize='md'
      fontWeight='medium'
      type='span'
    >
      {text}
      {nestedText && (
        <Text
          color='black'
          display='inline-block'
          fontSize='md'
          fontWeight='semibold'
          truncate={22}
          type='span'
        >
          {nestedText}
        </Text>
      )}
    </Text>
  )
}
