import { Button, Column, Container } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useRouter } from 'next/router'
import { Entry, eventClick } from '@smu-chile/pkg-unimarc-hooks'

interface ButtonProps {
  selectOption: Entry
  sequence?: string
  status?: string
  setOpenModalConfirmate: React.Dispatch<React.SetStateAction<boolean>>
}
export const Buttons = ({
  selectOption,
  sequence,
  status,
  setOpenModalConfirmate
}: ButtonProps) => {
  const router = useRouter()
  const handleButtonClicked = (option: string) => {
    const { label } = selectOption?.fields || {}
    const eventProps = {
      event: 'interacciones_mis_compras',
      eventAction: 'clic',
      eventCategory: 'cancelar-compra',
      estado_compra: status,
      event_label: label,
      id_compra: sequence
    }
    eventClick(eventProps)
    eventClick({
      ...eventProps,
      eventCategory: 'cancelar-compra',
      event_label:
        (option == 'cancelar' && 'cancelar compra') ||
        (option == 'volver' && 'volver a mi compra')
    })
  }
  return (
    <Container isWrap>
      <BigScreen>
        <>
          <Container>
            <Column alignItems='end'>
              <Button
                borderRadius={getGlobalStyle('--border-radius-xl')}
                fontWeight='500'
                height='45px'
                label='Volver a mi compra'
                onClick={() => {
                  handleButtonClicked('volver')
                  router.back()
                }}
                size='md'
                type='secondary-gray'
              />
            </Column>
            <Column
              alignItems='end'
              customWidth='35'
            >
              <Button
                borderRadius={getGlobalStyle('--border-radius-xl')}
                fontWeight='500'
                height='45px'
                label='Cancelar compra'
                onClick={() => {
                  handleButtonClicked('cancelar')
                  setOpenModalConfirmate(true)
                }}
                size='md'
                status={selectOption?.fields?.label ? 'initial' : 'disabled'}
                type='primary-gray'
              />
            </Column>
          </Container>
        </>
      </BigScreen>
      <SmallScreen>
        <Container
          absoluteDefault='bottomRight'
          alignItems='center'
          backgroundColor={getGlobalStyle('--color-base-white')}
          border={`0.3px solid ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          customHeight='85px'
          customWidth='100'
          gap='10px'
          justifyContent='center'
          padding='20px 16px'
          position='fixed'
          zIndex={getGlobalStyle('--z-index-5')}
        >
          <Column alignItems='center'>
            <Button
              borderRadius={getGlobalStyle('--border-radius-xl')}
              fontWeight='500'
              height='45px'
              label='Cancelar compra'
              onClick={() => {
                handleButtonClicked('cancelar')
                setOpenModalConfirmate(true)
              }}
              size='md'
              status={selectOption?.fields?.label ? 'initial' : 'disabled'}
              type='primary-gray'
              width='100%'
            />
          </Column>
        </Container>
      </SmallScreen>
    </Container>
  )
}
