import router from 'next/router'
import { eventClick } from '@smu-chile/pkg-unimarc-hooks'

export const getSettingsOrderDetail = () => {
  const handleLogout = () => {
    eventClick({
      event: 'interacciones-mis-compras',
      eventCategory: 'menu',
      event_label: 'cerrar sesion'
    })
    router.push(
      `${process.env.NEXT_PUBLIC_HOMEURL}/?orderform_id=&sc=1&recatoken=&catoken=&idtoken=&userid=&masterdata_id=`
    )
  }

  const handleHomeEvent = () => {
    eventClick({
      event: 'interacciones-mis-compras',
      eventCategory: 'menu',
      event_label: 'mis compras'
    })
  }

  const handleListsEvent = () => {
    eventClick({
      event: 'interacciones-mis-compras',
      eventCategory: 'menu',
      event_label: 'mis listas'
    })
  }

  return {
    handleHomeEvent,
    handleListsEvent,
    handleLogout
  }
}
