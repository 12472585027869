import { IContentfulResponse } from '@smu-chile/pkg-unimarc-hooks'
import { IUserWithMembershipResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetUserWithMembership'

const MEMBERSHIP_LEVELS_BLOCK_IDS = {
  clubunimarc: 'Socio Club',
  diamante: 'Diamante',
  oro: 'Socio Oro',
  platino: 'Socio Platino',
  default: 'Sin nivel'
}

export interface ITransformMembershipDataCF {
  data: any
  imageSaving: any
  imageLevels: IContentfulResponse
  userMembership: IUserWithMembershipResponse
}

export const transformMembershipDataCF = ({
  data,
  imageSaving,
  imageLevels,
  userMembership
}: ITransformMembershipDataCF) => {
  const items = data?.items?.[0]?.fields?.items?.map((item) => {
    const entry = data?.includes?.Entry?.filter((filteredItem) => {
      return filteredItem?.sys?.id === item?.sys?.id
    })

    const subItems = entry?.[0]?.fields?.items?.map((filteredSubItem) => {
      const subEntry = data?.includes?.Entry?.filter((filteredItem) => {
        return filteredItem?.sys?.id === filteredSubItem?.sys?.id
      })[0]?.fields

      if (subEntry?.icon) {
        subEntry.icon = data.includes.Asset.filter((iconAsset) => {
          return subEntry?.icon?.sys?.id === iconAsset?.sys?.id
        })[0]
      }

      const subEntryAssets = subEntry?.items?.map((itemAsset) => {
        const subItem = data?.includes?.Entry?.filter((asset) => {
          return asset?.sys?.id === itemAsset?.sys?.id
        })
        return subItem.map((subItemAsset) => {
          return {
            img: data?.includes?.Asset?.filter((imgAsset) => {
              return imgAsset?.sys?.id === subItemAsset?.fields?.img?.sys?.id
            })?.[0]?.fields,
            imgMobile: data?.includes?.Asset?.filter((imgAsset) => {
              return (
                imgAsset?.sys?.id === subItemAsset?.fields?.imgMobile?.sys?.id
              )
            })?.[0]?.fields
          }
        })?.[0]
      })?.[0]

      return {
        ...subEntry,
        ...subEntryAssets
      }
    })

    return {
      title: entry[0]?.fields?.blockId,
      items: subItems
    }
  })

  const dataLevelUser = items?.[0]?.items?.filter((filteredItem) => {
    return (
      MEMBERSHIP_LEVELS_BLOCK_IDS[userMembership?.level] ===
      filteredItem?.blockId
    )
  })

  const levels = (
    imageLevels?.items?.[0]?.fields?.imageBanner as { sys: { id: string } }[]
  )?.map((item) => {
    const entry = imageLevels?.includes?.Entry?.filter((filteredItem) => {
      return filteredItem?.sys?.id === item?.sys?.id
    })?.[0]?.fields as { image: { sys: { id: string } } }
    return imageLevels?.includes?.Asset?.filter((asset) => {
      return entry?.image?.sys?.id === asset?.sys?.id
    })?.[0]?.fields
  })

  const membershipButtons = items?.[2]?.items?.filter((item) => {
    if (userMembership?.membership) {
      if (userMembership?.membership !== 'active') {
        return item?.['label'] === 'Renovar'
      }
    } else {
      return item?.['label'] === 'Ser diamante'
    }

    return false
  })?.[0]

  return {
    membershipLevels: dataLevelUser || null,
    headerButtons: items?.[1] || null,
    membershipButtons: membershipButtons || null,
    imageSaving: imageSaving?.['items'][0]?.fields?.file?.url || null,
    imageLevels: levels || null
  }
}

export const setMembershipData = (data) => {
  return {
    fontColor: data?.[0]?.fontColor,
    backgroundColor: data?.[0]?.backgroundColor,
    img: data?.[0]?.img?.file?.url,
    imgMoblie: data?.[0]?.imgMoblie?.file?.url,
    imgLabel: data?.[0]?.img?.title,
    userLevel: data?.[0]?.description || null
  }
}

export const setMembershipButtons = (data, currentSaves, onClickButton) => {
  return data?.map((item) => {
    return {
      icon: item?.icon?.fields?.file?.url,
      text: item?.label,
      iconLabel: item?.icon?.fields?.title,
      onClick: () => {
        onClickButton(item?.label.toLowerCase())
      },
      currentSaves: item?.label.toLowerCase().includes('ahorrado')
        ? currentSaves || '$ -'
        : null
    }
  })
}

export const setMembershipBlock = (data) => {
  return {
    icon: data?.[0]?.icon?.fields?.file?.url,
    textColor: data?.[0]?.labelColorText,
    labelText: data?.[0]?.labelNewText,
    backgroundColor: data?.[0]?.labelColorBackground,
    title: data?.[0]?.subtitulo,
    subtitle: data?.[0]?.subtitulo2,
    buttonLabel: data?.[0]?.buttonText
  }
}
