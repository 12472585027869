import { Fragment, useEffect, useState } from 'react'
import {
  Column,
  Container,
  Icon,
  Link,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  dataLayerUniqueTypeEvent,
  eventClick,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { IOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { Shelves } from './Shelves'
import { getTotalQuantityProducts } from 'shared/helpers'
import { IListItems } from 'shared/interfaces/IProductsList'
import { ImageCard } from '../ImageCard'
import { v4 as uuid } from 'uuid'
import { GenericImage } from '../GenericImage'
import orderStyles from '../../../../OrderDetail.module.css'
import styles from '../../../../../Components.module.css'

interface ListProductsProps {
  hideIcon?: boolean
  order: IOrder
  text?: string
}

export const statusPrePicking = [
  'Cancelada',
  'Solicitud recibida',
  'Confirmada',
  'Rechazada'
]

export const statusPostPicking = [
  'Retirada',
  'Entregada',
  'En preparación',
  'En camino',
  'Lista para retiro'
]

export const ListProducts = (props: ListProductsProps) => {
  const { isMobile } = useMobile()
  const [openOneResult, setOpenOneResult] = useState(false)
  const [showItems, setShowItems] = useState(false)
  const [showItemsSent, setShowItemsSent] = useState(false)
  const [showItemsAdded, setShowItemsAdded] = useState(false)
  const [showItemsRemoved, setShowItemsRemoved] = useState(false)
  const [itemsToShow, setItemsToShow] = useState([])

  const countItems = getTotalQuantityProducts(props.order.items)
  const countItemsSent = getTotalQuantityProducts(props.order.itemsSent)
  const countItemsAdded = getTotalQuantityProducts(props.order.itemsAdded)
  const countItemsRemoved = getTotalQuantityProducts(props.order.itemsRemoved)
  const paddingContainer = isMobile ? '16px 0 16px 12px' : '24px 32px'
  const paddingContainerEnd =
    window.innerWidth < 330 ? '10px 24px 0' : paddingContainer
  const fontSizeTitle = isMobile ? 'md' : 'xl'
  const customWith = isMobile ? '100' : '50'
  const linkCustomWidth = isMobile ? 100 : 75
  const customMinHeight = isMobile ? '136px' : '162px'
  const linkSize = isMobile ? 'md' : 'lg'
  const arrowSize = isMobile ? 20 : 24
  const paddingCarousel = isMobile ? '24px 0 0 0' : '36px 0 0 0'
  const sizeImageProp = isMobile ? '73px' : '83px'
  const marginContainer = isMobile ? '-3.5px 0px' : null
  const quantityImages = isMobile ? 7 : 8
  const showImagesNumber =
    props.order.items?.length === quantityImages
      ? quantityImages
      : quantityImages - 1

  useEffect(() => {
    getAllItems()
  }, [showItems, showItemsSent, showItemsAdded, showItemsRemoved])

  const getAllItems = () => {
    let newItems = []
    if (statusPrePicking.includes(props.text) && countItems > 0) {
      const items: IListItems = {
        items: props.order.items,
        quantity: countItems,
        showItems: showItems,
        tittleItems: 'Productos solicitados',
        showDetails: (show: boolean) => {
          return setShowItems(show)
        }
      }
      newItems = [...newItems, items]
    }

    if (statusPostPicking.includes(props.text)) {
      if (countItemsSent) {
        const itemsSent: IListItems = {
          items: props.order.itemsSent,
          quantity: countItemsSent,
          showItems: showItemsSent,
          tittleItems: 'Productos enviados',
          showDetails: (show: boolean) => {
            return setShowItemsSent(show)
          }
        }
        newItems = [...newItems, itemsSent]
      }

      if (countItemsAdded) {
        const itemsAdded: IListItems = {
          items: props.order.itemsAdded,
          quantity: countItemsAdded,
          showItems: showItemsAdded,
          tittleItems: 'Productos sustitutos',
          showDetails: (show: boolean) => {
            return setShowItemsAdded(show)
          }
        }
        newItems = [...newItems, itemsAdded]
      }

      if (countItemsRemoved) {
        const itemsRemoved: IListItems = {
          items: props.order.itemsRemoved,
          quantity: countItemsRemoved,
          showItems: showItemsRemoved,
          tittleItems: 'Productos no cobrados',
          showDetails: (show: boolean) => {
            return setShowItemsRemoved(show)
          }
        }
        newItems = [...newItems, itemsRemoved]
      }
    }
    // Show items with details if has one result, only on first load
    if (isMobile && newItems.length === 1 && !openOneResult) {
      newItems[0].showDetails(true)
      setOpenOneResult(true)
    }

    setItemsToShow(newItems)
  }

  useEffect(() => {
    dataLayerUniqueTypeEvent({
      id_compra: props?.order?.sequence,
      page: 'mis-compras-detalle-productos'
    })
  }, [])

  return (
    <Container
      clickable='pointer'
      id='container-products-list__my-account'
      isWrap
      justifyContent='center'
      tagName='section'
    >
      {Array.isArray(itemsToShow) &&
        itemsToShow.length > 0 &&
        itemsToShow.map(
          ({ items, quantity, showItems, tittleItems, showDetails }) => {
            return (
              <Fragment key={tittleItems}>
                <Spacer.Horizontal size={12} />
                <Container
                  alignItems='center'
                  backgroundColor={getGlobalStyle('--color-base-white')}
                  border={`0.3px solid ${getGlobalStyle(
                    '--color-neutral-gray-divider'
                  )}`}
                  borderRadius={getGlobalStyle('--border-radius-md')}
                  clickable='pointer'
                  customHeight='auto'
                  isWrap
                  justifyContent='center'
                  minHeight={customMinHeight}
                  onClick={() => {
                    !showItems &&
                      eventClick({
                        event: 'interacciones-mis-compras',
                        eventCategory: 'mostrar-todos-los-productos'
                      })
                    return showDetails(!showItems)
                  }}
                  padding={paddingContainerEnd}
                  tagName='section'
                >
                  <Row
                    alignItems='center'
                    clickable='pointer'
                    margin={marginContainer}
                  >
                    <Column
                      clickable='pointer'
                      customWidth={customWith}
                    >
                      <Row clickable='pointer'>
                        <Text
                          clickable='pointer'
                          customColor={getGlobalStyle('--color-base-black')}
                          fontSize={fontSizeTitle}
                          fontWeight='medium'
                        >
                          {tittleItems}
                        </Text>
                        <Spacer.Vertical size={4} />
                        <Text
                          clickable='pointer'
                          customColor={getGlobalStyle(
                            '--color-text-gray-light'
                          )}
                          fontSize={fontSizeTitle}
                        >
                          ({quantity})
                        </Text>
                      </Row>
                    </Column>
                    <BigScreen>
                      <Column
                        clickable='pointer'
                        width='100%'
                      >
                        <Spacer.Horizontal
                          backgroundColor={getGlobalStyle(
                            '--color-background-gray'
                          )}
                          size={1}
                        />
                      </Column>
                    </BigScreen>
                    <Column
                      alignItems='end'
                      width={linkCustomWidth}
                    >
                      <Spacer.Vertical size={8} />
                      <Link
                        color={getGlobalStyle('--color-alvi-neutral-black')}
                        fontSize={linkSize}
                        href='#'
                        onClick={() => {
                          !showItems &&
                            eventClick({
                              event: 'interacciones-mis-compras',
                              eventCategory: 'mostrar-todos-los-productos'
                            })
                          return showDetails(!showItems)
                        }}
                      >
                        {showItems ? (
                          <Container padding='0 8px 0 0'>
                            <Row className={styles.containerIconArrowOpen}>
                              <Icon
                                clickable='pointer'
                                color={getGlobalStyle(
                                  '--color-neutral-gray-dark'
                                )}
                                customSize={arrowSize}
                                name='ArrowRightNavigate2'
                              />
                            </Row>
                          </Container>
                        ) : (
                          <Container padding='0 8px 0 0'>
                            <Row
                              className={styles.containerIconArrowDown}
                              margin='-5px 0 0 -12px'
                            >
                              <Icon
                                clickable='pointer'
                                color={getGlobalStyle(
                                  '--color-neutral-gray-dark'
                                )}
                                customSize={arrowSize}
                                name='ArrowRightNavigate2'
                              />
                            </Row>
                          </Container>
                        )}
                      </Link>
                    </Column>
                  </Row>
                  {showItems ? (
                    <Container
                      clickable='pointer'
                      isWrap
                      justifyContent='center'
                      padding='0 12px 0 0'
                    >
                      <Shelves items={items} />
                    </Container>
                  ) : (
                    <Row
                      className={`${orderStyles.listContainer} ${orderStyles.withSpacer}`}
                      clickable='pointer'
                      padding={paddingCarousel}
                    >
                      {items.map((item, index) => {
                        const { imageUrl } = item

                        if (
                          (!isMobile && index < showImagesNumber) ||
                          (isMobile && index < showImagesNumber)
                        ) {
                          return (
                            <Fragment key={uuid()}>
                              <ImageCard
                                hasURL={true}
                                imageSize={sizeImageProp}
                                imageUrl={imageUrl}
                              />
                            </Fragment>
                          )
                        }
                        return true
                      })}
                      {quantity > showImagesNumber &&
                        props?.order?.items?.length > quantityImages && (
                          <GenericImage quantity={quantity} />
                        )}
                    </Row>
                  )}
                </Container>
              </Fragment>
            )
          }
        )}
    </Container>
  )
}
