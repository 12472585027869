interface SettingsGlobalProps {
  arrowSize: number
  paddingContainer: string
  paddingDivider: string
  rowHeight: string
  widthColum: string
  widthColumLoading: string
}

export const getSettingsGlobal = (isMobile: boolean): SettingsGlobalProps => {
  return {
    arrowSize: isMobile ? 20 : 24,
    paddingContainer: isMobile ? '0' : '13px 32px',
    paddingDivider: isMobile ? '0' : '0 32px',
    rowHeight: isMobile ? '52px' : '82px',
    widthColum: isMobile ? '4' : '2',
    widthColumLoading: isMobile ? '7' : '3'
  }
}
