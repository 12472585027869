import { Text } from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export const SearchResult = () => {
  const { isMobile } = useMobile()
  const fontSize = isMobile ? 'md' : 'base'

  return (
    <Text
      color='grayNeutral'
      fontSize={fontSize}
    >
      1 resultado de búsqueda
    </Text>
  )
}
