import { IEntriesProps } from '@smu-chile/pkg-unimarc-hooks'
import { ID_STORE_CONTENT_FUL_NOT_FOUND } from 'shared/constants'

export const getTitleStore = (dataContent:IEntriesProps,courierNameNumber:string) : string =>{
  let titleStore=ID_STORE_CONTENT_FUL_NOT_FOUND
  if(dataContent?.includes?.Entry){
    dataContent?.includes?.Entry.map((item)=>{
      if(item?.fields?.visitCardId === courierNameNumber){
        titleStore=item?.fields?.title
      }
    })
  }
  return titleStore
}