import {
  Column,
  Container,
  Icon,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  useContentful,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import { UNIMARC_ISOTYPE } from 'shared/constants'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const WelcomeHeadband = (): React.ReactElement => {
  const { data: dataUser } = useUserWithMembership()

  const nameModify = dataUser?.firstName?.toLowerCase() ?? ''
  const clientName = nameModify
    ? nameModify[0].toUpperCase() + nameModify.substring(1)
    : ''

  const unimarcIsotypeAsset = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-welcome-headband',
    options: {
      'sys.id': UNIMARC_ISOTYPE[0]
    },
    type: 'assets'
  })

  const unimarcIsotypeImage =
    unimarcIsotypeAsset?.data?.['items'][0]?.fields?.file?.url || ''

  return (
    <Container
      border={`${getGlobalStyle('--border-width-3xs')} solid ${getGlobalStyle(
        '--color-neutral-gray-divider'
      )}`}
      borderRadius={getGlobalStyle('--border-radius-md')}
      height={90}
      margin='16px 0 0 0'
      padding='12px 16px'
    >
      <Row alignItems='center'>
        <Column maxWidth='90px'>
          <Picture
            height='90'
            src={unimarcIsotypeImage}
            width='auto'
          />
        </Column>
        <Spacer.Vertical size={24} />
        <Spacer.Vertical size={1} />
        <Column>
          <Row>
            <Text
              customColor={getGlobalStyle('--color-base-black')}
              fontSize='3xl'
              fontWeight='semibold'
            >
              ¡Hola, {clientName}!
            </Text>
            <Icon
              customSize={32}
              name='HandWave'
            />
          </Row>
          <Spacer.Horizontal size={16} />
          <Text
            customColor={getGlobalStyle('--color-neutral-gray-dark')}
            fontSize='lg'
            fontWeight='regular'
          >
            Haz que tu experiencia en Unimarc, sea a tu medida.
          </Text>
        </Column>
      </Row>
    </Container>
  )
}
