import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ContainerPages } from 'components/ContainerPages'
import { TextThird } from '../TextThird'
import { getParamsInURL } from '@smu-chile/pkg-unimarc-hooks'
import styles from '../../../Components.module.css'

export const PageConfirmateLoader = (): React.ReactElement => {
  const isParamsApp = getParamsInURL('source')
  const webviewHeight = isParamsApp ? '95vh' : ''

  return (
    <>
      <BigScreen>
        <ContainerPages>
          <Container
            alignItems='center'
            backgroundColor={getGlobalStyle('--color-base-white')}
            border={`${getGlobalStyle(
              '--border-width-3xs'
            )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
            borderRadius={getGlobalStyle('--border-radius-md')}
            customHeight='auto'
            isWrap
            justifyContent='center'
          >
            <Row
              isWrap
              padding='24px 32px'
            >
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <Backbone
                  height={30}
                  width={100}
                />
              </Column>
            </Row>
            <Row isWrap>
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <Backbone
                  height={30}
                  width={100}
                />
              </Column>
            </Row>
            <Row
              isWrap
              padding='24px 32px'
            >
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <Backbone
                  height={30}
                  width={100}
                />
              </Column>
            </Row>
            <Row
              isWrap
              padding='24px 170px'
            >
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <TextThird />
              </Column>
            </Row>
            <Row
              isWrap
              padding='32px'
            >
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <Backbone
                  height={30}
                  width={100}
                />
              </Column>
            </Row>
          </Container>
        </ContainerPages>
      </BigScreen>

      <SmallScreen>
        <ContainerPages>
          {!isParamsApp && (
            <Row
              backgroundColor={getGlobalStyle('--color-base-white')}
              boxShadow={getGlobalStyle('--box-shadow-2xs')}
              id='container-go-back--my-account'
            >
              <Backbone
                height={30}
                width={100}
              />
            </Row>
          )}
          <Column
            alignItems='center'
            isWrap
            justifyContent='center'
            padding='12px 16px 12px'
          >
            <Container
              backgroundColor={getGlobalStyle('--color-base-white')}
              border={`${getGlobalStyle(
                '--border-width-3xs'
              )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
              borderRadius={getGlobalStyle('--border-radius-md')}
              className={styles.containerMinHeightWithBack}
              isWrap
              justifyContent='between'
              minHeight={webviewHeight}
              padding='28px 12px'
              tagName='section'
            >
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <Backbone
                  height={30}
                  width={100}
                />
                <Spacer.Horizontal size={24} />
                <Backbone
                  height={30}
                  width={100}
                />
                <Spacer.Horizontal size={16} />
                <Spacer.Horizontal size={1} />
                <Backbone
                  height={30}
                  width={100}
                />
                <Spacer.Horizontal size={24} />
                <Backbone
                  height={30}
                  width={100}
                />
                <Spacer.Horizontal size={64} />
              </Column>
              <Column alignSelf='end'>
                <Backbone
                  height={30}
                  width={100}
                />
              </Column>
            </Container>
          </Column>
        </ContainerPages>
      </SmallScreen>
    </>
  )
}
