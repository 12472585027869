import {
  Row,
  Column,
  Vertical,
  Text,
  Horizontal
} from '@smu-chile/pkg-unimarc-components'

import { Image } from './Image'
import { conditionalDecimal, Name } from './Name'
import { Price } from './Price'
import { Readjustment } from './Readjustment'

import { ItemsSent } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'

import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'

interface ProductDetailsProps {
  brandName: string
  item: ItemsSent
  latest?: boolean
  price: number
}

export const ProductDetails = ({
  brandName,
  item,
  latest,
  price
}: ProductDetailsProps): React.ReactElement => {
  const {
    id,
    imageUrl,
    measurementUnit,
    name,
    quantity,
    unitMultiplier,
    weight
  } = item

  const existWeight = weight ? true : false
  const unitFinal: string | number = conditionalDecimal(
    existWeight,
    quantity,
    unitMultiplier,
    weight
  )
  const customHeight = existWeight ? '145px' : '120px'
  const marginName = existWeight ? '0 0 -47px' : '0'

  return (
    <>
      <BigScreen>
        <Row
          alignItems='center'
          customHeight={customHeight}
          justifyContent='center'
        >
          <Column
            customWidth='16'
            padding='10px 8px'
          >
            <Image
              altPicture='picture'
              srcPicture={imageUrl}
            />
            <Vertical size={16} />
          </Column>
          <Column
            alignItems='center'
            justifyContent='center'
            margin={marginName}
          >
            <Name
              brandName={brandName}
              measurementUnit={measurementUnit}
              name={name}
              quantity={quantity}
              unitMultiplier={unitMultiplier}
              weight={weight}
            />
            {existWeight === true && (
              <>
                <Horizontal size={8} />
                <Readjustment
                  id={id}
                  measurementUnit={measurementUnit}
                  price={price}
                  quantity={quantity}
                />
                <Horizontal size={8} />
              </>
            )}
          </Column>

          <Column
            alignItems='end'
            customWidth='20'
          >
            <Horizontal size={48} />
            <Price totalPrice={price * quantity} />
          </Column>
        </Row>
      </BigScreen>

      <SmallScreen>
        <>
          <Row
            alignItems='center'
            justifyContent='center'
          >
            <Column
              customWidth='30'
              padding='0px 8px 0px 0px'
            >
              <Image
                altPicture='picture'
                srcPicture={imageUrl}
              />
              <Vertical size={12} />
            </Column>

            <Column isWrap>
              <Name
                brandName={brandName}
                measurementUnit={measurementUnit}
                name={name}
                quantity={quantity}
                unitMultiplier={unitMultiplier}
              />
              <Horizontal size={8} />
              <Row alignItems='center'>
                <Column>
                  <Text fontSize='sm'>
                    Cantidad: {weight ? weight : unitFinal} {measurementUnit}
                  </Text>
                </Column>
                <Column alignItems='end'>
                  <Price totalPrice={price * quantity} />
                </Column>
              </Row>
            </Column>
            <Vertical size={2} />
          </Row>
          <Row alignItems='start'>
            <Column>
              {existWeight === true && (
                <Readjustment
                  id={id}
                  measurementUnit={measurementUnit}
                  price={price}
                  quantity={quantity}
                />
              )}
            </Column>
          </Row>
          {!latest && <Horizontal size={24} />}
        </>
      </SmallScreen>
    </>
  )
}
