import { Column, Row, Spacer } from '@smu-chile/pkg-unimarc-components'
import { LoaderSmallScreen } from 'components/OrdersList/PageOrdersLoaders'
import { ShelfListMobile } from 'components/OrdersList/Shelf'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'

interface ShelfListContainerProps {
  loading: boolean
  objetSrcImagesOrders?: {
    srcCancel: string
    srcStore: string
    srcMoto: string
  }
  orderList: Order[]
  sourceFlow?: string
}

export const ShelfListContainer = ({
  loading,
  objetSrcImagesOrders,
  orderList,
  sourceFlow
}: ShelfListContainerProps) => {
  if (loading) {
    return <LoaderSmallScreen />
  }

  return (
    <Row>
      <Column>
        {orderList.map((order, key) => {
          return (
            <>
              <Spacer.Horizontal size={12} />
              <ShelfListMobile
                key={key}
                objetSrcImagesOrders={objetSrcImagesOrders}
                order={order}
                sourceFlow={sourceFlow}
              />
            </>
          )
        })}
      </Column>
    </Row>
  )
}
