import { Entry, isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { getParamHelpCenter } from 'shared/helpers/getParamHelpCenter'
import { getSettingsOrderDetail } from './getSettingsOrderDetail'

export const getCFOptions = (entriesSorted: Entry[], pathname: string) => {
  const { handleHomeEvent, handleListsEvent } = getSettingsOrderDetail()

  /**
   * Shoud create a new category when added a new one at contenful.
   */
  const categories = {
    '/mi-cuenta': 'Mi Cuenta',
    '/benficios': 'Mi Club Unimarc',
    '/custom-url': 'Mis Productos',
    '/servicio-al-cliente': 'Centro de ayuda',
    '/modal': 'logout'
  }

  const activeMyOrdersOption = [
    '/',
    '/OrderDetails',
    '/ClaimFlow',
    '/CancelsPurchase',
    '/ClaimFlowSuccess'
  ]
  const activeHelpCenterOption = [
    '/HelpCenter',
    '/HelpPurchase',
    '/HelpCenter/Others'
  ]

  const sendEvent = {
    '/': handleHomeEvent,
    '/MyLists': handleListsEvent
  }

  const getStrokeWidth = (iconText) => {
    return iconText === 'BagTick' ? '1.2' : '1'
  }

  const leakedData = isValidArrayWithData(entriesSorted)
    ? entriesSorted.filter((entry) => {
        return entry?.fields?.['showbuttom'] === true
      })
    : []
  const isParamHelpCenter = getParamHelpCenter()
  const newList = []

  const isMisCompras = (fields): boolean => {
    return (
      fields?.label === 'Mis compras' &&
      activeMyOrdersOption.includes(window.location.pathname) &&
      !isParamHelpCenter
    )
  }

  const isMisListas = (fields): boolean => {
    return (
      fields?.label === 'Mis listas' &&
      window.location.pathname.includes('List')
    )
  }

  const isHelpCenterOrHelpPurchase = (fields, pathname: string) => {
    if (activeHelpCenterOption.includes(fields?.url)) {
      return (
        isParamHelpCenter ||
        fields?.url === pathname ||
        activeHelpCenterOption.includes(pathname)
      )
    }
    return fields?.url === pathname
  }

  leakedData.forEach(({ fields }) => {
    fields.active =
      isMisCompras(fields) ||
      isMisListas(fields) ||
      isHelpCenterOrHelpPurchase(fields, pathname)
    fields.eventClick = sendEvent[fields?.url] || null
    fields.strokeWidth = getStrokeWidth(fields?.iconText)

    const index = newList.findIndex((fnList) => {
      return fnList.action === fields?.action
    })

    if (index >= 0) {
      newList[index]?.options?.push(fields)
      return
    }

    if (categories[fields?.action]) {
      newList.push({
        title: categories[fields?.action] || '',
        description: fields?.subtitulo || '',
        action: fields?.action,
        options: [fields]
      })
    }
  })

  return newList
}
