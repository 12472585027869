import {
  Column,
  Container,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { getParamsInURL } from '@smu-chile/pkg-unimarc-hooks'
import { ITicketsResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetTicketsZendesk'
import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { ContainerPages } from 'components/ContainerPages'
import { GoBack } from 'components/GoBack'
import { MobilePagination } from 'components/OrdersList/OrdersSmallScreen/MobilePagination'
import { useEffect, useState } from 'react'
import {
  IMAGES_ICON_REQUEST_CLOSED,
  IMAGES_ICON_REQUEST_NEW,
  IMAGES_ICON_REQUEST_OPEN,
  ORDER_PER_PAGE_DESKTOP_ZENDESK
} from 'shared/constants'
import { IMembershipDataCF } from 'shared/interfaces/IMembership'
import useContentfulData from 'shared/helpers/orderIconTheme'
import { ImageZendesk } from './components/ImageZendesk'
import { InformationZendesk } from './components/InformationZendesk'
import { StatusZendesk } from './components/StatusZendesk'
import { v4 as uuid } from 'uuid'

interface MyRequestsZendeskComponentProps {
  changeOrder?: boolean
  currentPage: number
  dataTickets: ITicketsResponse
  membershipDataCF?: IMembershipDataCF
  ordersType: boolean
  userData?: ResponseOrderFormProps
  setChangeOrder: (order: boolean) => void
  setCurrentPage: (page: number) => void
  setOrder: (order: 0 | 1) => void
  setOrdersType: (type: boolean) => void
}

export const MyRequestsZendeskComponent = ({
  currentPage,
  dataTickets,
  membershipDataCF,
  userData,
  setCurrentPage
}: MyRequestsZendeskComponentProps) => {
  const paramsApp = getParamsInURL('source')
  const [totalPage, setTotalPage] = useState(1)
  const [orderList, setOrderList] = useState<ITicketsResponse['results']>()

  const optionsCFNew = {
    id_contentful: 'contentful-builtin-asset-content-New',
    options: {
      'sys.id': IMAGES_ICON_REQUEST_NEW[0]
    },
    type: 'assets'
  }

  const optionsCFOpen = {
    id_contentful: 'contentful-builtin-asset-content-Open',
    options: {
      'sys.id': IMAGES_ICON_REQUEST_OPEN[0]
    },
    type: 'assets'
  }

  const optionsCFClosed = {
    id_contentful: 'contentful-builtin-asset-content-Closed',
    options: {
      'sys.id': IMAGES_ICON_REQUEST_CLOSED[0]
    },
    type: 'assets'
  }

  const { data: dataCFImgNew } = useContentfulData(optionsCFNew)
  const { data: dataCFImgOpen } = useContentfulData(optionsCFOpen)
  const { data: dataCFImgClosed } = useContentfulData(optionsCFClosed)

  const srcImageOrderNew = dataCFImgNew?.['items'][0]?.fields?.file?.url || ''
  const srcImageOrderOpen = dataCFImgOpen?.['items'][0]?.fields?.file?.url || ''
  const srcImageOrderClosed =
    dataCFImgClosed?.['items'][0]?.fields?.file?.url || ''

  useEffect(() => {
    if (dataTickets) {
      const { results, total } = dataTickets
      setOrderList([...results])
      let pages =
        total % ORDER_PER_PAGE_DESKTOP_ZENDESK === 0
          ? Math.floor(total / ORDER_PER_PAGE_DESKTOP_ZENDESK)
          : Math.floor(total / ORDER_PER_PAGE_DESKTOP_ZENDESK) + 1
      if (pages > 15) {
        pages = 15
      }
      setTotalPage(pages)
    }
  }, [dataTickets])

  const statusMappings = {
    NEW: {
      text: 'Ingresada',
      textColor: '--color-feedback-info',
      backgroundColor: '--color-feedback-info-bg', // blue
      image: srcImageOrderNew
    },
    OPEN: {
      text: 'En curso',
      textColor: '--color-feedback-warning-orange',
      backgroundColor: '--color-feedback-warning-bg', // yellow
      image: srcImageOrderOpen
    },
    PENDING: {
      text: 'En curso',
      textColor: '--color-feedback-warning-orange',
      backgroundColor: '--color-feedback-warning-bg', // yellow
      image: srcImageOrderOpen
    },
    HOLD: {
      text: 'En curso',
      textColor: '--color-feedback-warning-orange',
      backgroundColor: '--color-feedback-warning-bg', // yellow
      image: srcImageOrderOpen
    },
    CLOSED: {
      text: 'Resuelta',
      textColor: '--color-feedback-success-light',
      backgroundColor: '--color-feedback-success-bg', // green
      image: srcImageOrderClosed
    },
    SOLVED: {
      text: 'Resuelta',
      textColor: '--color-feedback-success-light',
      backgroundColor: '--color-feedback-success-bg', // green
      image: srcImageOrderClosed
    }
  }

  const getTicketDetails = (status: string) => {
    return (
      statusMappings[status?.toUpperCase()] || {
        text: 'Ingresada',
        textColor: '--color-feedback-info',
        backgroundColor: '--color-feedback-info-bg', // Azul
        image: srcImageOrderNew
      }
    )
  }

  const returnPagination = () => {
    return (totalPage > currentPage || currentPage > 1) &&
      orderList?.length > 0 ? (
      <MobilePagination
        onChangePage={(pageNumber) => {
          return setCurrentPage(pageNumber)
        }}
        pages={totalPage}
        selectedPage={currentPage}
      />
    ) : (
      <Row margin='6px 0 0'>
        <Spacer.Horizontal
          backgroundColor={getGlobalStyle('--color-background-white')}
          size={4}
        />
      </Row>
    )
  }

  return (
    <>
      <ContainerPages
        membershipDataCF={membershipDataCF}
        userData={userData}
      >
        <BigScreen>
          <Column
            backgroundColor={getGlobalStyle('--color-base-white')}
            border={`${getGlobalStyle(
              '--border-width-3xs'
            )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
            borderRadius={getGlobalStyle('--border-radius-md')}
            id={getBemId('myRequestsZendeskComponent', 'bigScreenColumn')}
            padding='24px 32px 32px 32px'
          >
            <Row
              id={getBemId('myRequestsZendeskComponent', 'bigScreenRow')}
              isWrap
              maxWidth={getGlobalStyle('--width-max-desktop')}
            >
              <Column
                id={getBemId('myRequestsZendeskComponent', 'section')}
                isWrap
                tagName='section'
              >
                <Row
                  id={getBemId('myRequestsZendeskComponent', 'headerRow')}
                  isWrap
                >
                  <Column
                    alignItems='start'
                    id={getBemId('myRequestsZendeskComponent', 'titleColumn')}
                  >
                    <Text
                      fontSize='2xl'
                      fontWeight='semibold'
                      id={getBemId('myRequestsZendeskComponent', 'titleText')}
                    >
                      Mis solicitudes
                    </Text>
                  </Column>
                  <Spacer.Horizontal size={8} />
                  <Column
                    alignItems='start'
                    id={getBemId(
                      'myRequestsZendeskComponent',
                      'subtitleColumn'
                    )}
                  >
                    <Text
                      customColor={getGlobalStyle('--color-neutral-gray-dark')}
                      id={getBemId(
                        'myRequestsZendeskComponent',
                        'subtitleText'
                      )}
                    >
                      Revisa el estado de tus consultas
                    </Text>
                  </Column>
                  <Spacer.Horizontal size={16} />
                  <div
                    id={getBemId('myRequestsZendeskComponent', 'divider')}
                    style={{
                      border: `0.3px solid ${getGlobalStyle(
                        '--color-neutral-gray-divider'
                      )}`,
                      width: '100%'
                    }}
                  />
                </Row>
                <Spacer.Horizontal size={16} />
                <Row
                  alignItems='center'
                  id={getBemId('myRequestsZendeskComponent', 'contentRow')}
                  isWrap
                  justifyContent='center'
                >
                  {orderList?.map((order) => {
                    const ticketDetails = getTicketDetails(order?.status)
                    return (
                      <>
                        <Container
                          backgroundColor={getGlobalStyle(
                            '--color-background-white'
                          )}
                          border={`${getGlobalStyle(
                            '--border-width-3xs'
                          )} solid ${getGlobalStyle(
                            '--color-neutral-gray-divider'
                          )}`}
                          borderRadius={getGlobalStyle('--border-radius-md')}
                          customHeight='94px'
                          id={getBemId(
                            'myRequestsZendeskComponent',
                            'orderContainer',
                            uuid()
                          )}
                          key={uuid()}
                          padding='8px 24px'
                          tagName='section'
                        >
                          <ImageZendesk
                            srcImageZendesk={ticketDetails?.image}
                          />

                          <InformationZendesk
                            dateTimeZendesk={order?.createdAt}
                            numberTicket={order?.id}
                            valueTicket={
                              order?.customFields[0]?.value === '360056187993'
                                ? order?.customFields[1]?.value
                                : order?.customFields[0]?.value
                            }
                          />
                          <StatusZendesk
                            colorBackgroundColor={
                              ticketDetails?.backgroundColor
                            }
                            colorText={ticketDetails?.textColor}
                            textStatus={ticketDetails?.text}
                          />
                        </Container>
                        <Spacer.Horizontal size={12} />
                      </>
                    )
                  })}
                </Row>
              </Column>
            </Row>
            {returnPagination()}
          </Column>
        </BigScreen>

        <SmallScreen>
          <>
            <Column
              id={getBemId('myRequestsZendeskComponent', 'smallScreenColumn')}
              tagName='body'
            >
              {!paramsApp && (
                <Row
                  boxShadow={getGlobalStyle('--box-shadow-2xs')}
                  id={getBemId('myRequestsZendeskComponent', 'goBackRow')}
                  zIndex={getGlobalStyle('--z-index-1')}
                >
                  <GoBack title={'Mis solicitudes'} />
                </Row>
              )}
              <Spacer.Horizontal
                backgroundColor={getGlobalStyle('--color-background-white')}
                size={12}
              />
              <Column
                backgroundColor={getGlobalStyle('--color-base-white')}
                id={getBemId('myRequestsZendeskComponent', 'mainColumnSmall')}
              >
                <Row
                  id={getBemId('myRequestsZendeskComponent', 'contentRowSmall')}
                  isWrap
                  maxWidth={getGlobalStyle('--width-max-mobile')}
                  padding='12px 16px 24px'
                >
                  <Column
                    id={getBemId('myRequestsZendeskComponent', 'sectionSmall')}
                    isWrap
                    tagName='section'
                  >
                    {orderList?.map((order) => {
                      const ticketDetails = getTicketDetails(order?.status)
                      return (
                        <>
                          <Container
                            backgroundColor={getGlobalStyle(
                              '--color-background-white'
                            )}
                            border={`${getGlobalStyle(
                              '--border-width-3xs'
                            )} solid ${getGlobalStyle(
                              '--color-neutral-gray-divider'
                            )}`}
                            borderRadius={getGlobalStyle('--border-radius-md')}
                            customHeight='83px'
                            id={getBemId(
                              'myRequestsZendeskComponent',
                              'orderContainerSmall',
                              uuid()
                            )}
                            key={uuid()}
                            padding='12px'
                          >
                            <ImageZendesk
                              srcImageZendesk={ticketDetails?.image}
                            />

                            <InformationZendesk
                              dateTimeZendesk={order?.createdAt}
                              numberTicket={order?.id}
                              valueTicket={order?.customFields[0]?.value}
                            />
                            <StatusZendesk
                              colorBackgroundColor={
                                ticketDetails?.backgroundColor
                              }
                              colorText={ticketDetails?.textColor}
                              textStatus={ticketDetails?.text}
                            />
                          </Container>
                          <Spacer.Horizontal size={12} />
                        </>
                      )
                    })}
                  </Column>
                </Row>
              </Column>
            </Column>
            {returnPagination()}
          </>
        </SmallScreen>
      </ContainerPages>
    </>
  )
}
