export function convertToPascalCase(sentence:string) {
  const words = sentence.toLowerCase().split(' ')
  const pascalCase = words.map(function (word:string, index:number) {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    } 
    return word
    
  })

  return pascalCase.join(' ')
}