import { Button } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'

interface ButtonPageProps {
  isParamsApp: string | boolean
}

export const ButtonPage = ({ isParamsApp }: ButtonPageProps) => {
  const router = useRouter()
  const { isMobile } = useMobile()
  const redirectClick = isParamsApp
    ? process.env.NEXT_PUBLIC_HOMEURL_APP
    : process.env.NEXT_PUBLIC_HOMEURL
  return (
    <Button
      borderRadius={getGlobalStyle('--border-radius-xl')}
      fontWeight='500'
      label='Descubre las ofertas'
      onClick={() => {
        return router.push(redirectClick)
      }}
      size='md'
      type='secondary-gray'
      width={isMobile ? ' 100%' : '335px'}
    />
  )
}
