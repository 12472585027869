import React, { useEffect, useState } from 'react'
import {
  Button,
  Column,
  Container,
  Divider,
  Picture,
  Row,
  Spacer,
  Text,
  ToggleSwitch
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  useMobile,
  goToHome,
  UseQueryResult,
  getParamsInURL,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { GoBack } from 'components/GoBack'
import { SideNav } from 'components/SideNav'
import { SearchInput, SearchInputProps } from '../SearchInput'
import { LoaderBigScreen, LoaderSmallScreen } from '../PageOrdersLoaders'
import {
  handleChange,
  handleClearInput,
  handleKeyNumberToSearch,
  handleSearchKeyOnClick
} from '../OrdersBigScreen'
import {
  getSettingsEmpty,
  useImageOrder,
  useImageOrderNotFound,
  useImageOrderRejected
} from './helper'
import { OrdersHeader } from '../OrdersHeader'
import { setMembershipBlock } from 'shared/helpers/membershipHeader'
import { MembershipBlock } from 'components/MembershipBlock'
import { MembershipHeaderBrowse } from 'components/MembershipHeaderBrowse'
import { IMembershipDataCF } from 'shared/interfaces/IMembership'
import { MEMBERSHIP_STATUS } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

interface OrdersIncompleteProps {
  inputListener: string
  isFetching: boolean
  membershipDataCF?: IMembershipDataCF
  numberOrderMinimun: boolean
  ordersType: boolean
  sequence: string
  userData?: ResponseOrderFormProps
  changeOrderType: () => void
  setCurrentPage: (type: number) => void
  setInputListener: (type: string) => void
  setIsError: (type: boolean) => void
  setNumberOrderMinimun: (type: boolean) => void
  setOrderList: (type: []) => void
  setSequence: (type: string) => void
}

export const EmptyOrders = ({
  changeOrderType,
  inputListener,
  isFetching,
  membershipDataCF,
  numberOrderMinimun,
  ordersType,
  sequence,
  userData,
  setCurrentPage,
  setInputListener,
  setIsError,
  setNumberOrderMinimun,
  setOrderList,
  setSequence
}: OrdersIncompleteProps): React.ReactElement => {
  const { data: dataMembership } = useUserWithMembership()
  const [membershipBlockData, setMembershipBlockData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { isMobile, innerWidth } = useMobile()
  const viewLoading: React.ReactElement = isMobile ? (
    <LoaderSmallScreen />
  ) : (
    <LoaderBigScreen isFetching={isFetching} />
  )
  const paramsApp = getParamsInURL('source')
  const maxWithSecondTitle = isMobile ? '303px' : '400px'
  const widthImgSrc = isMobile ? '213' : '225'
  const heightImgSrc = isMobile ? '186' : '176'
  const widthButton = isMobile ? '311px' : '408px'
  const imgOrder = useImageOrder()
  const imgOrderRejected = useImageOrderRejected()
  const imgOrderNotFound = useImageOrderNotFound()
  const fontSize = innerWidth <= 360 ? 'sm' : 'md'
  let backgroundColorIconCondition = ''
  let borderRadiusCondition = ''
  let colorIconCondition = ''
  let columnWidthCondition = ''
  let marginDesktopCondition = ''
  let paddingDesktopCondition = ''

  switch (true) {
    case isMobile:
      backgroundColorIconCondition = 'rgb(242, 244, 244)'
      borderRadiusCondition = '--border-radius-none'
      colorIconCondition = '--color-neutral-gray-dark'
      columnWidthCondition = '100'
      marginDesktopCondition = '0'
      paddingDesktopCondition = '0'
      break
    case !isMobile:
      backgroundColorIconCondition = 'rgba(218, 41, 28, .1)'
      borderRadiusCondition = '--border-radius-md'
      colorIconCondition = '--color-campaigns-red'
      columnWidthCondition = '75.89'
      marginDesktopCondition = '18px 0px'
      paddingDesktopCondition = '24px 32px 0px'
      break
  }

  const stylesDefinition = {
    isMobileSmall: innerWidth <= 360,
    backgroundColorIconCondition: backgroundColorIconCondition,
    borderRadiusCondition: borderRadiusCondition,
    colorIconCondition: colorIconCondition,
    columnWidthCondition: columnWidthCondition,
    marginDesktopCondition: marginDesktopCondition,
    paddingDesktopCondition: paddingDesktopCondition
  }

  const {
    backgroundColor,
    borderRadius,
    columnWidth,
    imageCF,
    marginDesktop,
    paddingDesktop,
    textButton,
    textPrimary,
    textSecondary,
    typeButton
  } = getSettingsEmpty(
    numberOrderMinimun,
    ordersType,
    sequence,
    isMobile,
    stylesDefinition
  )

  const getImageSrc = (imageCF: string) => {
    let imgFinal: UseQueryResult<unknown, unknown>
    switch (imageCF) {
      case 'order':
        imgFinal = imgOrder
        break
      case 'rejected':
        imgFinal = imgOrderRejected
        break
      case 'not-found':
        imgFinal = imgOrderNotFound
        break
    }
    return imgFinal?.data?.['items'][0]?.fields?.file?.url || ''
  }

  const handleOnClickButton = (imageCF: string) => {
    if (imageCF !== 'not-found') {
      handleGoToHome()
    } else {
      handleClearInput(
        inputListener,
        sequence,
        setInputListener,
        setIsError,
        setOrderList,
        setNumberOrderMinimun,
        setSequence
      )
    }
  }
  const repatPurchaseImage = getImageSrc(imageCF)

  const searchBarProps: SearchInputProps = {
    inputListener,
    onchange: (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(event, setInputListener)
    },
    onclear: () => {
      setLoading(true)
      handleClearInput(
        inputListener,
        sequence,
        setInputListener,
        setIsError,
        setOrderList,
        setNumberOrderMinimun,
        setSequence
      )
    },
    onclick: () => {
      handleSearchKeyOnClick(
        inputListener,
        setCurrentPage,
        setNumberOrderMinimun,
        setSequence
      )
    },
    onkeydown: (event: React.KeyboardEvent<HTMLInputElement>) => {
      handleKeyNumberToSearch(
        event,
        setCurrentPage,
        setNumberOrderMinimun,
        setSequence
      )
    },
    sequence
  }

  const handleGoToHome = () => {
    window.location.href = goToHome()
  }

  useEffect(() => {
    if (membershipDataCF?.membershipButtons) {
      setMembershipBlockData(
        setMembershipBlock([membershipDataCF?.membershipButtons])
      )
    }
  }, [membershipDataCF])

  return (
    <Container
      alignItems='baseline'
      backgroundColor={getGlobalStyle('--color-background-white')}
      isWrap
      justifyContent='center'
      tagName='main'
    >
      <Column
        customWidth='100'
        maxWidth={getGlobalStyle('--width-max-desktop')}
        padding='0'
      >
        <BigScreen>
          <>
            <MembershipHeaderBrowse
              membershipDataCF={membershipDataCF}
              userData={userData}
            />
          </>
        </BigScreen>
        <Row>
          <>
            {/* side nav */}
            <BigScreen>
              <>
                <Column
                  customWidth='24.11'
                  margin={membershipBlockData ? '16px 0 0' : '2px 0 0'}
                  position='sticky'
                  tagName='aside'
                  top='74'
                >
                  {membershipBlockData &&
                    dataMembership?.membership !== MEMBERSHIP_STATUS.ACTIVE && (
                      <MembershipBlock
                        buttonLabel={membershipBlockData?.buttonLabel}
                        labelColorBackground={
                          membershipBlockData?.backgroundColor
                        }
                        labelColorText={membershipBlockData?.textColor}
                        labelIcon={membershipBlockData?.icon}
                        labelText={membershipBlockData?.labelText}
                        subtitle={membershipBlockData?.subtitle}
                        title={membershipBlockData?.title}
                      />
                    )}
                  <SideNav />
                </Column>
              </>
            </BigScreen>

            {/* main content */}
            <Column
              backgroundColor={getGlobalStyle(backgroundColor)}
              border={`0.3px solid ${getGlobalStyle(
                '--color-neutral-gray-divider'
              )}`}
              borderRadius={getGlobalStyle(borderRadius)}
              customWidth={columnWidth}
              isWrap
              margin={marginDesktop}
              minHeight='80vh'
              padding={paddingDesktop}
              tagName='section'
            >
              {/* title, subtitle, toggle and searchbar */}
              <Row>
                <BigScreen>
                  <>
                    <OrdersHeader
                      changeOrderType={changeOrderType}
                      numberOrderMinimun={numberOrderMinimun}
                      ordersType={ordersType}
                      sequence={sequence}
                    />
                  </>
                </BigScreen>
                <SmallScreen>
                  <>
                    <Row
                      backgroundColor={getGlobalStyle('--color-base-white')}
                      boxShadow={getGlobalStyle('--box-shadow-2xs')}
                    >
                      <Column alignItems='center'>
                        {!paramsApp && <GoBack title='Mis compras' />}
                        {!sequence && !numberOrderMinimun && (
                          <>
                            <Spacer.Horizontal size={8} />
                            <ToggleSwitch
                              leftLabel='Compras realizadas'
                              onClick={() => {
                                return changeOrderType()
                              }}
                              rightLabel='Compras rechazadas'
                              textSize={fontSize}
                              value={ordersType}
                            />
                            <Spacer.Horizontal size={16} />
                          </>
                        )}
                      </Column>
                    </Row>
                  </>
                </SmallScreen>
              </Row>

              <Spacer.Horizontal size={8} />

              {/* divider */}
              <BigScreen>
                <Divider
                  thickness={0}
                  verticalMargin={0}
                  width={100}
                />
              </BigScreen>

              {/* loader */}
              {isFetching || loading ? (
                viewLoading
              ) : (
                <>
                  <SmallScreen>
                    {sequence || numberOrderMinimun ? (
                      <Column>
                        <Row
                          backgroundColor={getGlobalStyle('--color-base-white')}
                          border={`0.3px solid ${getGlobalStyle(
                            '--color-neutral-gray-divider'
                          )}`}
                          padding='10px 20px'
                        >
                          <Column>
                            <SearchInput {...searchBarProps} />
                            <Spacer.Horizontal size={8} />
                          </Column>
                        </Row>
                      </Column>
                    ) : null}
                  </SmallScreen>
                  <Spacer.Horizontal size={12} />
                  <BigScreen>
                    {sequence || numberOrderMinimun ? (
                      <Row
                        customWidth='100'
                        justifyContent='end'
                      >
                        <Column customWidth='34.2'>
                          <SearchInput {...searchBarProps} />
                        </Column>
                      </Row>
                    ) : null}
                  </BigScreen>
                  <Row margin='60px 0 16px 0'>
                    <Column
                      alignItems='center'
                      id={getBemId('orders-list', 'image')}
                    >
                      <Picture
                        height={heightImgSrc}
                        src={repatPurchaseImage}
                        width={widthImgSrc}
                      />
                    </Column>
                  </Row>
                  <Row
                    justifyContent='center'
                    margin='16px 0 0'
                  >
                    <Column alignItems='center'>
                      <Text
                        fontSize='2xl'
                        fontWeight='medium'
                        id={getBemId('orders-list', 'title')}
                        textAlign='center'
                      >
                        {textPrimary}
                      </Text>
                    </Column>
                  </Row>
                  <Row
                    justifyContent='center'
                    margin='12px 0'
                  >
                    <Column maxWidth={maxWithSecondTitle}>
                      <Text
                        fontSize='lg'
                        id={getBemId('orders-list', 'subtitle')}
                        textAlign='center'
                      >
                        {textSecondary}
                      </Text>
                    </Column>
                  </Row>
                </>
              )}

              <Spacer.Horizontal size={64} />

              {/* actions */}
              <Row margin='32px 0'>
                <Column alignItems='center'>
                  {loading ? null : (
                    <Button
                      borderRadius={getGlobalStyle('--border-radius-lg')}
                      fontWeight='500'
                      height='45px'
                      id={getBemId('orders-list', 'button')}
                      label={textButton}
                      onClick={() => {
                        return handleOnClickButton(imageCF)
                      }}
                      size='xs'
                      status='initial'
                      type={typeButton}
                      width={widthButton}
                    />
                  )}
                </Column>
              </Row>
            </Column>
          </>
        </Row>
      </Column>
    </Container>
  )
}
