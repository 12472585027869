import { Container } from '@smu-chile/pkg-unimarc-components'
import { ItemsSent } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { Shelf } from './Shelf'

export interface ShelvesProps {
  productsCount?: number
  items?: ItemsSent[]
}

export const Shelves = (props: ShelvesProps): React.ReactElement => {
  let brandNameFinal = ''
  let priceFinal = 0
  const productsCount = props.productsCount
  const itemsNormal = props.items ? props.items.slice(0, productsCount) : null

  return (
    <>
      {
        <Container
          isWrap
          margin='20px 0px'
        >
          {itemsNormal.map((shelve: ItemsSent, index) => {
            if (shelve?.additionalInfo) {
              const {
                additionalInfo: { brandName }
              } = shelve
              brandNameFinal = brandName
            }
            if (shelve?.brandName) {
              const { brandName } = shelve
              brandNameFinal = brandName
            }
            if (shelve?.price) {
              const { price } = shelve
              priceFinal = price
            }

            if (shelve?.sellingPrice) {
              const { sellingPrice } = shelve
              priceFinal = sellingPrice
            }
            return (
              <Shelf
                brandName={brandNameFinal}
                item={shelve}
                key={shelve.id}
                latest={index === itemsNormal.length - 1}
                price={priceFinal}
              />
            )
          })}
        </Container>
      }
    </>
  )
}
