import { Column, Container, Row } from '@smu-chile/pkg-unimarc-components'
import {
  getGlobalStyle,
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useSession, useUserWithMembership } from '@smu-chile/pkg-unimarc-hooks'
import { MEMBERSHIP_STATUS } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { MembershipBlock, MembershipHeaderBrowse, SideNav } from 'components'
import { useEffect, useState } from 'react'
import { setMembershipBlock } from 'shared/helpers/membershipHeader'
import { IMembershipDataCF } from 'shared/interfaces/IMembership'

interface ContainerPagesProps {
  children: React.ReactNode
  membershipDataCF?: IMembershipDataCF
  userData?: ResponseOrderFormProps
}

export const ContainerPages = ({
  children,
  membershipDataCF,
  userData
}: ContainerPagesProps): React.ReactElement => {
  const { data: dataMembership } = useUserWithMembership()
  const [membershipBlockData, setMembershipBlockData] = useState(null)
  const { isLoading, isLoggedIn } = useSession()

  useEffect(() => {
    if (membershipDataCF?.membershipButtons) {
      setMembershipBlockData(
        setMembershipBlock([membershipDataCF?.membershipButtons])
      )
    }
  }, [membershipDataCF])

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-background-white')}
      isWrap
      justifyContent='center'
    >
      <BigScreen>
        <Row
          customWidth='90'
          isWrap
          maxWidth='1200px'
        >
          {(isLoading || isLoggedIn) && (
            <MembershipHeaderBrowse
              membershipDataCF={membershipDataCF}
              userData={userData}
            />
          )}
          <Column
            customWidth='24.11'
            isWrap
            margin={membershipBlockData ? '16px 0 0' : '2px 0 0'}
            position='sticky'
            tagName='aside'
            top='74'
          >
            {membershipBlockData &&
              dataMembership?.membership !== MEMBERSHIP_STATUS.ACTIVE &&
              isLoggedIn && (
                <MembershipBlock
                  buttonLabel={membershipBlockData?.buttonLabel}
                  labelColorBackground={membershipBlockData?.backgroundColor}
                  labelColorText={membershipBlockData?.textColor}
                  labelIcon={membershipBlockData?.icon}
                  labelText={membershipBlockData?.labelText}
                  subtitle={membershipBlockData?.subtitle}
                  title={membershipBlockData?.title}
                />
              )}
            <SideNav />
          </Column>
          <Column
            customWidth='75.89'
            isWrap
            margin='16px 0'
            tagName='section'
          >
            {children}
          </Column>
        </Row>
      </BigScreen>
      <SmallScreen>
        <Container
          backgroundColor={getGlobalStyle('--color-background-white')}
          justifyContent='center'
        >
          <Column maxWidth={getGlobalStyle('--width-max-mobile')}>
            {children}
          </Column>
        </Container>
      </SmallScreen>
    </Container>
  )
}
