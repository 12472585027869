import {
  Column,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
export interface PropsCard {
  number: string
}

export const GiftCardPayment = (props: PropsCard): React.ReactElement => {
  return (
    <>
      <Row alignItems='center'>
        <Column customWidth='15'>
          <Spacer.Horizontal size={2} />
          <Row alignItems='center'>
            <Icon
              name='GiftCard'
              sizes='md'
            ></Icon>
          </Row>
        </Column>
        <Column>
          <Spacer.Horizontal size={8} />
          <Text
            fontSize='md'
            fontWeight='semibold'
          >
            Giftcard
          </Text>
          <Spacer.Vertical size={8} />
          <Text
            color='gray'
            fontSize='sm'
            isFlex={false}
            textAlign='justify'
          >
            {props.number}
          </Text>
        </Column>
      </Row>
    </>
  )
}
