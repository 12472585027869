import { Row, Column, Text } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { AlignItems } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import { GoBack } from 'components/GoBack'

export const TitlePage = (props: {
  title: string
  alignItemsTitle?: AlignItems
}) => {
  const { title, alignItemsTitle = 'center' } = props
  return (
    <>
      <BigScreen>
        <>
          <Row>
            <Column
              alignItems={alignItemsTitle}
              justifyContent='center'
              minHeight={'36px'}
            >
              <Text
                fontSize='2xl'
                fontWeight='semibold'
                id={getBemId('titulo', 'text')}
                lineHeight='27px'
              >
                {title}
              </Text>
            </Column>
          </Row>
        </>
      </BigScreen>
      <SmallScreen>
        <Row
          alignItems='center'
          alignSelf='center'
          backgroundColor={getGlobalStyle('--color-base-white')}
          isWrap
          justifyContent='between'
          maxWidth={getGlobalStyle('--width-max-mobile')}
        >
          <Column
            customWidth='100'
            margin='0px -20% 0px 0px'
          >
            <GoBack title={title} />
          </Column>
        </Row>
      </SmallScreen>
    </>
  )
}
