import {
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import React from 'react'

interface ShippingProps {
  address: string
  icon: keyof typeof Icons
  tittle: string
}
export const Shipping = ({ address, icon, tittle }: ShippingProps) => {
  const { isMobile } = useMobile()
  const paddingMobile = isMobile ? '0' : '18px 15px'
  const truncateMobile = isMobile ? 100 : 50
  return (
    <Container
      isWrap
      padding={paddingMobile}
    >
      <Row alignItems={isMobile ? 'center' : 'start'}>
        <Icon
          color={getGlobalStyle('--color-base-black')}
          customSize={24}
          name={icon}
        />
        <Spacer.Vertical size={8} />
        <Container>
          <Text
            fontSize='md'
            fontWeight='medium'
          >
            {tittle}
          </Text>
        </Container>
      </Row>
      <Spacer.Horizontal size={4} />
      <Row padding='0px 0px 0px 30px'>
        <Text
          fontSize='md'
          truncate={truncateMobile}
        >
          {address}
        </Text>
      </Row>
    </Container>
  )
}
