import {
  Dispatch,
  SetStateAction,
  useState
} from 'react'
import { useLocalStorage } from '@smu-chile/pkg-unimarc-hooks'
import { IClaimData } from 'shared/interfaces/IClaimData'
import { getLocalStorageItem } from 'shared/helpers'
import { useGetClaimData, UseNextClaimData } from '../useGetClaimData'

interface UseClaimData extends UseNextClaimData {
  claimData: IClaimData;
  currentPage: number;
  currentProduct: number;
  setClaimData: Dispatch<SetStateAction<IClaimData>>;
  setCurrentPage: (value: string | number | ((val: string | number) => string | number)) => void;
  setCurrentProduct: (value: number) => void;
}

/**
 * return current page
 * save data in localStorage
 * delete data from localStorage
 * we only invoke this in ClaimFlow Page,
 * @returns CFData, CFID, ClaimData, currentPage, loaders, others
 */
export const useLocalClaimData = (): UseClaimData => {
  // get current page from localStorage
  const getCurrentPage = getLocalStorageItem('claimCurrentPage') ?? 0
  const [currentPage, setCurrentPage] = useLocalStorage('claimCurrentPage', Number(getCurrentPage))
  // get current claim data from localStorage
  const getCurrentClaimData = getLocalStorageItem('claimData') ?? {}
  const [claimData, setClaimData] = useLocalStorage('claimData', getCurrentClaimData)
  // set current product for problems with products flow
  const [currentProduct, setCurrentProduct] = useState(0)
  // get step data from CF
  const {
    CFData,
    CFID,
    isCFLoading,
    isOrderLoading,
    orderData,
    orderError,
    setCFID
  } = useGetClaimData(currentPage,setCurrentPage)

  return {
    CFData,
    CFID,
    claimData,
    currentPage: Number(currentPage),
    currentProduct,
    isCFLoading,
    isOrderLoading,
    orderData,
    orderError,
    setCFID,
    setClaimData,
    setCurrentPage,
    setCurrentProduct
  }
}