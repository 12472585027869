import {
  Container,
  Spacer,
  ErrorView,
  ErrorViewProps
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  dataLayerUniqueTypeEvent,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { useEffect } from 'react'
import { handleWindowRedirect, handleWindowReload } from 'shared/helpers'

interface ErrorViewFeProps extends Omit<ErrorViewProps, 'onClick'> {
  route?: string
}

export const ErrorPage = ({
  body1,
  body2,
  buttonLabel,
  errorImage,
  errorType = '500',
  redirect = true,
  route = '/',
  title
}: ErrorViewFeProps): React.ReactElement => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const errorViewProps = {
    body1,
    body2,
    buttonLabel,
    errorImage,
    errorType,
    redirect,
    title
  }

  const onClickError = () => {
    return redirect ? handleWindowRedirect(route) : handleWindowReload()
  }

  useEffect(() => {
    dataLayerUniqueTypeEvent({ event: `error_${errorType}` })
  }, [])

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-background-white')}
      justifyContent='center'
      minHeight={!isMobile && '543px'}
      tagName='section'
    >
      <Container
        backgroundColor={getGlobalStyle('--color-background-white')}
        customWidth='90'
        isWrap
        justifyContent='center'
        maxWidth='1440px'
      >
        <Spacer.Horizontal size={96} />
        <ErrorView
          {...errorViewProps}
          onClick={onClickError}
        />
        <Spacer.Horizontal size={48} />
      </Container>
    </Container>
  )
}
