import { useEffect, useState } from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  SizeIcon,
  Spacer,
  Text,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { currencyFormat } from '@smu-chile/pkg-unimarc-hooks'
import { formatCardNumber } from 'shared/helpers'
import { PAYMENT_CARD_TYPE } from 'shared/helpers/paymentSystems'
import style from './CardPayment.module.css'

export interface PropsCard {
  cardNumber: string
  cardType: string
  isMobile?: boolean
  quotas?: string
  quotaValue?: string
  rate?: string
  totalCreditCost?: string
}

export const handleNumberCard = (
  cardNumber: string,
  cardType: string,
  valorCondition: string | boolean
): string => {
  if (cardType === PAYMENT_CARD_TYPE[5] || cardType === PAYMENT_CARD_TYPE[6]) {
    return 'Tarjeta Unipay'
  }
  return !valorCondition ? formatCardNumber(cardNumber) : ''
}

export const getIcon = (
  cardType: string,
  sizes: SizeIcon = 'lg',
  customSize = 49,
  sourceMembership?: boolean
): React.ReactElement => {
  const iconProps = sizes ? { sizes } : { customSize }
  const iconNamePrepaid = sourceMembership ? 'Prepaid' : 'WebPayOneClickBlue'

  switch (cardType?.toUpperCase()) {
    // REDCOMPRA
    case PAYMENT_CARD_TYPE[0]?.toUpperCase():
      return (
        <Icon
          name='DebitCard'
          {...iconProps}
        />
      )
    // PREPAGO
    case PAYMENT_CARD_TYPE[1]?.toUpperCase():
      return (
        <Icon
          color='#282828'
          name={iconNamePrepaid}
          {...iconProps}
        />
      )
    // MASTERCARD
    case PAYMENT_CARD_TYPE[2]?.toUpperCase():
      return (
        <Icon
          name='Mastercard'
          {...iconProps}
        />
      )
    // VISA
    case PAYMENT_CARD_TYPE[3]?.toUpperCase():
      return (
        <Icon
          color={getGlobalStyle('--color-base-white')}
          name='Visa'
          {...iconProps}
        />
      )
    // AMEX
    case PAYMENT_CARD_TYPE[4]?.toUpperCase():
      return (
        <Icon
          name='Amex'
          {...iconProps}
        />
      )

    // Unipay
    case PAYMENT_CARD_TYPE[5]?.toUpperCase():
      return (
        <Icon
          customSize={customSize}
          name='CardUnipay'
        />
      )
    case PAYMENT_CARD_TYPE[6]?.toUpperCase():
      return (
        <Icon
          customSize={customSize}
          name='CardUnipay'
        />
      )
    default:
      return null
  }
}

export const CardPayment = ({
  cardNumber,
  cardType,
  isMobile,
  quotas,
  quotaValue,
  rate,
  totalCreditCost
}: PropsCard): React.ReactElement => {
  const valorCondition = cardNumber === ''

  const widthMobile = isMobile ? '20' : '23'
  const witdhIcon =
    valorCondition &&
    cardType !== PAYMENT_CARD_TYPE[5] &&
    cardType !== PAYMENT_CARD_TYPE[6]
      ? '50'
      : widthMobile
  const fontsize = 'sm'
  const lineHeight = '15.12px'

  const minWidthRow = isMobile ? '98%' : '195px'
  const colorTextQuota = getGlobalStyle('--color-text-gray-light')
  const [openMessage, setOpenMessage] = useState<boolean>(false)

  const messageTooltip = (openMessageToolTip: string) => {
    return (
      <>
        <Column zIndex={getGlobalStyle('--z-index-1')}>
          <Tooltip
            arrow={true}
            background={getGlobalStyle('--color-base-black')}
            borderColor={getGlobalStyle('--color-base-black')}
            borderStyle={getGlobalStyle('--color-base-black')}
            borderWidth='0px'
            boxShadow={getGlobalStyle('--box-shadow-none')}
            colorArrow='black'
            colorTitle='white'
            customClassName={style.toastMessage}
            height='26px'
            onClick={() => {
              setOpenMessage(false)
            }}
            padding='2px 8px'
            sizeArrow='xs'
            title={openMessageToolTip}
            titleSize='sm'
            type='topLeft'
            width='145px'
          />
        </Column>
      </>
    )
  }

  const costUnipay = () => {
    return (
      <>
        <Spacer.Horizontal size={8} />
        <Container isWrap>
          <Row
            customWidth={minWidthRow}
            minWidth={minWidthRow}
            width={minWidthRow}
          >
            <Column>
              <Text
                customColor={colorTextQuota}
                fontSize={fontsize}
                lineHeight={lineHeight}
              >
                Tasa de interés
              </Text>
            </Column>
            <Column alignItems='end'>
              <Text
                customColor={colorTextQuota}
                fontSize={fontsize}
                lineHeight={lineHeight}
              >
                {rate}% mensual
              </Text>
            </Column>
          </Row>
          <Spacer.Horizontal size={4} />
          <Row
            customWidth={minWidthRow}
            minWidth={minWidthRow}
            width={minWidthRow}
          >
            <Column>
              <Text
                customColor={colorTextQuota}
                fontSize={fontsize}
                lineHeight={lineHeight}
              >
                Valor cuota
              </Text>
            </Column>
            <Column alignItems='end'>
              <Text
                customColor={colorTextQuota}
                fontSize={fontsize}
                lineHeight={lineHeight}
              >
                {quotas}x{' '}
                {currencyFormat({
                  number: parseInt(quotaValue),
                  bool: false
                })}
              </Text>
            </Column>
          </Row>
          <Spacer.Horizontal size={4} />
          <Row
            alignItems='center'
            customWidth={minWidthRow}
            minWidth={minWidthRow}
            width={minWidthRow}
          >
            <Column customWidth={isMobile ? '10' : '17'}>
              <Text
                customColor={colorTextQuota}
                fontSize={fontsize}
                lineHeight={lineHeight}
              >
                CTC
              </Text>
            </Column>
            <Column
              clickable='pointer'
              customWidth='5'
              margin='2px 0px 0px 0px'
            >
              <Icon
                clickable='pointer'
                color={colorTextQuota}
                customSize={12}
                name='InfoThin2'
                onClick={() => {
                  setOpenMessage(true)
                }}
              />
            </Column>
            <Column alignItems='end'>
              <Text
                customColor={colorTextQuota}
                fontSize={fontsize}
                lineHeight={lineHeight}
              >
                {currencyFormat({
                  number: parseInt(totalCreditCost),
                  bool: false
                })}
              </Text>
            </Column>
          </Row>
          {openMessage && (
            <Row
              left={isMobile ? '-47%' : '-38px'}
              margin='10px 0 0 0'
              position='relative'
            >
              {messageTooltip('Costo total del crédito')}
            </Row>
          )}
        </Container>
      </>
    )
  }

  useEffect(() => {
    const handleClick = () => {
      if (openMessage) {
        setOpenMessage(false)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [openMessage])

  return (
    <>
      <Row alignItems='center'>
        <Container customWidth={witdhIcon}>{getIcon(cardType)}</Container>
        <Spacer.Vertical size={8} />
        <Spacer.Vertical size={2} />
        <Container>
          <Text
            color={
              cardType === PAYMENT_CARD_TYPE[5] ||
              cardType === PAYMENT_CARD_TYPE[6]
                ? 'black'
                : 'gray'
            }
            fontSize='md'
          >
            {handleNumberCard(cardNumber, cardType, valorCondition)}{' '}
          </Text>
        </Container>
      </Row>
      {cardType === PAYMENT_CARD_TYPE[5] && costUnipay()}
    </>
  )
}
