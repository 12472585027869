import { Container, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { KLAP_PAY_NAMES } from 'shared/helpers/paymentSystems'

export interface PropsCard {
  paymentName: string
}

export const KlapPayment = ({ paymentName }: PropsCard): React.ReactElement => {
  const getKlapType = () => {
    if (
      paymentName === KLAP_PAY_NAMES[0] ||
      paymentName === KLAP_PAY_NAMES[1]
    ) {
      return 'Tarjeta de débito'
    }
    return 'Tarjeta de crédito'
  }

  return (
    <Container
      isWrap
      padding='10px 0px'
    >
      <Row alignItems='center'>
        <Container>
          <Row alignItems='center'>
            <Text fontSize='md'>{getKlapType()}</Text>
          </Row>
        </Container>
      </Row>
    </Container>
  )
}
