import {
  Container,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { formatCardNumber } from 'shared/helpers'

export interface BankStatePaymentProps {
  bankStateImage: string
  cardNumber: string
}

export const BankStatePayment = ({
  bankStateImage,
  cardNumber
}: BankStatePaymentProps): React.ReactElement => {
  return (
    <Row alignItems='center'>
      <Picture
        height='24px'
        src={bankStateImage}
        width='auto'
      />
      <Spacer.Vertical size={8} />
      <Container>
        <Text
          color='gray'
          fontSize='md'
        >
          {cardNumber ? <>{formatCardNumber(cardNumber)}</> : 'Banco Estado'}
        </Text>
      </Container>
    </Row>
  )
}
