import {
  Column,
  Container,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers'
import { eventClick, formatDate, useMobile } from '@smu-chile/pkg-unimarc-hooks'
interface InformationOrderProps {
  creationDate: string
  sequence: string
}

export const InformationOrder = (
  props: InformationOrderProps
): React.ReactElement => {
  const { isMobile } = useMobile()
  const fontSizeTextDate = isMobile ? 'md' : 'lg'
  const fontSizeTextSequence = isMobile ? 'lg' : '2xl'

  return (
    <Container>
      <Row>
        <Column
          alignItems='start'
          maxWidth='100%'
          minWidth='50%'
        >
          <Text
            color='gray'
            fontSize={fontSizeTextDate}
            textAlign='left'
          >{`Fecha: ${formatDate(props.creationDate).replace(
            /-/gi,
            '/'
          )}`}</Text>
          <Spacer.Horizontal size={4} />
          <Text
            fontSize={fontSizeTextSequence}
            fontWeight='medium'
            textAlign='left'
          >
            {`Nº ${props.sequence}`}
          </Text>
        </Column>

        <BigScreen>
          <Column
            alignItems='end'
            maxWidth='50%'
            minWidth='50%'
            onClick={() => {
              eventClick({
                event: 'interacciones-mis-compras',
                eventCategory: 'ver-todas-mis-compras'
              })
              window.location.href = '/'
            }}
          >
            <Spacer.Horizontal size={12} />
            <Text
              clickable='pointer'
              color='gray'
              fontWeight='medium'
              underline='always'
            >
              Ver todas mis compras
            </Text>
          </Column>
        </BigScreen>
      </Row>
    </Container>
  )
}
