import {
  Container,
  Column,
  Spacer,
  Button,
  ButtonStatus
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ResponseOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import router from 'next/router'
import { OrderActionDetail } from '../OrderActionDetail'
import { getParamsInURL, useEvents } from '@smu-chile/pkg-unimarc-hooks'
import styles from '../../../Components.module.css'

interface RepeatPurchaseProps {
  cartLimitArray: {
    cartLimit: number
    id: string
  }[]
  data: ResponseOrder
  loadingButton: ButtonStatus
  openModalRepeatPurchase?: boolean
  paramsApp: string | boolean
  sourcePage: string
  text: string
  ticketNumber: string
  setLoadingButton: React.Dispatch<React.SetStateAction<ButtonStatus>>
  setOpenModalRepeatPurchase: (data: boolean) => void
}

export const RepeatPurchase = ({
  cartLimitArray = [],
  data,
  loadingButton,
  openModalRepeatPurchase,
  paramsApp,
  sourcePage,
  text,
  ticketNumber,
  setLoadingButton,
  setOpenModalRepeatPurchase
}: RepeatPurchaseProps) => {
  const seller = getParamsInURL('seller')

  useEvents({
    eventType: 'hasRemovedProducts',
    callBack: async ({ detail: { hasRemovedProducts } }) => {
      setLoadingButton('initial')
      if (hasRemovedProducts) return
      setOpenModalRepeatPurchase(true)
    }
  })

  useEvents({
    eventType: 'closeNoStockModal',
    callBack: async ({ detail: { closeNoStockModal } }) => {
      setOpenModalRepeatPurchase(closeNoStockModal)
      setLoadingButton('initial')
    }
  })

  return (
    <>
      <BigScreen>
        <OrderActionDetail
          alignItems='end'
          cartLimitArray={cartLimitArray}
          items={data?.data?.items}
          justifyContent='end'
          loadingButton={loadingButton}
          maxWidthColumn='100'
          openModalRepeatPurchase={openModalRepeatPurchase}
          setLoadingButton={setLoadingButton}
          tagInfo={{
            orderState: text.toLowerCase(),
            sequence: data?.data?.sequence,
            totalizers: data?.data?.totalizers
          }}
          widthButton='202.05px'
        />
      </BigScreen>
      <SmallScreen>
        <>
          {/* button repeat purchase*/}

          <Container
            absoluteDefault='bottomRight'
            alignItems='center'
            backgroundColor={getGlobalStyle('--color-base-white')}
            border={`${getGlobalStyle(
              '--border-width-3xs'
            )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
            customHeight='85px'
            customWidth='100'
            id='button--repeat-purchase'
            justifyContent='center'
            position='fixed'
            zIndex={getGlobalStyle('--z-index-5')}
          >
            {!paramsApp && (
              <OrderActionDetail
                alignItems='center'
                cartLimitArray={cartLimitArray}
                items={data.data.items}
                justifyContent='center'
                loadingButton={loadingButton}
                maxWidthColumn='100'
                openModalRepeatPurchase={openModalRepeatPurchase}
                setLoadingButton={setLoadingButton}
                tagInfo={{
                  orderState: text.toLowerCase(),
                  sequence: data?.data?.sequence,
                  totalizers: data?.data?.totalizers
                }}
                widthButton='335px'
              />
            )}
            {paramsApp && (
              <Column
                alignItems='center'
                justifyContent='center'
                padding='0 16px'
              >
                <Spacer.Horizontal size={4} />
                <Button
                  borderRadius={getGlobalStyle('--border-radius-xl')}
                  customClassName={styles.repeatPurchaseBtn}
                  fontWeight='500'
                  height='45px'
                  label='Repetir compra'
                  minWidth='100%'
                  onClick={() => {
                    router.push(
                      `/${sourcePage}?og=${ticketNumber}&seller=${seller}&source=APP&repeatpurchase`
                    )
                  }}
                  size='xs'
                  type='primary'
                />
                <Spacer.Horizontal size={16} />
              </Column>
            )}
          </Container>

          {/* button repeat purchase*/}
          {!paramsApp && <Spacer.Horizontal size={96} />}
        </>
      </SmallScreen>
    </>
  )
}
