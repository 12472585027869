import {
  Button,
  Column,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { eventClick } from '@smu-chile/pkg-unimarc-hooks'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'
import { statusCancelling } from 'shared/helpers'
interface OrderActionProps {
  order: Order
  sourceFlow: string
}
export const OrderAction = ({
  order,
  sourceFlow
}: OrderActionProps): React.ReactElement => {
  const { orderId, status, totalPrepicking, total } = order
  const arrayOrder = orderId.split('-')
  const ticketNumber = arrayOrder[0]
  const seller = arrayOrder[1]
  return (
    <>
      <Text
        clickable='pointer'
        color='black'
        fontSize='2xl'
        fontWeight='regular'
        textAlign='left'
      >
        {total}
      </Text>
      {(!totalPrepicking || total === totalPrepicking) && (
        <Spacer.Horizontal size={24} />
      )}
      {!statusCancelling.includes(status) &&
        totalPrepicking &&
        total !== totalPrepicking && (
          <Text
            clickable='pointer'
            color='inactive'
            fontSize='lg'
            fontWeight='regular'
            textAlign='left'
            underline='line-through'
          >
            {totalPrepicking}
          </Text>
        )}
      <Row clickable='pointer'>
        <Column
          alignItems='end'
          clickable='pointer'
          justifyContent='end'
          margin='6px 0 0'
        >
          <Button
            borderRadius='24px'
            fontWeight='500'
            height='36px'
            label='Ver compra'
            minWidth='156px'
            onClick={() => {
              eventClick({
                event: 'interacciones-mis-compras',
                eventCategory: 'ver compra'
              })
              const sourceHelpCenter =
                sourceFlow === 'ClaimFlow' ? '&HelpCenter=true' : ''
              window.location.href = `/${sourceFlow}?og=${ticketNumber}&seller=${seller}${sourceHelpCenter}`
            }}
            size='xs'
            status='initial'
            type='primary-gray'
          />
        </Column>
      </Row>
    </>
  )
}
