import {
  Backbone,
  Column,
  Container,
  Divider,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const ShelfListLoaderSmall = (): React.ReactElement => {
  return (
    <Container isWrap>
      <Row
        alignItems='end'
        justifyContent='end'
      >
        <Column maxWidth='45px'>
          <Container
            alignItems='center'
            backgroundColor={getGlobalStyle('--color-neutral-gray-light')}
            borderRadius='100%'
            justifyContent='center'
            maxHeight='43px'
            maxWidth='43px'
            minHeight='43px'
          >
            <Backbone
              backgroundColor={getGlobalStyle('--color-loaders-background')}
              borderRadius='100%'
              foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
              height={50}
              speed={2}
              type='Generic'
              width={100}
            />
          </Container>
        </Column>

        <Column
          maxWidth='190px'
          padding='0px 0px 0px 16px'
        >
          <Row>
            <Column
              maxHeight='22px'
              maxWidth='189px'
            >
              <Backbone
                backgroundColor={getGlobalStyle('--color-loaders-background')}
                borderRadius='8px'
                foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
                height={38}
                speed={2}
                type='Generic'
                width={100}
              />
            </Column>
          </Row>
          <Spacer.Horizontal size={4} />
          <Row>
            <Column
              maxHeight='22px'
              maxWidth='77px'
            >
              <Backbone
                backgroundColor={getGlobalStyle('--color-loaders-background')}
                borderRadius='8px'
                foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
                height={47}
                speed={2}
                type='Generic'
                width={100}
              />
            </Column>
          </Row>
          <Spacer.Horizontal size={4} />
          <Row>
            <Column
              maxHeight='22px'
              maxWidth='140px'
            >
              <Backbone
                backgroundColor={getGlobalStyle('--color-loaders-background')}
                borderRadius='8px'
                foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
                height={38}
                speed={2}
                type='Generic'
                width={100}
              />
            </Column>
          </Row>
        </Column>

        <Column>
          <Row
            alignItems='end'
            justifyContent='end'
          >
            <Column
              maxHeight='22px'
              maxWidth='55px'
            >
              <Backbone
                backgroundColor={getGlobalStyle('--color-loaders-background')}
                borderRadius='8px'
                foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
                height={38}
                speed={2}
                type='Generic'
                width={100}
              />
            </Column>
          </Row>
        </Column>
      </Row>
      <Spacer.Horizontal size={16} />
      <Row>
        <Column
          margin='0 12px 0 0'
          maxWidth='155px'
        >
          <Backbone
            backgroundColor={getGlobalStyle('--color-loaders-background')}
            borderRadius='8px'
            foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
            height={35}
            speed={2}
            type='Generic'
            width={100}
          />
        </Column>
        <Column maxWidth='155px'>
          <Backbone
            backgroundColor={getGlobalStyle('--color-loaders-background')}
            borderRadius='8px'
            foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
            height={35}
            speed={2}
            type='Generic'
            width={100}
          />
        </Column>
      </Row>
      <Row>
        <Divider
          color={getGlobalStyle('--color-primary-disabled')}
          thickness={1}
          width={90}
        />
      </Row>
    </Container>
  )
}
