import { Container, Icon, Text } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { ChangeEvent, createRef, useState } from 'react'
import styles from './SelectFilter.module.css'

interface SelectFilterProps {
  onChange?: (option: IOptionSelect) => void
  options: IOptionSelect[]
  value: IOptionSelect
}

export interface IOptionSelect {
  label: string
  placeholder?: string
  value: string | number
}

export const SelectFilter = ({
  onChange,
  options,
  value
}: SelectFilterProps) => {
  const { isMobile } = useMobile()
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (option: IOptionSelect) => {
    onChange(option)
    setIsOpen(false)
  }

  const textRendered = value.placeholder ? value.placeholder : value.label

  if (isMobile) {
    const orderSelectRef = createRef<HTMLSelectElement>()

    const openMobile = () => {
      orderSelectRef.current?.focus()
      setIsOpen(!isOpen)
    }

    const handleChangeMobile = (event: ChangeEvent<HTMLSelectElement>) => {
      const optionSelected = options.find((option) => {
        return option.value === +event.target.value
      })
      onChange(optionSelected)
      setIsOpen(false)
    }

    return (
      <div
        className={styles.select__container}
        onClick={openMobile}
      >
        <select
          className={`${styles.select__filter} ${styles['select__filter--mobile']}`}
          onChange={handleChangeMobile}
          ref={orderSelectRef}
          value={value.value}
        >
          {options.map((option, key) => {
            return (
              <option
                key={key}
                value={option.value}
              >
                {option.label}
              </option>
            )
          })}
        </select>
        {!isOpen && (
          <Container
            className={styles.select__icon}
            margin='2px 0 0 0'
            maxHeight='16px'
            maxWidth='16px'
            position='absolute'
          >
            <Icon
              clickable='pointer'
              color={getGlobalStyle('--color-neutral-gray-dark')}
              customSize={10.56}
              name='DropDownClose'
            />
          </Container>
        )}
        {isOpen && (
          <Container
            className={styles.select__icon}
            clickable='pointer'
            margin='2px 0 0 0'
            maxHeight='16px'
            maxWidth='16px'
            position='absolute'
          >
            <Icon
              clickable='pointer'
              color={getGlobalStyle('--color-neutral-gray-dark')}
              customSize={10.56}
              name='DropDownOpen'
            />
          </Container>
        )}
      </div>
    )
  }

  return (
    <Container>
      {isOpen && (
        <a
          className={styles['select__list--bg']}
          href='#'
          onClick={() => {
            return setIsOpen(!isOpen)
          }}
        />
      )}
      <div
        className={styles.select__container}
        onClick={() => {
          return setIsOpen(!isOpen)
        }}
      >
        <div className={styles.select__filter}>
          <Text
            clickable='pointer'
            color='black'
            fontSize='md'
            lineHeight='19px'
            type='span'
          >
            {textRendered}
          </Text>
          {!isOpen && (
            <Container
              margin='5px 0 0 0'
              maxWidth='16px'
              minHeight='16px'
            >
              <Icon
                clickable='pointer'
                color={getGlobalStyle('--color-neutral-black')}
                customSize={10.56}
                name='DropDownClose'
              />
            </Container>
          )}
          {isOpen && (
            <Container
              margin='5px 0 0 0'
              maxWidth='16px'
              minHeight='16px'
            >
              <Icon
                clickable='pointer'
                color={getGlobalStyle('--color-neutral-black')}
                customSize={10.56}
                name='DropDownOpen'
              />
            </Container>
          )}
          {isOpen && (
            <div className={styles.select__list}>
              <ul>
                {options.map((option, key) => {
                  const selected =
                    value.value === option.value
                      ? styles['select__options--selected']
                      : ''
                  return (
                    <li
                      className={`${styles.select__options} ${selected}`}
                      key={key}
                      onClick={() => {
                        return handleChange(option)
                      }}
                    >
                      {option.label}
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}
