import { Button } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'

interface ButtonPurchaseProps {
  tktNumber: string | boolean
  seller: string | boolean
}

export const ButtonPurchase = ({ tktNumber, seller }: ButtonPurchaseProps) => {
  const router = useRouter()
  const { isMobile } = useMobile()
  return (
    <Button
      borderRadius={getGlobalStyle('--border-radius-xl')}
      fontWeight='500'
      label='Volver a mi compra'
      onClick={() => {
        return router.push(`OrderDetails?og=${tktNumber}&seller=${seller}`)
      }}
      size='md'
      type='primary-gray'
      width={isMobile ? ' 100%' : '335px'}
    />
  )
}
