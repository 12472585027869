import {
  Button,
  ButtonProps,
  Column,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { eventClick, useMobile } from '@smu-chile/pkg-unimarc-hooks'

interface ButtonPaginationProps {
  buttonsType?: ButtonProps['type']
  customClass?: string
  customClassSelected?: string
  page: number | string
  selected: boolean
  onClick: (numberPage: number) => void
}

export const ButtonPagination = ({
  buttonsType,
  customClass,
  customClassSelected,
  page,
  selected,
  onClick
}: ButtonPaginationProps) => {
  const { isMobile } = useMobile()

  const handleClick = () => {
    eventClick({
      event: 'interacciones_mis_listas',
      eventAction: 'clic',
      eventCategory: 'indexacion_lista',
      eventLabel: `${page}`
    })
    if (page === '...') {
      return null
    }
    if (page !== 0) return onClick(page as number)
    return null
  }

  const size = '30px'
  const sizeColumns = isMobile ? '30' : '40'

  const widthMobile = isMobile ? 'max-content' : size
  const widthThreeDots = page === '...' || page === 0 ? widthMobile : size

  const renderText = `${page === 0 ? '...' : page}`
  const columnWidth = `${page === 0 ? '10' : sizeColumns}px`

  if (selected) {
    return (
      <Column maxWidth={size}>
        <Button
          borderRadius={size}
          customClassName={customClassSelected}
          fontSize='12px'
          fontWeight='500'
          height={size}
          label={`${page}`}
          minWidth={size}
          onClick={handleClick}
          type={buttonsType}
        />
      </Column>
    )
  }

  return (
    <Column
      alignItems='center'
      borderRadius='100%'
      clickable='pointer'
      customClassName={renderText === '...' ? '' : customClass}
      justifyContent='center'
      maxWidth={columnWidth}
      minHeight={size}
      minWidth={widthThreeDots}
      onClick={handleClick}
    >
      <Text
        clickable='pointer'
        customColor={getGlobalStyle('--color-primary-disabled')}
        fontSize='sm'
        fontWeight='medium'
        textAlign='center'
      >
        {renderText}
      </Text>
    </Column>
  )
}
