import { Container } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ShelfListLoaderBig } from './BigScreen'
import { ShelfListLoaderSmall } from './SmallScreen'

export const ShelfListLoader = (): React.ReactElement => {
  return (
    <>
      <BigScreen>
        <Container
          border='1px solid #E5E5E5'
          borderRadius='10px'
          justifyContent='center'
          margin='8px 0'
          maxWidth='100%'
          padding='26px 16px 16px 26px'
        >
          <ShelfListLoaderBig />
        </Container>
      </BigScreen>

      <SmallScreen>
        <ShelfListLoaderSmall />
      </SmallScreen>
    </>
  )
}
