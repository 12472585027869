import { Container, Picture } from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { removeAccentsAndSpaces } from 'shared/helpers'

export const ImagePage = (props: { image: string }) => {
  const { image } = props
  const { isMobile } = useMobile()
  return (
    <Container justifyContent='center'>
      {/* Imagen */}
      <Picture
        alt={image}
        height={'200px'}
        id={getBemId(removeAccentsAndSpaces('imagePage'), 'image')}
        src={image}
        width={isMobile ? '200px' : '220px'}
      />
    </Container>
  )
}
