import {
  Button,
  ButtonStatus,
  Column,
  Container,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  currentOrderformInfoTag,
  useOrderForm,
  useUpdateQuantityQuickly
} from '@smu-chile/pkg-unimarc-hooks'
import { Item } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { Item as TagItem } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { totalizerId, TotalizerOrderFormItem } from '../TotalizerResume'
import styles from '../../../Components.module.css'

interface OrderActionDetailProps {
  alignItems?: 'center' | 'start' | 'end' | 'baseline' | 'stretch'
  cartLimitArray?: {
    cartLimit: number
    id: string
  }[]
  items?: (Item | TagItem)[]
  justifyContent?: 'center' | 'start' | 'end' | 'between' | 'around'
  loadingButton: ButtonStatus
  maxWidthColumn?: string
  openModalRepeatPurchase?: boolean
  clickRepeatPurchase?: boolean
  tagInfo: {
    orderState: string
    totalizers: TotalizerOrderFormItem[]
    sequence: string
  }
  widthButton?: string
  setLoadingButton: React.Dispatch<React.SetStateAction<ButtonStatus>>
}

export const OrderActionDetail = ({
  alignItems,
  cartLimitArray = [],
  items,
  justifyContent,
  loadingButton,
  maxWidthColumn,
  tagInfo,
  widthButton,
  setLoadingButton
}: OrderActionDetailProps): React.ReactElement => {
  const { data } = useOrderForm()
  const { handleMutate } = useUpdateQuantityQuickly()

  if (!data?.data || !data?.data?.orderFormId || !data) {
    return null
  }

  const tagRepeatOrder = () => {
    const subtotal = {
      name: 'Subtotal',
      value: totalizerId(tagInfo?.totalizers, 'Items')
    }?.value
    const discounts = {
      name: 'Subtotal',
      value: totalizerId(tagInfo?.totalizers, 'Discounts')
    }?.value

    currentOrderformInfoTag({
      context: 'repetir-compra',
      customItems: items,
      defaultCategory: false,
      estado_compra: tagInfo?.orderState,
      event: 'add_to_cart',
      id_compra: tagInfo.sequence,
      value: subtotal + discounts
    })
  }

  const handleRepeatOrder = () => {
    setLoadingButton('loading')
    const itemsAdded = []
    items?.forEach((item) => {
      let totalQuantity = item?.quantity
      const {
        additionalInfo: { brandName },
        id,
        imageUrl,
        name
      } = item
      data?.data?.items?.forEach((element) => {
        if (element?.id == id) totalQuantity += element?.quantity
      })
      const cartLimit = cartLimitArray?.filter((object) => {
        return object.id == id
      })
      let cartLimitToSend = Number(cartLimit?.[0]?.cartLimit)
      // Validation to send max quantity if there cartLimit
      const quantityToSend =
        cartLimit.length > 0 &&
        totalQuantity > cartLimitToSend &&
        cartLimitToSend !== 0
          ? cartLimitToSend
          : Number(totalQuantity)
      if (Number.isNaN(cartLimitToSend)) cartLimitToSend = undefined

      itemsAdded.push({
        brandName: brandName,
        cartLimit: cartLimitToSend,
        id,
        image: imageUrl,
        name: name,
        netContent: undefined,
        replacedProduct: null,
        quantity: quantityToSend
      })
    })
    tagRepeatOrder()
    handleMutate(itemsAdded)
  }

  return (
    <>
      <Container
        alignItems='end'
        justifyContent='end'
      >
        <Row>
          <Column
            alignItems={alignItems}
            justifyContent={justifyContent}
            maxWidth={maxWidthColumn}
          >
            <Button
              borderRadius='24px'
              customClassName={styles.repeatPurchaseBtn}
              fontWeight='500'
              height='45px'
              label='Repetir compra'
              minWidth={widthButton}
              onClick={() => {
                handleRepeatOrder()
              }}
              size='xs'
              status={loadingButton}
              type='primary'
            />
          </Column>
        </Row>
      </Container>
    </>
  )
}
