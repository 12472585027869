import { useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  Column,
  Container,
  ModalBottomSheet,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  dataLayerUniqueTypeEvent,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'

interface ModalErrorProps {
  imageModalErrorCancelPurchase: string
  openModalError: boolean
  sequence: string
  status: string
  setOpenModalError: React.Dispatch<React.SetStateAction<boolean>>
}
export const ModalError = ({
  imageModalErrorCancelPurchase,
  openModalError,
  sequence,
  status,
  setOpenModalError
}: ModalErrorProps) => {
  const router = useRouter()
  const { isMobile } = useMobile()

  const handleBack = () => {
    setOpenModalError(false)
    router.back()
  }

  useEffect(() => {
    dataLayerUniqueTypeEvent({
      estado_compra: status,
      id_compra: sequence,
      page: 'mis-compras-cancelación-no-posible'
    })
  }, [])

  return (
    <ModalBottomSheet
      hideSecondaryCTA={true}
      isMobile={isMobile}
      isOpen={openModalError}
      onClose={() => {
        return setOpenModalError(false)
      }}
      primaryBtnLabel='Entendido'
      primaryCTA={handleBack}
    >
      <>
        <Column
          alignItems='center'
          margin='36px 0 0 0'
        >
          <Picture
            alt='palta'
            height='auto'
            src={imageModalErrorCancelPurchase}
            width='198px'
          />
          <Container
            justifyContent='center'
            margin='16px 0'
          >
            <Text
              fontSize='xl'
              fontWeight='medium'
              textAlign='center'
            >
              ¡Oh! justo nos adelantamos...
            </Text>
          </Container>
        </Column>
        <Row maxWidth='351px'>
          <Text
            fontWeight='regular'
            isFlex
            textAlign='center'
          >
            El tiempo para cancelar tu compra ya caducó. Estamos preparando tus
            productos y pronto vamos en camino.
          </Text>
        </Row>
      </>
    </ModalBottomSheet>
  )
}
