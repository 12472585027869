import {
  Container,
  Icon,
  IconProps,
  Picture
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { TTokens } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ITokens'

interface OrderIconProps {
  borderRadiusToken?: TTokens
  colorContainerIcon?: TTokens
  colorIcon?: TTokens
  customSizeIcon?: number
  heighContainer?: string
  nameIcon?: IconProps['name']
  srcImageOrder?: string
  strokeWidthIcon?: string
  styleContainer?: string
  widthContainer?: string
}

export const OrderIcon = ({
  borderRadiusToken = '--color-base-transparent',
  colorContainerIcon = '--color-base-transparent',
  colorIcon = '--color-base-transparent',
  customSizeIcon,
  heighContainer = '60px',
  nameIcon,
  srcImageOrder,
  strokeWidthIcon,
  styleContainer,
  widthContainer = '60px'
}: OrderIconProps): React.ReactElement => {
  return (
    <>
      <BigScreen>
        <>
          <Container
            alignItems='center'
            backgroundColor={getGlobalStyle(colorContainerIcon)}
            borderRadius={getGlobalStyle(borderRadiusToken)}
            clickable='pointer'
            customClassName={styleContainer}
            justifyContent='center'
            maxHeight={heighContainer}
            maxWidth={widthContainer}
            minHeight={heighContainer}
            minWidth={widthContainer}
          >
            {srcImageOrder && (
              <Picture
                alt='Order'
                height={customSizeIcon.toString()}
                src={srcImageOrder}
                width={customSizeIcon.toString()}
              />
            )}
            {nameIcon && (
              <Icon
                clickable='pointer'
                color={getGlobalStyle(colorIcon)}
                customSize={customSizeIcon}
                name={nameIcon}
                strokeWidth={strokeWidthIcon}
              />
            )}
          </Container>
        </>
      </BigScreen>

      <SmallScreen>
        <Container
          alignItems='center'
          backgroundColor={getGlobalStyle(colorContainerIcon)}
          borderRadius={getGlobalStyle(borderRadiusToken)}
          clickable='pointer'
          justifyContent='center'
          maxHeight={heighContainer}
          maxWidth={widthContainer}
          minHeight={heighContainer}
          minWidth={widthContainer}
        >
          {srcImageOrder && (
            <Picture
              alt='Order'
              height={customSizeIcon.toString()}
              src={srcImageOrder}
              width={customSizeIcon.toString()}
            />
          )}
          {nameIcon && (
            <Icon
              clickable='pointer'
              color={getGlobalStyle(colorIcon)}
              customSize={customSizeIcon}
              name={nameIcon}
              strokeWidth={strokeWidthIcon}
            />
          )}
        </Container>
      </SmallScreen>
    </>
  )
}
