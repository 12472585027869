import { useEffect, useState } from 'react'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { OrdersBigScreen } from './OrdersBigScreen'
import { OrdersSmallScreen } from './OrdersSmallScreen'
import {
  useMobile,
  useOrderForm,
  useNewOrders,
  useSession,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import {
  IMAGES_ICON_ORDERS_CANCEL,
  IMAGES_ICON_ORDERS_MOTO,
  IMAGES_ICON_ORDERS_STORE,
  MEMBERSHIP_SAVINGS,
  ORDER_PER_PAGE_DESKTOP,
  ORDER_PER_PAGE_MOBILE
} from 'shared/constants'
import useContentfulData from 'shared/helpers/orderIconTheme'
import { LoaderBigScreen } from './PageOrdersLoaders/LoaderBigScreen'
import { LoaderSmallScreen } from './PageOrdersLoaders/LoaderSmallScreen'
import { transformMembershipDataCF } from 'shared/helpers/membershipHeader'

interface OrderListProps {
  sourceFlow?: string
  titleBack?: string
}

const cleanParams = (params: Record<string, string | number | boolean>) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === '' || params[key] == null) {
      delete params[key]
    }
  })
  return params
}

export const OrdersList = ({
  sourceFlow = 'OrderDetails',
  titleBack = 'Mis compras'
}: OrderListProps): React.ReactElement => {
  const { data: userMembership, isLoading: isLoadingUserMembership } =
    useUserWithMembership()
  const { isLoggedIn } = useSession()
  const { isMobile } = useMobile()

  const [currentPage, setCurrentPage] = useState(1)
  const [order, setOrder] = useState<0 | 1>(0)
  const [changeOrder, setChangeOrder] = useState(false)
  const [sequence, setSequence] = useState('')

  const [membershipDataCF, setMembershipDataCF] = useState(null)
  const [isError, setIsError] = useState(false)

  const [inputListener, setInputListener] = useState('')
  const [numberOrderMinimun, setNumberOrderMinimun] = useState(false)

  const optionsCFCancel = {
    id_contentful: 'contentful-builtin-asset-content-cancel',
    options: {
      'sys.id': IMAGES_ICON_ORDERS_CANCEL[0]
    },
    type: 'assets'
  }

  const optionsCFStore = {
    id_contentful: 'contentful-builtin-asset-content-store',
    options: {
      'sys.id': IMAGES_ICON_ORDERS_STORE[0]
    },
    type: 'assets'
  }

  const optionsCFMoto = {
    id_contentful: 'contentful-builtin-asset-content-moto',
    options: {
      'sys.id': IMAGES_ICON_ORDERS_MOTO[0]
    },
    type: 'assets'
  }

  const optionsCFMembership = {
    id_contentful: 'contentful_membership',
    options: {
      content_type: 'viewBlock',
      'fields.blockId[in]': 'Menú Header',
      'fields.platform': 'Web Unimarc eComm',
      include: 5
    }
  }

  const optionsCFSavings = {
    id_contentful: 'contentful-builtin-asset-savings',
    options: {
      'sys.id': MEMBERSHIP_SAVINGS[0]
    },
    type: 'assets'
  }

  const optionsCFMembershipLevels = {
    id_contentful: 'contentful_membership_levels',
    options: {
      content_type: 'featureContentBanners',
      'fields.label[in]': 'Beneficios por nivel mobile',
      include: 4
    }
  }

  const { data: dataCFImgCancel, isLoading: isLoadingCFCancel } =
    useContentfulData(optionsCFCancel)
  const { data: dataCFImgStore, isLoading: isLoadingCFStore } =
    useContentfulData(optionsCFStore)
  const { data: dataCFImgMoto, isLoading: isLoadingCFMoto } =
    useContentfulData(optionsCFMoto)
  const { data: dataCFMembership } = useContentfulData(optionsCFMembership)
  const { data: dataCFSaving, isLoading: isLoadingCFSavings } =
    useContentfulData(optionsCFSavings)
  const { data: dataCFMembershipLeves, isLoading: isLoadingCFMembershipLeves } =
    useContentfulData(optionsCFMembershipLevels)

  const [ordersType, setOrdersType] = useState(true)
  const params = {
    order: order.toString(),
    page: currentPage.toString(),
    payment_denied: !ordersType ? 'true' : null,
    per_page: isMobile
      ? ORDER_PER_PAGE_MOBILE.toString()
      : ORDER_PER_PAGE_DESKTOP.toString(),
    sequence: ordersType ? sequence.toString() : null
  }

  const cleanedParams = cleanParams(params)

  const {
    data: orders,
    isLoading: isLoadingOrders,
    isFetching: isFetchingOrders,
    refetch
  } = useNewOrders(cleanedParams, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: 5000
  })

  useEffect(() => {
    refetch()
  }, [sequence])

  const { data: userData, isLoading: userDataLoading } = useOrderForm()

  useEffect(() => {
    if (dataCFMembership) {
      setMembershipDataCF(
        transformMembershipDataCF({
          data: dataCFMembership,
          imageSaving: dataCFSaving,
          imageLevels: dataCFMembershipLeves,
          userMembership
        })
      )
    }
  }, [dataCFMembership, dataCFSaving, dataCFMembershipLeves, userMembership])

  const flagEmptyOrdersIncomplete =
    orders?.data?.orders?.length === 0 && !ordersType ? true : false
  const flagEmptyOrders =
    orders?.data?.orders?.length === 0 && ordersType ? true : false

  if (
    isLoadingCFCancel ||
    isLoadingCFStore ||
    isLoadingCFMoto ||
    userDataLoading ||
    isLoadingCFSavings ||
    isLoadingCFMembershipLeves ||
    isLoadingUserMembership
  ) {
    return (
      <>
        <BigScreen>
          <LoaderBigScreen />
        </BigScreen>
        <SmallScreen>
          <LoaderSmallScreen />
        </SmallScreen>
      </>
    )
  }

  const srcImageOrderCancel =
    dataCFImgCancel?.['items'][0]?.fields?.file?.url || ''
  const srcImageOrderStore =
    dataCFImgStore?.['items'][0]?.fields?.file?.url || ''
  const srcImageOrderMoto = dataCFImgMoto?.['items'][0]?.fields?.file?.url || ''

  const objetSrcImagesOrders = {
    srcCancel: srcImageOrderCancel,
    srcStore: srcImageOrderStore,
    srcMoto: srcImageOrderMoto
  }

  return (
    <>
      <BigScreen>
        <OrdersBigScreen
          changeOrder={changeOrder}
          currentPage={currentPage}
          data={orders}
          flagEmptyOrders={flagEmptyOrders}
          flagEmptyOrdersIncomplete={flagEmptyOrdersIncomplete}
          inputListener={inputListener}
          isError={isError}
          isFetching={isFetchingOrders}
          isLoading={isLoadingOrders}
          isLoggedIn={isLoggedIn}
          membershipDataCF={membershipDataCF}
          numberOrderMinimun={numberOrderMinimun}
          objetSrcImagesOrders={objetSrcImagesOrders}
          ordersType={ordersType}
          sequence={sequence}
          setChangeOrder={setChangeOrder}
          setCurrentPage={setCurrentPage}
          setInputListener={setInputListener}
          setIsError={setIsError}
          setNumberOrderMinimun={setNumberOrderMinimun}
          setOrder={setOrder}
          setOrdersType={setOrdersType}
          setSequence={setSequence}
          sourceFlow={sourceFlow}
          userData={userData}
        />
      </BigScreen>

      <SmallScreen>
        <OrdersSmallScreen
          currentPage={currentPage}
          data={orders}
          flagEmptyOrders={flagEmptyOrders}
          flagEmptyOrdersIncomplete={flagEmptyOrdersIncomplete}
          inputListener={inputListener}
          isError={isError}
          isFetching={isFetchingOrders}
          isLoading={isLoadingOrders}
          isLoggedIn={isLoggedIn}
          numberOrderMinimun={numberOrderMinimun}
          objetSrcImagesOrders={objetSrcImagesOrders}
          ordersType={ordersType}
          sequence={sequence}
          setCurrentPage={setCurrentPage}
          setInputListener={setInputListener}
          setIsError={setIsError}
          setNumberOrderMinimun={setNumberOrderMinimun}
          setOrder={setOrder}
          setOrdersType={setOrdersType}
          setSequence={setSequence}
          sourceFlow={sourceFlow}
          titleBack={titleBack}
        />
      </SmallScreen>
    </>
  )
}
