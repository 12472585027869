/**
 * Manage steps to change the current page
 * @param currentPage currentPage to show
 * @param setCurrentPage handle current page
 * @returns functions to manage steps (next and back)
 */
export const useClaimSteps = (
  currentPage: number,
  setCurrentPage: (value: number) => void
) => {
  const nextPage = () => {
    // update currentPage by user action
    setCurrentPage((currentPage >= 2) ? currentPage : currentPage + 1)
    window.scrollTo(0, 0)
  }

  const prevPage = () => {
    setCurrentPage((currentPage === 0) ? currentPage : currentPage - 1)
    window.scrollTo(0, 0)
  }

  return {
    nextPage,
    prevPage
  }
}