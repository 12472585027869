import { useOrderForm, useSession } from '@smu-chile/pkg-unimarc-hooks'
import { useCallback, useEffect, useState } from 'react'

export const useCustomValidation = (): boolean => {
  const [validateLogOut, setValidateLogOut] = useState<boolean>(false)
  const session = useSession()
  const orderForm = useOrderForm()

  const { message, isLoggedIn, isLoading } = session || {}
  const { clientProfileData } = orderForm?.data?.data || {}
  const { email, firstName, phone } = clientProfileData || {}

  const isSessionInvalid = useCallback(() => {
    return (
      ['No session', 'No x-id-token', 'No Autorizado'].includes(message) ||
      !isLoggedIn
    )
  }, [message, isLoggedIn])

  const isProfileDataIncomplete = useCallback(() => {
    return !email || !firstName || !phone
  }, [email, firstName, phone])

  useEffect(() => {
    if (!isLoading && (isSessionInvalid() || isProfileDataIncomplete())) {
      setValidateLogOut(true)
    } else {
      setValidateLogOut(false)
    }
  }, [isLoading, isSessionInvalid, isProfileDataIncomplete])

  return validateLogOut
}
