import {
  Column,
  Container,
  Divider,
  Icon,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { eventClick } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { removeAccentsAndSpaces } from 'shared/helpers'

interface GoBackProps {
  eventCategory?: string
  title: string
  customClick?: () => void
}
export const GoBack = ({
  customClick,
  eventCategory = 'volver',
  title
}: GoBackProps) => {
  const router = useRouter()

  const goBackLogic = () => {
    if (customClick) {
      return customClick()
    }
    return router.back()
  }

  const handleOnClick = () => {
    goBackLogic()
    eventClick({ event: 'interacciones-mis-compras', eventCategory })
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 100)
  }

  return (
    <>
      <BigScreen>
        <Container
          alignItems='center'
          maxHeight='54px'
          padding='10px 32px'
        >
          <Row
            alignItems='center'
            clickable='pointer'
            maxWidth='320px'
            onClick={handleOnClick}
          >
            <Column
              clickable='pointer'
              justifyContent='center'
              maxWidth='25px'
            >
              <Icon
                clickable='pointer'
                color={getGlobalStyle('--color-base-black')}
                name='Back3'
                sizes='xs'
              />
            </Column>
            <Column
              clickable='pointer'
              justifyContent='center'
              padding='6px 0px'
            >
              <Text
                clickable='pointer'
                fontSize='2xl'
                fontWeight='semibold'
                id={getBemId(removeAccentsAndSpaces(title), 'Title')}
              >
                {title}
              </Text>
            </Column>
          </Row>
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container isWrap>
          <Row
            alignItems='center'
            customWidth='100'
            isWrap
            maxHeight='54px'
            onClick={handleOnClick}
            padding='10px 20px'
          >
            <Container
              alignItems='center'
              justifyContent='center'
            >
              <Column
                justifyContent='center'
                maxWidth='0px'
              >
                <Icon
                  color={getGlobalStyle('--color-base-black')}
                  name='Back2'
                  sizes='sm'
                />
              </Column>
              <Column
                alignItems='center'
                justifyContent='center'
                margin='0 -2%'
                padding='10px 0'
                width='100%'
              >
                <Text
                  fontSize='lg'
                  fontWeight='medium'
                  height='17px'
                  id={getBemId(removeAccentsAndSpaces(title), 'Title')}
                >
                  {title}
                </Text>
              </Column>
            </Container>
          </Row>
          <Row>
            <Divider
              borderHeight='3xs'
              color={getGlobalStyle('--color-neutral-gray-divider')}
              verticalMargin={0}
            />
          </Row>
        </Container>
      </SmallScreen>
    </>
  )
}
