import { useEffect, useState } from 'react'
import { Column, Container, Icon, Row } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ChildrenBaseContainerProps } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import { ButtonsPagination } from './ButtonsPagination'
import { eventClick, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import styles from './MobilePagination.module.css'

interface PaginationProps {
  pages: number
  selectedPage: number
  onChangePage: (numberPage: number) => void
}

const buttonProps: ChildrenBaseContainerProps = {
  alignItems: 'center',
  border: `1px solid ${getGlobalStyle('--color-primary-disabled')}`,
  borderRadius: '50%',
  clickable: 'pointer',
  justifyContent: 'center',
  maxWidth: 'max-content',
  minHeight: '40px',
  minWidth: '40px'
}

export const MobilePagination = ({
  pages,
  selectedPage,
  onChangePage
}: PaginationProps) => {
  const { isMobile, innerWidth } = useMobile()
  const [buttons, setButtons] = useState([])

  const justifyButton = buttons.length <= 5 ? 'around' : 'between'
  const buttonSize = '30px'
  const marginLeft = isMobile ? '0 7.5px 0 0' : '0 15px 0 0'
  const marginRight = isMobile ? '0 0 0 7.5px' : '0 0 0 15px'
  const marginLeftMedium = innerWidth <= 375 ? '0 5.5px 0 0' : marginLeft
  const marginRightMedium = innerWidth <= 375 ? '0 0 0 5.5px' : marginRight
  const marginLeftSmall = innerWidth <= 320 ? '0 3.5px 0 0' : marginLeftMedium
  const marginRightSmall = innerWidth <= 320 ? '0 0 0 3.5px' : marginRightMedium

  const padding = isMobile ? '24px 24px 32px' : '24px 18px'
  const pxSmall = innerWidth <= 320 ? '15px' : '17px'
  const paddingFinal = innerWidth <= 360 ? `24px ${pxSmall} 32px` : padding

  useEffect(() => {
    const auxButtons = []
    for (let index = 1; index <= pages; index++) {
      auxButtons.push(index)
    }
    setButtons([...auxButtons])
  }, [])

  return (
    <Container
      alignItems='center'
      isWrap
      justifyContent={isMobile ? 'center' : 'end'}
    >
      <Column maxWidth='fit-content'>
        <Row
          justifyContent={justifyButton}
          padding={paddingFinal}
        >
          {selectedPage > 1 && (
            <Column
              {...buttonProps}
              margin={marginLeftSmall}
              minHeight={buttonSize}
              minWidth={buttonSize}
              onClick={() => {
                const page = selectedPage - 1
                eventClick({
                  event: 'interacciones-mis-compras',
                  eventCategory: 'paginacion',
                  event_label: '<'
                })
                return onChangePage(page)
              }}
            >
              <Icon
                clickable='pointer'
                color={getGlobalStyle('--color-primary-disabled')}
                name='ArrowBackNavigate'
              />
            </Column>
          )}
          <ButtonsPagination
            buttons={buttons}
            buttonsType='secondary-ca'
            customClass={styles.numberButton}
            customClassSelected={styles.numberButtonSelected}
            margin={true}
            onChangePage={(page) => {
              eventClick({
                event: 'interacciones-mis-compras',
                eventCategory: 'paginacion',
                event_label: page
              })
              return onChangePage(page)
            }}
            selectedPage={selectedPage}
          />
          {selectedPage < buttons.length && (
            <Column
              {...buttonProps}
              margin={marginRightSmall}
              minHeight={buttonSize}
              minWidth={buttonSize}
              onClick={() => {
                const page = selectedPage + 1
                eventClick({
                  event: 'interacciones-mis-compras',
                  eventCategory: 'paginacion',
                  event_label: '>'
                })
                return onChangePage(page)
              }}
            >
              <Icon
                clickable='pointer'
                color={getGlobalStyle('--color-primary-disabled')}
                name='ArrowRightNavigate'
              />
            </Column>
          )}
        </Row>
      </Column>
    </Container>
  )
}
