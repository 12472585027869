import { Container, Row, Text } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { TTokens } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ITokens'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

interface StatusZendeskProps {
  colorBackgroundColor: string
  colorText: string
  textStatus: string
}

export const StatusZendesk = ({
  colorBackgroundColor,
  colorText,
  textStatus
}: StatusZendeskProps) => {
  const { isMobile } = useMobile()
  return (
    <Container
      customWidth='50'
      id={getBemId('statusZendesk', 'container')}
      justifyContent='end'
      padding='2px 0'
    >
      <Row
        absoluteDefault='topRight'
        alignItems='end'
        alignSelf='end'
        backgroundColor={getGlobalStyle(colorBackgroundColor as TTokens)}
        borderRadius={getGlobalStyle('--border-radius-full')}
        height={isMobile ? '23px' : '30px'}
        id={getBemId('statusZendesk', 'statusRow')}
        justifyContent='center'
        padding={isMobile ? '4px 8px' : '5px 21.5px'}
        width={isMobile ? '74px' : '120px'}
      >
        <Text
          customColor={getGlobalStyle(colorText as TTokens)}
          fontSize={isMobile ? 'sm' : 'lg'}
          id={getBemId('statusZendesk', 'statusText')}
          lineHeight={isMobile ? '15.12px' : '20.16px'}
        >
          {textStatus}
        </Text>
      </Row>
    </Container>
  )
}
