import { Container, Row, Spacer, Text } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { formatDate, useMobile } from '@smu-chile/pkg-unimarc-hooks'

interface InformationZendeskProps {
  dateTimeZendesk: string
  numberTicket: number
  valueTicket: string
}

export const InformationZendesk = ({
  dateTimeZendesk,
  numberTicket,
  valueTicket
}: InformationZendeskProps) => {
  const { isMobile, innerWidth } = useMobile()
  const trucateText = isMobile ? 20 : 60
  return (
    <Container
      id={getBemId('informationZendesk', 'container')}
      isWrap
      margin={`0 0 0 ${isMobile ? '20px' : '16px'}`}
    >
      <Row id={getBemId('informationZendesk', 'dateRow')}>
        <Text
          customColor={getGlobalStyle('--color-neutral-gray-dark')}
          fontSize={isMobile ? 'sm' : 'base'}
          id={getBemId('informationZendesk', 'dateText')}
          lineHeight={isMobile ? '15.12px' : '20.16px'}
        >
          {formatDate(dateTimeZendesk).replace(/-/gi, '/')}
        </Text>
      </Row>
      <Spacer.Horizontal size={6} />
      <Row id={getBemId('informationZendesk', 'numberRow')}>
        <Text
          fontSize={isMobile ? 'md' : 'xl'}
          fontWeight='medium'
          id={getBemId('informationZendesk', 'numberText')}
          lineHeight={isMobile ? '17.64px' : '22.68px'}
        >
          {`Nº ${numberTicket}`}
        </Text>
      </Row>
      <Spacer.Horizontal size={6} />
      <Row id={getBemId('informationZendesk', 'valueRow')}>
        <Text
          fontSize={isMobile ? 'md' : 'xl'}
          id={getBemId('informationZendesk', 'valueText')}
          lineHeight={isMobile ? '17.64px' : '22.68px'}
          truncate={innerWidth < 320 ? 17 : trucateText}
        >
          {valueTicket}
        </Text>
      </Row>
    </Container>
  )
}
