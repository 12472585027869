import { Text } from '@smu-chile/pkg-unimarc-components'

export const TextPostPicking = () => {
  return (
    <>
      <Text
        fontSize='md'
        isFlex={false}
        textAlign='justify'
      >
        <Text
          color='primary'
          fontSize='md'
          fontWeight='medium'
          isFlex={false}
          type='span'
        >
          IMPORTANTE:
        </Text>
        &nbsp;Verás
        <Text
          fontSize='md'
          fontWeight='medium'
          isFlex={false}
          type='span'
        >
          &nbsp;2 cobros&nbsp;
        </Text>
        en tu cuenta, uno al momento de pagar y otro al momento del envío.
        <Text
          fontSize='md'
          fontWeight='medium'
          isFlex={false}
          type='span'
        >
          &nbsp;Recuerda que el primero se eliminará&nbsp;
        </Text>
        dependiendo de los tiempos de tu banco.
      </Text>
    </>
  )
}
