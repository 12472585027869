import { Icon, Row, Spacer, Text } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export const FeedbackSeeker = () => {
  const { isMobile } = useMobile()
  const iconSize = isMobile ? 10.8 : 12.6
  const fontSize = isMobile ? 'xs' : 'sm'
  const marginFeedback = isMobile ? '0' : '-1px 1px'

  return (
    <>
      <Spacer.Horizontal size={4} />
      <Row margin='0 14px'>
        <Icon
          color={getGlobalStyle('--color-feedback-warning-gray')}
          customSize={iconSize}
          name='InfoFill'
        />
        <Spacer.Vertical size={4} />
        <Row margin={marginFeedback}>
          <Text
            color='grayNeutral'
            fontSize={fontSize}
          >
            Ingresa al menos 7 dígitos (Ej: 00001111)
          </Text>
        </Row>
      </Row>
    </>
  )
}
