import { Container, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

interface GenericImageProps {
  quantity: number
}

export const GenericImage = ({ quantity }: GenericImageProps) => {
  const { isMobile } = useMobile()
  const fontSize = isMobile ? 'lg' : 'xl'
  const sizeImage = isMobile ? '56px' : '64px'
  const genericNumber = isMobile ? quantity - 6 : quantity - 7

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-neutral-gray-light')}
      borderRadius='12px'
      customHeight={sizeImage}
      margin='10px 12px 10px 0'
      tagName='section'
      width={sizeImage}
    >
      <Row
        height={18}
        justifyContent='center'
        width={64}
      >
        <Text
          color='grayNeutral'
          fontSize={fontSize}
          fontWeight='medium'
        >
          +{genericNumber}
        </Text>
      </Row>
    </Container>
  )
}
