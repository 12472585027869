import { Container, Row, Spacer, Text } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { removeAccentsAndSpaces } from 'shared/helpers'

export const TextPage = (props: {
  codeError: string
  textPrimary: string
  textSecondary: string
  widthTextSecondaryDesktop?: string
  widthTextSecondaryMobile?: string
}) => {
  const {
    codeError,
    textPrimary,
    textSecondary,
    widthTextSecondaryDesktop = '420px',
    widthTextSecondaryMobile = '345px'
  } = props
  const { isMobile } = useMobile()
  return (
    <Container isWrap>
      <Row justifyContent='center'>
        <Text
          fontSize={isMobile ? 'xl' : '2xl'}
          fontWeight='semibold'
          id={getBemId(removeAccentsAndSpaces('textPrimary'), 'text_page')}
          lineHeight='25.2px'
        >
          {textPrimary}
        </Text>
      </Row>
      <Spacer.Horizontal size={12} />
      <Row justifyContent='center'>
        <Text
          fontSize={isMobile ? 'lg' : 'xl'}
          id={getBemId(removeAccentsAndSpaces('textSecondary'), 'text_page')}
          lineHeight='22.68px'
          textAlign='center'
          width={
            isMobile ? widthTextSecondaryMobile : widthTextSecondaryDesktop
          }
        >
          {textSecondary}
        </Text>
      </Row>
      <Spacer.Horizontal size={12} />
      {codeError && (
        <Row justifyContent='center'>
          <Text
            customColor={getGlobalStyle('--color-text-inactive')}
            fontSize='md'
            fontWeight='medium'
            id={getBemId(removeAccentsAndSpaces('code_error'), 'text')}
            lineHeight='17.64px'
          >
            Error: {codeError}
          </Text>
        </Row>
      )}
    </Container>
  )
}
