import { Container, Spacer, Text } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import React from 'react'

export const TittleCancel = () => {
  const { isMobile } = useMobile()
  const tittleSemiboldSize = isMobile ? 'lg' : '2xl'
  const tittleNormalSize = isMobile ? 'md' : 'xl'
  const descriptionSize = isMobile ? 'sm' : 'lg'
  return (
    <Container isWrap>
      <Text
        fontSize={tittleSemiboldSize}
        fontWeight='medium'
      >
        Motivo de cancelación&nbsp;
      </Text>
      <Text
        color='gray'
        fontSize={tittleNormalSize}
      >
        (Opcional)
      </Text>
      <SmallScreen>
        <Spacer.Horizontal size={8} />
      </SmallScreen>
      <BigScreen>
        <Spacer.Horizontal size={12} />
      </BigScreen>
      <Text
        color='neutral'
        fontSize={descriptionSize}
      >
        La siguiente información es solo para mejorar tu experiencia de compra y
        no será un factor para la aprobación de tu cancelación.
      </Text>
    </Container>
  )
}
