import React from 'react'
import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { getParamsInURL } from '@smu-chile/pkg-unimarc-hooks'

export const OrderDetailLoader = (): React.ReactElement => {
  const paramsApp = getParamsInURL('source')
  const paddingLoader = paramsApp ? '20px 15px' : '50px 15px'
  return (
    <>
      <Container
        alignItems='center'
        backgroundColor={getGlobalStyle('--color-background-white')}
        isWrap
        justifyContent='center'
        tagName='main'
      >
        <BigScreen>
          <Row
            customWidth='90'
            isWrap
            maxWidth={getGlobalStyle('--width-max-desktop')}
          >
            <Column
              customWidth='24.11'
              isWrap
              position='sticky'
              tagName='aside'
            >
              <Container
                backgroundColor={getGlobalStyle('--color-background-white')}
                border={`${getGlobalStyle(
                  '--border-width-3xs'
                )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
                borderRadius={getGlobalStyle('--border-radius-md')}
                customHeight='270px'
                justifyContent='center'
                margin='12px 0'
                maxWidth='90%'
                padding='26px 20px'
              >
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-md')}
                  height={220}
                  width={100}
                />
              </Container>
            </Column>
            <Column
              customWidth='75.89'
              isWrap
              margin='12px 0'
              tagName='section'
            >
              <Container
                alignItems='center'
                backgroundColor={getGlobalStyle('--color-base-white')}
                border={`${getGlobalStyle(
                  '--border-width-3xs'
                )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
                borderRadius={getGlobalStyle('--border-radius-md')}
                customHeight='auto'
                isWrap
                justifyContent='center'
              >
                <Row
                  maxHeight='100px'
                  minHeight='100px'
                  padding='24px 32px'
                >
                  {/* InformationOrder */}
                  <Column margin='0px'>
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={50}
                      width={100}
                    />
                  </Column>
                  <Spacer.Horizontal size={1} />
                  <Column>
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={50}
                      width={100}
                    />
                  </Column>
                </Row>
                {/* button repeat purchase*/}
                <Row
                  maxHeight='100px'
                  minHeight='100px'
                  padding='24px 32px'
                >
                  <Column margin='0px 0px 0px 500px'>
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={50}
                      width={100}
                    />
                  </Column>
                </Row>
                <Row
                  isWrap
                  padding='24px 40px'
                >
                  {/* Tracking Order*/}
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={70}
                    width={100}
                  />
                  <Spacer.Horizontal size={48} />
                  <Column
                    backgroundColor={getGlobalStyle(
                      '--color-neutral-gray-light'
                    )}
                    borderRadius='4px'
                    isWrap
                    tagName='section'
                  >
                    {/* Shippping Type */}
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={100}
                      width={100}
                    />
                  </Column>
                </Row>
              </Container>
              <Spacer.Horizontal size={12} />
              <Container
                alignItems='center'
                backgroundColor={getGlobalStyle('--color-base-white')}
                border={`${getGlobalStyle(
                  '--border-width-3xs'
                )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
                borderRadius={getGlobalStyle('--border-radius-md')}
                customHeight='auto'
                isWrap
                justifyContent='center'
                padding='25px 32px' /* paddingContainer*/
                tagName='section'
              >
                <Row
                  justifyContent='center'
                  maxHeight='100%'
                  minHeight='100%'
                >
                  <Column
                    backgroundColor={getGlobalStyle('--color-base-white')}
                    clickable='pointer'
                    customHeight='auto'
                  >
                    {/* Productos */}
                    <Backbone
                      borderRadius={getGlobalStyle('--border-radius-md')}
                      height={100}
                      width={100}
                    />
                  </Column>
                </Row>
              </Container>
              <Spacer.Horizontal size={12} />
              <Container
                alignItems='center'
                backgroundColor={getGlobalStyle('--color-neutral-white')}
                border={`${getGlobalStyle(
                  '--border-width-3xs'
                )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
                borderRadius={getGlobalStyle('--border-radius-md')}
                customHeight='auto'
                isWrap
                justifyContent='center'
                padding='25px 32px'
              >
                <Row
                  justifyContent='center'
                  maxHeight='100%'
                  minHeight='100%'
                >
                  {/* <TotalizerResume  */}
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={200}
                    width={100}
                  />
                </Row>
              </Container>
            </Column>
          </Row>
        </BigScreen>
      </Container>

      <SmallScreen>
        <>
          <Container
            alignItems='center'
            isWrap
            justifyContent='center'
          >
            <Row
              isWrap
              padding={paddingLoader}
            >
              {!paramsApp && (
                <>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={50}
                    width={100}
                  />
                  {/* <TrackingOrder */}
                  <Spacer.Horizontal size={16} />
                </>
              )}

              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={330}
                width={100}
              />
              <Spacer.Horizontal size={16} />
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={70}
                width={100}
              />
            </Row>
          </Container>

          <Container>
            <Row padding='10px 15px'>
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={80}
                width={100}
              />
            </Row>
          </Container>

          <Container>
            <Row padding='10px 15px'>
              {/* <TotalizerResume */}
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={140}
                width={100}
              />
            </Row>
          </Container>
          {/* button repeat purchase*/}
          <Container
            absoluteDefault='bottomRight'
            alignItems='center'
            backgroundColor={getGlobalStyle('--color-base-white')}
            customHeight='71px'
            customWidth='100'
            justifyContent='center'
            position='fixed'
            zIndex={getGlobalStyle('--z-index-5')}
          >
            <Row padding='10px 15px'>
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={40}
                width={100}
              />
            </Row>
          </Container>
        </>
      </SmallScreen>
    </>
  )
}
