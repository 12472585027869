import {
  Container,
  Row,
  Column,
  Divider,
  Backbone
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ShelfLoader } from './ShelfLoader'
import { MembershipHeaderBackBone } from '@smu-chile/pkg-unimarc-components/stories/organisms/MembershipHeader/MembershipHeaderBackBone'

interface LoaderBigScreenProps {
  isFetching?: boolean
  orderQuantity?: number
}

export const LoaderBigScreen = (
  props: LoaderBigScreenProps
): React.ReactElement => {
  const { isFetching = false } = props
  const { orderQuantity = 4 } = props

  if (isFetching) {
    return <ShelfLoader orderQuantity={orderQuantity} />
  }

  return (
    <Container
      alignItems='baseline'
      backgroundColor={getGlobalStyle('--color-background-white')}
      isWrap
      justifyContent='center'
      tagName='main'
    >
      <Column
        margin='28px 0 0'
        width='calc(100vw - 60px)'
      >
        <MembershipHeaderBackBone />
      </Column>
      <Column
        customWidth='90'
        gap='20px'
        maxWidth={getGlobalStyle('--width-max-desktop')}
      >
        <Row gap='20px'>
          {/* Left */}
          <Column width='253px'>
            <Column width='74px'>
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={14}
                width={100}
              />
            </Column>
            <Divider
              color={getGlobalStyle('--color-neutral-gray-light')}
              thickness={1}
            />
            <Column width='172px'>
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={12}
                width={100}
              />
            </Column>
            <Column
              margin='15px 0 0'
              width='100%'
            >
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-full')}
                height={39}
                width={100}
              />
            </Column>
            <Divider
              color={getGlobalStyle('--color-neutral-gray-light')}
              thickness={1}
            />
            <Row gap='9px'>
              <Column width='20px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-full')}
                  height={20}
                  width={100}
                />
              </Column>
              <Column width='153px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-full')}
                  height={20}
                  width={100}
                />
              </Column>
            </Row>
            <Divider
              color={getGlobalStyle('--color-neutral-gray-light')}
              thickness={1}
            />
            <Row gap='9px'>
              <Column width='20px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-full')}
                  height={20}
                  width={100}
                />
              </Column>
              <Column width='153px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-full')}
                  height={20}
                  width={100}
                />
              </Column>
            </Row>
            <Column
              margin='82px 0 0'
              width='74px'
            >
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={14}
                width={100}
              />
            </Column>
            <Divider
              color={getGlobalStyle('--color-neutral-gray-light')}
              thickness={1}
            />
            <Column width='168px'>
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={12}
                width={100}
              />
            </Column>
            <Column
              margin='6px 0 0'
              width='150px'
            >
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={12}
                width={100}
              />
            </Column>
            <Row
              gap='9px'
              margin='30px 0 0'
            >
              <Column width='20px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-full')}
                  height={20}
                  width={100}
                />
              </Column>
              <Column width='153px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-full')}
                  height={20}
                  width={100}
                />
              </Column>
            </Row>
          </Column>
          {/* Right */}
          <Column
            maxWidth='970px'
            padding='0 24px'
          >
            <Row alignItems='center'>
              <Column
                gap='17px'
                height='58px'
                width='212px'
              >
                <Column width='147px'>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={14}
                    width={100}
                  />
                </Column>
                <Column>
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-md')}
                    height={21}
                    width={100}
                  />
                </Column>
              </Column>
              <Column
                margin='0 0 0 auto'
                width='280px'
              >
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-full')}
                  height={34}
                  width={100}
                />
              </Column>
            </Row>
            <Divider color={getGlobalStyle('--color-neutral-gray-light')} />
            <Column
              gap='12px'
              padding='67px 0 0'
            >
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={125}
                width={100}
              />
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={125}
                width={100}
              />
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={125}
                width={100}
              />
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-md')}
                height={125}
                width={100}
              />
            </Column>
          </Column>
        </Row>
      </Column>
    </Container>
  )
}
