import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { OrderInfoBigScreen } from './OrderInfoBigScreen'
import { OrderInfoSmallScreen } from './OrderInfoSmallScreen'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'

interface OrderInfoProps {
  order: Order
}

export const OrderInfo = ({ order }: OrderInfoProps): React.ReactElement => {
  return (
    <>
      <BigScreen>
        <OrderInfoBigScreen order={order} />
      </BigScreen>

      <SmallScreen>
        <OrderInfoSmallScreen order={order} />
      </SmallScreen>
    </>
  )
}
