import { Column, Container, Backbone } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const SideNavLoader = () => {
  return (
    <Column
      customWidth='100%'
      isWrap
      margin='5px 0 0 0'
      position='sticky'
      tagName='aside'
    >
      <Container
        backgroundColor={getGlobalStyle('--color-background-white')}
        border={`${getGlobalStyle('--border-width-3xs')} solid ${getGlobalStyle(
          '--color-neutral-gray-divider'
        )}`}
        borderRadius={getGlobalStyle('--border-radius-md')}
        customHeight='270px'
        justifyContent='center'
        margin='12px 0'
        maxWidth='90%'
        padding='26px 20px'
      >
        <Backbone
          borderRadius={getGlobalStyle('--border-radius-md')}
          height={220}
          width={100}
        />
      </Container>
    </Column>
  )
}
