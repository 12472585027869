import {
  Button,
  ButtonStatus,
  Column,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import router from 'next/router'

export interface ButtonProps {
  idfirstButtonPage?: string
  idsecondaryButtonPage?: string
  maxWidthMobile?: string
  prevIcon?: keyof typeof Icons
  prevIconClickable?: string
  prevIconColor?: string
  prevIconSize?: number
  statusFirstButton?: ButtonStatus
  statusSecondButton?: ButtonStatus
  textFirstButton?: string
  textSecondaryButton?: string
  onClickFirstButton?: () => void
  onClickSecondaryButton?: () => void
}

export const ButtonFirst = ({
  idfirstButtonPage = 'firstButtonPage',
  idsecondaryButtonPage = 'secondaryButtonPage',
  maxWidthMobile = '311px',
  prevIcon,
  prevIconClickable,
  prevIconColor,
  prevIconSize,
  statusFirstButton = 'initial',
  statusSecondButton = 'initial',
  textFirstButton,
  textSecondaryButton,
  onClickFirstButton,
  onClickSecondaryButton
}: ButtonProps) => {
  const { isMobile } = useMobile()

  const handleFirstButton = () => {
    if (onClickFirstButton) {
      onClickFirstButton()
    } else {
      router.reload()
    }
  }

  const handleSecondaryButton = () => {
    if (onClickSecondaryButton) {
      onClickSecondaryButton()
    }
  }
  return (
    <>
      <Column
        alignItems='center'
        alignSelf='center'
        justifyContent='center'
      >
        <Button
          borderRadius={getGlobalStyle('--border-radius-xl')}
          fontWeight='500'
          height='40px'
          id={getBemId(idfirstButtonPage, 'button')}
          isLoading={statusFirstButton === 'loading' ? true : false}
          label={textFirstButton}
          maxWidth={isMobile ? maxWidthMobile : '440px'}
          onClick={() => {
            handleFirstButton()
          }}
          prevIcon={prevIcon}
          prevIconClickable={prevIconClickable}
          prevIconColor={prevIconColor}
          prevIconSize={prevIconSize}
          size='lg'
          status={statusFirstButton}
          type='primary-gray'
          width='100%'
        />
        {textSecondaryButton && (
          <>
            <Spacer.Horizontal size={16} />
            <Column
              alignItems='center'
              alignSelf='center'
              justifyContent='center'
            >
              <Button
                borderRadius={getGlobalStyle('--border-radius-xl')}
                fontWeight={getGlobalStyle('--font-weight-medium')}
                height='40px'
                id={getBemId(idsecondaryButtonPage, 'button')}
                label={textSecondaryButton}
                maxWidth={isMobile ? maxWidthMobile : '440px'}
                onClick={() => {
                  handleSecondaryButton()
                }}
                size='medium'
                status={statusSecondButton || 'initial'}
                type='secondary'
                width='100%'
              />
            </Column>
          </>
        )}
      </Column>
    </>
  )
}
