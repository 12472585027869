import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { OrderIcon, OrderInfo } from '../components'
import { eventClick, getParamsInURL } from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { orderIconTheme } from 'shared/helpers'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'

interface ShelfListMobileProps {
  objetSrcImagesOrders?: {
    srcCancel: string
    srcStore: string
    srcMoto: string
  }
  order: Order
  sourceFlow?: string
}

export const ShelfListMobile = ({
  objetSrcImagesOrders,
  order,
  sourceFlow
}: ShelfListMobileProps): React.ReactElement => {
  const { orderId, status, totalPrepicking, total, addressType } = order || {}
  const isParamsApp = getParamsInURL('source')
  const arrayOrder = orderId.split('-')
  const ticketNumber = arrayOrder[0]
  const seller = arrayOrder[1]
  const customHeight = '86px'
  const srcImageOrder = orderIconTheme(
    order,
    objetSrcImagesOrders.srcCancel,
    objetSrcImagesOrders.srcMoto,
    objetSrcImagesOrders.srcStore
  )

  let sizeImgIcon = 36
  if (addressType === 'pickup') {
    sizeImgIcon = 40
  }

  return (
    <>
      <Container
        backgroundColor={getGlobalStyle('--color-base-white')}
        border={`${getGlobalStyle('--border-width-3xs')} solid ${getGlobalStyle(
          '--color-neutral-gray-divider'
        )}`}
        borderRadius={getGlobalStyle('--border-radius-md')}
        customHeight={customHeight}
        justifyContent='center'
        onClick={() => {
          eventClick({
            event: 'interacciones-mis-compras',
            eventCategory: 'ir-detalle-compra'
          })
          const sourceHelpCenter =
            sourceFlow === 'ClaimFlow' ? '&HelpCenter=true' : ''
          const sourceParamApp = isParamsApp ? '&source=App' : ''
          window.location.href = `/${sourceFlow}?og=${ticketNumber}&seller=${seller}${sourceHelpCenter}${sourceParamApp}`
        }}
        padding='8px 12px'
      >
        <Column
          alignItems='center'
          alignSelf='center'
          maxWidth='44px'
        >
          <OrderIcon
            customSizeIcon={sizeImgIcon}
            heighContainer='44px'
            srcImageOrder={srcImageOrder}
            widthContainer='44px'
          />
        </Column>

        <Column
          alignSelf='center'
          justifyContent='center'
          padding='0px 0px 0px 16px'
        >
          <OrderInfo order={order} />
        </Column>

        <Column customWidth='30'>
          <Row margin='0 0 9px'>
            <Column alignItems='end'>
              <Icon
                clickable='auto'
                color={getGlobalStyle('--color-neutral-gray-dark')}
                name='ArrowRight'
                sizes='xs'
              />
            </Column>
          </Row>
          <Row>
            <Column
              alignItems='end'
              justifyContent='end'
              margin='8px 0 0'
            >
              <Row>
                <Column alignItems='end'>
                  {status !== 'cancelling' &&
                    status !== 'canceled' &&
                    status !== 'incomplete' &&
                    total !== totalPrepicking && (
                      <Text
                        customColor={getGlobalStyle(
                          '--color-neutral-gray-dark'
                        )}
                        fontSize='md'
                        fontWeight='regular'
                        textAlign='left'
                        underline='line-through'
                      >
                        {totalPrepicking}
                      </Text>
                    )}
                  {status !== 'cancelling' &&
                    status !== 'canceled' &&
                    status !== 'incomplete' &&
                    total === totalPrepicking && (
                      <Spacer.Horizontal size={18} />
                    )}
                  {(status === 'cancelling' ||
                    status === 'canceled' ||
                    status === 'incomplete') && <Spacer.Horizontal size={8} />}
                </Column>
              </Row>
              <Row>
                <Column
                  alignItems='end'
                  justifyContent='end'
                >
                  <Text
                    color='black'
                    fontSize='lg'
                    fontWeight='medium'
                    textAlign='left'
                  >
                    {total}
                  </Text>
                </Column>
              </Row>
            </Column>
          </Row>
        </Column>
      </Container>
    </>
  )
}
