import {
  Row,
  Column,
  Backbone,
  Container
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ShelfListLoader } from 'components/OrdersList/Shelf'

interface ShelfLoaderProps {
  orderQuantity?: number
}

export const ShelfLoader = (props: ShelfLoaderProps): React.ReactElement => {
  const { orderQuantity = 4 } = props

  return (
    <Container isWrap>
      <Row
        justifyContent='between'
        padding='12px 0 25px 0'
      >
        <Container margin='10px'>
          <Column
            maxHeight='19px'
            maxWidth='250px'
          >
            <Backbone
              backgroundColor={getGlobalStyle('--color-loaders-background')}
              borderRadius='4px'
              foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
              height={19}
              speed={2}
              type='Generic'
              width={100}
            />
          </Column>
        </Container>
        <Column maxWidth='402px'>
          <Backbone
            backgroundColor={getGlobalStyle('--color-loaders-background')}
            borderRadius='4px'
            foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
            height={45}
            speed={2}
            type='Generic'
            width={100}
          />
        </Column>
      </Row>

      <Row>
        <Column>
          {[...Array(orderQuantity)].map((_item, key) => {
            return <ShelfListLoader key={key} />
          })}
        </Column>
      </Row>
      <Row
        justifyContent='center'
        margin='44px 0'
      >
        <Column
          maxHeight='45px'
          maxWidth='294px'
        >
          <Backbone
            backgroundColor={getGlobalStyle('--color-loaders-background')}
            borderRadius={getGlobalStyle('--border-radius-full')}
            foregroundColor={getGlobalStyle('--color-loaders-fore-ground')}
            height={35}
            speed={2}
            type='Generic'
            width={100}
          />
        </Column>
      </Row>
    </Container>
  )
}
