import { useState } from 'react'
import { Container, Row, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Buttons } from './Buttons'
import { CancelPurshaseLoader } from './CancelPurshaseLoader'
import { ContainerPages } from 'components/ContainerPages'
import { GoBack } from 'components'
import { PageConfirmate } from './PageConfirmate'
import { TittleCancel } from './TittleCancel'
import {
  Entry,
  getParamsInURL,
  orderEntriesById,
  useContentful,
  useOrder
} from '@smu-chile/pkg-unimarc-hooks'
import { CancelFlowListOptions } from './CancelFlowListOptions'
import { handleStatusTextOrderDetail } from 'shared/helpers'
import { ModalConfirmate } from './ModalConfirmate'
import { ModalError } from './ModalError'
import { HELP_CENTER } from 'shared/constants'

interface CancelPurshaseProps {
  imageModalConfirmCancelPurchase: string
  imageModalErrorCancelPurchase: string
}

export const CancelPurshase = ({
  imageModalConfirmCancelPurchase,
  imageModalErrorCancelPurchase
}: CancelPurshaseProps) => {
  const [selectOption, setSelectOption] = useState<Entry>({})
  const [selectOptionOther, setSelectOptionOther] = useState<string>('')
  const [openModalConfirmate, setOpenModalConfirmate] = useState(false)
  const [openModalError, setOpenModalError] = useState(false)

  const [openPage, setOpenPage] = useState(false)
  const paramsApp = getParamsInURL('source')
  const { data: dataOrder, isLoading: isLoadingDataOrder } = useOrder({
    oldOrders: true
  })
  const { text } = handleStatusTextOrderDetail(dataOrder?.data)
  const { data, isLoading } = useContentful({
    id_contentful: 'centroDeAyuda',
    options: {
      'sys.contentType.sys.id[in]': 'centroDeAyudaConfig',
      'sys.id[in]': HELP_CENTER
    }
  })
  if (isLoading) {
    return <CancelPurshaseLoader />
  }

  if (isLoading) {
    return <CancelPurshaseLoader />
  }

  const entriesSorted = orderEntriesById(data)

  if (openPage)
    return (
      <PageConfirmate
        sequence={dataOrder?.data?.sequence}
        status={text}
      />
    )

  return (
    <>
      {openModalConfirmate && (
        <ModalConfirmate
          imageModalConfirmCancelPurchase={imageModalConfirmCancelPurchase}
          openModalConfirmate={openModalConfirmate}
          selectOption={selectOption}
          selectOptionOther={selectOptionOther}
          setOpenModalConfirmate={setOpenModalConfirmate}
          setOpenModalError={setOpenModalError}
          setOpenPage={setOpenPage}
        />
      )}

      {openModalError && (
        <ModalError
          imageModalErrorCancelPurchase={imageModalErrorCancelPurchase}
          openModalError={openModalError}
          sequence={dataOrder?.data?.sequence}
          setOpenModalError={setOpenModalError}
          status={text}
        />
      )}
      <BigScreen>
        <ContainerPages>
          <Container
            alignItems='center'
            backgroundColor={getGlobalStyle('--color-base-white')}
            border={`${getGlobalStyle(
              '--border-width-3xs'
            )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
            borderRadius={getGlobalStyle('--border-radius-md')}
            customHeight='auto'
            isWrap
            justifyContent='center'
          >
            <Row
              isWrap
              padding='24px 32px'
            >
              <TittleCancel />
            </Row>
            <Row
              isWrap
              padding='24px 32px'
            >
              <CancelFlowListOptions
                elements={entriesSorted}
                isLoading={isLoadingDataOrder}
                selectOption={selectOption}
                sequence={dataOrder?.data?.sequence}
                setSelectOption={setSelectOption}
                setSelectOptionOther={setSelectOptionOther}
                status={text}
              />
            </Row>
          </Container>
          <Spacer.Horizontal size={16} />
          <Container
            alignItems='center'
            backgroundColor={getGlobalStyle('--color-base-white')}
            border={`${getGlobalStyle(
              '--border-width-3xs'
            )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
            borderRadius={getGlobalStyle('--border-radius-md')}
            customHeight='auto'
            isWrap
            justifyContent='center'
          >
            <Row padding='24px 32px'>
              <Buttons
                selectOption={selectOption}
                sequence={dataOrder?.data?.sequence}
                setOpenModalConfirmate={setOpenModalConfirmate}
                status={text}
              />
            </Row>
          </Container>
        </ContainerPages>
      </BigScreen>

      <SmallScreen>
        <ContainerPages>
          {!paramsApp && (
            <Row
              backgroundColor={getGlobalStyle('--color-base-white')}
              boxShadow={getGlobalStyle('--box-shadow-2xs')}
              id='container-go-back--my-account'
            >
              <GoBack title='Cancelar compra' />
            </Row>
          )}
          <Container
            alignItems='center'
            isWrap
            justifyContent='center'
            padding='12px 16px 12px'
          >
            <Row
              backgroundColor={getGlobalStyle('--color-base-white')}
              border={`0.3px solid ${getGlobalStyle(
                '--color-neutral-gray-divider'
              )}`}
              borderRadius={getGlobalStyle('--border-radius-md')}
              isWrap
              padding='12px 16px'
            >
              <TittleCancel />
            </Row>
            <Spacer.Horizontal size={12} />
            <Row
              backgroundColor={getGlobalStyle('--color-base-white')}
              border={`0.3px solid ${getGlobalStyle(
                '--color-neutral-gray-divider'
              )}`}
              borderRadius={getGlobalStyle('--border-radius-md')}
              isWrap
              padding='0px 16px'
            >
              <CancelFlowListOptions
                elements={entriesSorted}
                isLoading={isLoadingDataOrder}
                selectOption={selectOption}
                sequence={dataOrder?.data?.sequence}
                setSelectOption={setSelectOption}
                setSelectOptionOther={setSelectOptionOther}
                status={text}
              />
            </Row>
            <Spacer.Horizontal size={12} />
            {!paramsApp && (
              <>
                <Spacer.Horizontal size={64} />
                <Spacer.Horizontal size={12} />
              </>
            )}
            <Container
              absoluteDefault='bottomRight'
              backgroundColor={getGlobalStyle('--color-base-white')}
              padding='20px 16px'
              position='fixed'
            >
              <Buttons
                selectOption={selectOption}
                sequence={dataOrder?.data?.sequence}
                setOpenModalConfirmate={setOpenModalConfirmate}
                status={text}
              />
            </Container>
          </Container>
        </ContainerPages>
      </SmallScreen>
    </>
  )
}
