import {
  Button,
  Column,
  Container,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Image } from '@smu-chile/pkg-unimarc-components/stories/organisms/AddToListModal/Image'
import { trigger } from '@smu-chile/pkg-unimarc-hooks'

interface MembershipBlockProps {
  buttonLabel?: string
  labelColorBackground?: string
  labelColorText?: string
  labelIcon?: string
  labelText?: string
  title?: string
  subtitle?: string
}

export const MembershipBlock = ({
  buttonLabel,
  labelColorBackground,
  labelColorText,
  labelIcon,
  labelText,
  title,
  subtitle
}: MembershipBlockProps) => {
  const handleClick = () => {
    trigger({
      eventType: 'openMembershipModal',
      data: { eventContext: 'Mi cuenta' }
    })
  }
  return (
    <Column
      backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
      borderRadius={getGlobalStyle('--border-radius-md')}
      gap='12px'
      padding='10px 12px'
      width='259px'
    >
      <Row
        alignItems='center'
        backgroundColor={labelColorBackground}
        borderRadius={getGlobalStyle('--border-radius-full')}
        gap='5px'
        id={getBemId('membershipBlock', 'tag')}
        padding='3px 5px'
        width='auto'
      >
        <Container
          margin='-2px 0 0'
          width='12px'
        >
          <Image
            altPicture='Icono Socio'
            customSize='12px'
            srcPicture={labelIcon}
          />
        </Container>
        <Text
          customColor={labelColorText}
          fontSize='sm'
          fontWeight='medium'
        >
          {labelText}
        </Text>
      </Row>
      <Column
        gap='2px'
        id={getBemId('membershipBlock', 'text')}
        margin='0 0 4px'
      >
        <Text fontSize='md'>{title}</Text>
        <Text
          fontSize='sm'
          fontWeight='regular'
        >
          {subtitle}
        </Text>
      </Column>
      <Button
        borderRadius={getGlobalStyle('--border-radius-full')}
        fontSize={getGlobalStyle('--font-size-sm')}
        fontWeight={getGlobalStyle('--font-weight-medium')}
        height='32px'
        id={getBemId('membershipBlock', 'button')}
        label={buttonLabel}
        onClick={handleClick}
        type='secondary'
        width='100%'
      />
    </Column>
  )
}
