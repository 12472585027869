import { ReactElement } from 'react'

/**
 * return one page given a index
 * @param pages components to render
 * @param currentPage currentPage to show
 * @returns one page given a index
 */
export const useManagePages = (pages: ReactElement[], currentPage: number) => {
  return {
    page: pages[currentPage]
  }
}