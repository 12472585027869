import { Column, Spacer, Text } from '@smu-chile/pkg-unimarc-components'
import { formatDate } from '@smu-chile/pkg-unimarc-hooks'
import { shippingDate, statusCancelling } from 'shared/helpers'
import { OrderState } from '../OrderState'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'

interface OrderInfoBigScreenProps {
  order: Order
}

export const OrderInfoBigScreen = ({
  order
}: OrderInfoBigScreenProps): React.ReactElement => {
  const { creationDate, sequence, status } = order

  return (
    <Column
      alignItems='start'
      alignSelf='start'
      clickable='pointer'
      height='100%'
      justifyContent='start'
    >
      <Text
        clickable='pointer'
        color='gray'
        fontSize='md'
        fontWeight='regular'
        height='18px'
        lineHeight='15px'
        textAlign='left'
      >
        {`${formatDate(creationDate).replace(/-/gi, '/')}`}
      </Text>
      <Spacer.Horizontal size={8} />
      <Text
        clickable='pointer'
        color='black'
        fontSize='lg'
        fontWeight='medium'
        height='20px'
        lineHeight='17px'
        textAlign='left'
      >
        {`N° ${sequence}`}
      </Text>
      <Spacer.Horizontal size={8} />
      <OrderState
        order={order}
        textHeight='23px'
        textSize='xl'
      />
      <Spacer.Horizontal size={8} />
      {!statusCancelling.includes(status) && (
        <Text
          clickable='pointer'
          color='black'
          fontSize='lg'
          lineHeight='20px'
          textAlign='left'
        >
          {shippingDate(order)}
        </Text>
      )}
    </Column>
  )
}
