import { IEntriesProps, useContentful } from '@smu-chile/pkg-unimarc-hooks'
import { ID_SIDE_MENU_BUTTONS } from 'shared/constants'

interface IConnectionToCFSidenav {
  cfData?:IEntriesProps;
  CFError?: unknown;
  isLoadingCF?: boolean;
  isFetchedCF?: boolean;
}

const ConnectionToCFSidenav = (): IConnectionToCFSidenav => {
  const {
    data: cfData,
    error: CFError,
    isLoading: isLoadingCF,
    isFetched: isFetchedCF
  } = useContentful({
    id_contentful: `CentroDeAyuda-MenuLateral`,
    options: {
      'sys.contentType.sys.id[in]': 'viewBlock',
      'sys.id[in]': ID_SIDE_MENU_BUTTONS[0]
    },
    reactQuery: {
      enabled: true,
      retry: 3, staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false
    }
  })

  return {
    cfData,
    CFError,
    isFetchedCF,
    isLoadingCF
  }
}

export { ConnectionToCFSidenav }