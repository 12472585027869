import React, { useEffect, useState } from 'react'
import { Column, Divider, Row, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  IResponse,
  dataLayerUniqueTypeEvent
} from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ShelfList } from '../Shelf'
import { LoaderBigScreen } from '../PageOrdersLoaders'
import { EmptyOrders } from '../EmptyOrders'
import { v4 as uuid } from 'uuid'

import {
  addAttempt,
  getAttempts,
  initStorage,
  restartAttempt
} from 'shared/helpers'
import { Filters } from './Filters'
import { SearchInput } from '../SearchInput'
import { SearchResult } from '../SearchInput/SearchResult'
import { IOptionSelect } from 'components/SelectFilter'
import { OrdersHeader } from '../OrdersHeader'
import { ConnectionToCFSidenav } from 'shared/utils/connectionToCFSidenav'
import { ORDER_PER_PAGE_DESKTOP } from 'shared/constants'
import { MobilePagination } from '../OrdersSmallScreen/MobilePagination'
import { ContainerPages } from 'components/ContainerPages'
import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { IMembershipDataCF } from 'shared/interfaces/IMembership'
import { IGetOrdersResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'

export interface OrdersProps {
  changeOrder?: boolean
  currentPage: number
  data: IResponse<IGetOrdersResponse>
  flagEmptyOrders?: boolean
  flagEmptyOrdersIncomplete?: boolean
  inputListener: string
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  isLoggedIn: boolean
  membershipDataCF?: IMembershipDataCF
  numberOrderMinimun: boolean
  objetSrcImagesOrders?: {
    srcCancel: string
    srcStore: string
    srcMoto: string
  }
  ordersType: boolean
  sequence: string
  sourceFlow?: string
  titleBack?: string
  userData?: ResponseOrderFormProps
  setChangeOrder?: (order: boolean) => void
  setCurrentPage: (page: number) => void
  setInputListener: (type: string) => void
  setIsError: (type: boolean) => void
  setNumberOrderMinimun: (numberOrderMinimun: boolean) => void
  setOrder: (order: 0 | 1) => void
  setOrdersType: (type: boolean) => void
  setSequence: (type: string) => void
}

export const handleKeyNumberToSearch = (
  event: React.KeyboardEvent<HTMLInputElement>,
  setCurrentPage: (currentPage: number) => void,
  setNumberOrderMinimun: (numberOrderMinimun: boolean) => void,
  setSequence: (sequence: string) => void
): void => {
  const valueTarget = event.currentTarget.value
  event.currentTarget.value = valueTarget.replace(/[^\d]/g, '')

  if (event.key === 'Enter' && valueTarget.length >= 7) {
    setNumberOrderMinimun(false)
    setSequence(valueTarget)
    setCurrentPage(1)
  }
  if (
    event.key === 'Enter' &&
    valueTarget.length < 7 &&
    valueTarget.length > 1
  ) {
    setNumberOrderMinimun(true)
    setSequence('')
  }
}

export const handleSearchKeyOnClick = (
  inputListener: string,
  setCurrentPage: (currentPage: number) => void,
  setNumberOrderMinimun: (numberOrderMinimun: boolean) => void,
  setSequence: (sequence: string) => void
): void => {
  if (inputListener.length >= 7) {
    setNumberOrderMinimun(false)
    setSequence(inputListener)
    setCurrentPage(1)
  }
  if (inputListener.length < 7 && inputListener.length > 1) {
    setNumberOrderMinimun(true)
    setSequence('')
  }
}

export const handleChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setInputListener: (type: string) => void
) => {
  const valueTarget = event.target.value
  event.target.value = valueTarget.replace(/[^\d]/g, '')
  setInputListener(event.target.value)
}

export const handleClearInput = (
  inputListener: string,
  sequence: string,
  setInputListener: (type: string) => void,
  setIsError: (type: boolean) => void,
  setOrderList: (orderList: []) => void,
  setNumberOrderMinimun: (numberOrderMinimun: boolean) => void,
  setSequence: (sequence: string) => void
) => {
  if (inputListener.length < 7) {
    setInputListener('')
    setNumberOrderMinimun(false)
  }
  if (sequence.length >= 7) {
    setSequence('')
    setInputListener('')
    setIsError(false)
  }
  const input = document.getElementById(
    'input__search-orders'
  ) as HTMLTextAreaElement | null
  input.value = ''
  if (window.innerWidth > 1280 && sequence.length > 0) {
    setOrderList([])
  }
}

export const OrdersBigScreen = ({
  currentPage,
  data,
  flagEmptyOrders,
  flagEmptyOrdersIncomplete,
  inputListener,
  isError,
  isFetching,
  isLoading,
  isLoggedIn,
  membershipDataCF,
  numberOrderMinimun,
  objetSrcImagesOrders,
  ordersType,
  sequence,
  sourceFlow,
  userData,
  setChangeOrder,
  setCurrentPage,
  setInputListener,
  setIsError,
  setNumberOrderMinimun,
  setOrder,
  setOrdersType,
  setSequence
}: OrdersProps): React.ReactElement => {
  const [totalPage, setTotalPage] = useState(1)
  const [orderList, setOrderList] = useState([])
  const [loadingOrders, setLoadingOrders] = useState(true)

  const { isLoadingCF } = ConnectionToCFSidenav()

  const orderByOptions = [
    { label: 'Más recientes primero', value: 0, placeholder: 'Más recientes' },
    { label: 'Más antiguas primero', value: 1, placeholder: 'Más antiguas' }
  ]
  const [orderBy, setOrderBy] = useState<IOptionSelect>(orderByOptions[0])

  const handleChangeOrder = (sort: 0 | 1) => {
    setChangeOrder(true)
    setOrder(sort)
  }

  const changeOrderType = () => {
    setLoadingOrders(true)
    setOrdersType(!ordersType)
    setCurrentPage(1)
    setOrderList([])
  }

  useEffect(() => {
    if (data) {
      try {
        const {
          data: { orders, total }
        } = data
        if (sequence.length > 0 && currentPage > 1) {
          setCurrentPage(1)
        }
        setOrderList([...orders])
        let pages =
          total % ORDER_PER_PAGE_DESKTOP === 0
            ? Math.floor(total / ORDER_PER_PAGE_DESKTOP)
            : Math.floor(total / ORDER_PER_PAGE_DESKTOP) + 1
        if (pages > 15) {
          pages = 15
        }
        setTotalPage(pages)
        restartAttempt()
      } catch (error) {
        initStorage()
        setIsError(true)
        if (Number(getAttempts()) === 3) {
          restartAttempt()
        } else {
          addAttempt()
        }
      }
      setLoadingOrders(false)
    }
  }, [data])

  useEffect(() => {
    dataLayerUniqueTypeEvent({ page: 'mis-compras' })
  }, [])

  if ((isLoading && currentPage === 1) || !isLoggedIn || isLoadingCF) {
    return <LoaderBigScreen />
  }

  if (
    ((ordersType && flagEmptyOrders) ||
      (!ordersType && flagEmptyOrdersIncomplete) ||
      (sequence.length < 7 && numberOrderMinimun) ||
      isError ||
      !data) &&
    !isLoading &&
    !loadingOrders
  ) {
    return (
      <EmptyOrders
        changeOrderType={changeOrderType}
        inputListener={inputListener}
        isFetching={isFetching}
        membershipDataCF={membershipDataCF}
        numberOrderMinimun={numberOrderMinimun}
        ordersType={ordersType}
        sequence={sequence}
        setCurrentPage={setCurrentPage}
        setInputListener={setInputListener}
        setIsError={setIsError}
        setNumberOrderMinimun={setNumberOrderMinimun}
        setOrderList={setOrderList}
        setSequence={setSequence}
        userData={userData}
      />
    )
  }
  return (
    <ContainerPages
      membershipDataCF={membershipDataCF}
      userData={userData}
    >
      <Column
        backgroundColor={getGlobalStyle('--color-background-white')}
        border={`${getGlobalStyle('--border-width-3xs')} solid ${getGlobalStyle(
          '--color-neutral-gray-divider'
        )}`}
        borderRadius={getGlobalStyle('--border-radius-md')}
        isWrap
        padding='24px 32px 0'
        tagName='section'
      >
        <OrdersHeader
          changeOrderType={changeOrderType}
          numberOrderMinimun={numberOrderMinimun}
          ordersType={ordersType}
          sourceFlow={sourceFlow}
        />
        <Spacer.Horizontal size={16} />
        {sourceFlow === 'OrderDetails' && (
          <Divider
            thickness={0}
            verticalMargin={0}
            width={100}
          />
        )}

        {isFetching && <LoaderBigScreen isFetching={isFetching} />}

        {!isFetching && (
          <>
            <Spacer.Horizontal size={16} />
            <Row
              customWidth='100'
              justifyContent='between'
            >
              {!!ordersType && sourceFlow === 'OrderDetails' && (
                <>
                  <Column
                    customWidth='55.8'
                    margin='7px 0px 0px 0px '
                  >
                    <Filters
                      onChangeOrder={handleChangeOrder}
                      orderBy={orderBy}
                      orderByOptions={orderByOptions}
                      setOrderBy={setOrderBy}
                    />
                  </Column>
                  <Column
                    customWidth='280px'
                    width='280px'
                  >
                    <SearchInput
                      inputListener={inputListener}
                      onchange={(event) => {
                        handleChange(event, setInputListener)
                      }}
                      onclear={() => {
                        handleClearInput(
                          inputListener,
                          sequence,
                          setInputListener,
                          setIsError,
                          setOrderList,
                          setNumberOrderMinimun,
                          setSequence
                        )
                      }}
                      onclick={() => {
                        handleSearchKeyOnClick(
                          inputListener,
                          setCurrentPage,
                          setNumberOrderMinimun,
                          setSequence
                        )
                      }}
                      onkeydown={(event) => {
                        handleKeyNumberToSearch(
                          event,
                          setCurrentPage,
                          setNumberOrderMinimun,
                          setSequence
                        )
                      }}
                      sequence={sequence}
                    />
                  </Column>
                </>
              )}
            </Row>
            <Row clickable='pointer'>
              <Column>
                {sequence && !isFetching && orderList?.length === 1 && (
                  <SearchResult />
                )}
                {orderList.map((currentOrder) => {
                  return (
                    <ShelfList
                      key={uuid()}
                      objetSrcImagesOrders={objetSrcImagesOrders}
                      order={currentOrder}
                      sourceFlow={sourceFlow}
                    />
                  )
                })}
              </Column>
            </Row>
            {(totalPage > currentPage || currentPage > 1) &&
            orderList.length > 0 ? (
              <MobilePagination
                onChangePage={(pageNumber) => {
                  return setCurrentPage(pageNumber)
                }}
                pages={totalPage}
                selectedPage={currentPage}
              />
            ) : (
              <Spacer.Horizontal size={24} />
            )}
          </>
        )}
      </Column>
    </ContainerPages>
  )
}
