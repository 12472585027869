import React, { useState, useEffect } from 'react'
import {
  Column,
  Container,
  Input,
  RadioSelector,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  dataLayerUniqueTypeEvent,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { Entry } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IContentful'
import { ListOptionsProps } from 'shared/interfaces/IListOptions'

export const CancelFlowListOptions = ({
  elements,
  fontWeight = 'regular',
  isLoading,
  selectOption,
  setSelectOption,
  setSelectOptionOther,
  sequence,
  status
}: ListOptionsProps): React.ReactElement => {
  const { isMobile } = useMobile()
  const [countCharacters, setCountCharacters] = useState<number>(0)

  const marginRowOption = isMobile ? '5px 10px' : ''
  const fontSizeOption = isMobile ? 'md' : 'lg'
  const customMinHeight = isMobile ? '50px' : '54px'
  const customWidthTitle = isMobile ? '88' : '100'
  useEffect(() => {
    if (isLoading) return
    dataLayerUniqueTypeEvent({
      estado_compra: status,
      id_compra: sequence,
      page: 'mis-compras-cancelar-compra'
    })
  }, [isLoading])

  const onValueChange = (element: Entry) => {
    setCountCharacters(0)
    setSelectOption(element)
  }

  const onChangeCharactersQuantity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCountCharacters(event.target.value.length)
    setSelectOptionOther(event.target.value)
  }

  return (
    <>
      {Array.isArray(elements) &&
        elements.length > 0 &&
        elements.map((element: Entry, index: number) => {
          return (
            <React.Fragment key={`key_${element?.fields?.id}`}>
              {index > 0 && (
                <Spacer.Horizontal
                  backgroundColor={getGlobalStyle('--color-neutral-gray')}
                  size={1}
                />
              )}
              <Container
                absoluteDefault='topRight'
                alignItems='center'
                clickable='pointer'
                margin={marginRowOption}
                minHeight={customMinHeight}
                onClick={() => {
                  onValueChange(element)
                }}
                tagName='article'
              >
                <Column
                  clickable='pointer'
                  customWidth={customWidthTitle}
                >
                  <Text
                    fontSize={fontSizeOption}
                    fontWeight={fontWeight}
                  >
                    {element?.fields?.title}
                  </Text>
                </Column>
                <Column
                  clickable='pointer'
                  width={40}
                >
                  <RadioSelector
                    checked={
                      selectOption?.fields?.title === element?.fields?.title
                    }
                    type='gray'
                    value={element?.fields?.title}
                  />
                </Column>
              </Container>
              {element?.fields?.title === 'Otro' &&
                selectOption?.fields?.value === element?.fields?.value && (
                  <>
                    <Container
                      backgroundColor={getGlobalStyle(
                        '--color-neutral-gray-white'
                      )}
                      borderRadius={getGlobalStyle('--border-radius-xs')}
                      customHeight='62px'
                      padding='12px'
                    >
                      <Input
                        autoFocus
                        background={getGlobalStyle(
                          '--color-neutral-gray-white'
                        )}
                        fontSize='md'
                        maxLength={150}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          return onChangeCharactersQuantity(event)
                        }}
                        placeholder='Escribe tu comentario'
                        textAlign='left'
                        width='100%'
                      />
                    </Container>
                    <Container alignItems='end'>
                      <Row alignItems='end'>
                        <Column
                          alignItems='end'
                          customWidth='100'
                        >
                          <Text
                            color='gray'
                            fontSize='sm'
                          >
                            {' '}
                            {countCharacters} /150 caracteres
                          </Text>
                        </Column>
                      </Row>
                    </Container>
                    <Spacer.Horizontal size={8} />
                    <Spacer.Horizontal size={2} />
                  </>
                )}
            </React.Fragment>
          )
        })}
    </>
  )
}
