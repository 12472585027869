import { Text } from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

export const TextFirst = () => {
  const { isMobile } = useMobile()
  const fontSize = isMobile ? 'xl' : '3xl'
  return (
    <Text
      fontSize={fontSize}
      fontWeight='semibold'
      textAlign='center'
    >
      Cancelación en proceso
    </Text>
  )
}
