import { Picture } from '@smu-chile/pkg-unimarc-components'

interface ImagePageProps {
  imgSrc: string
}
export const ImagePage = ({ imgSrc }: ImagePageProps) => {
  return (
    <Picture
      height='160'
      src={imgSrc}
      width='auto'
    />
  )
}
