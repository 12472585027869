import React from 'react'
import {
  Container,
  Horizontal,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { convertToPascalCase } from 'shared/helpers/convertToPascalCase'

export interface NameProps {
  brandName: string
  hideQuantity?: boolean
  measurementUnit: string
  name: string
  quantity: number
  unitMultiplier: number
  weight?: number
}

export const validateDecimal = (value: number) => {
  const regex = /^\d{1,2}(\.\d{1,2})?$/
  if (regex.test(value.toString())) {
    return false
  }
  return true
}

export const conditionalDecimal = (
  existWeight: boolean,
  quantity: number,
  unitMultiplier: number,
  weight: number
) => {
  let unitFinal: string | number
  if (existWeight) {
    unitFinal = weight
  }
  if (!existWeight) {
    unitFinal = validateDecimal(quantity * unitMultiplier)
      ? (quantity * unitMultiplier).toFixed(2).replace(/\.0+$/, '')
      : quantity * unitMultiplier
  }
  return unitFinal
}

export const Name = ({
  brandName = '',
  hideQuantity = false,
  measurementUnit = '',
  name,
  quantity,
  unitMultiplier,
  weight
}: NameProps): React.ReactElement<NameProps> => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth <= 375
  const existWeight = weight ? true : false
  const unitFinal: string | number = conditionalDecimal(
    existWeight,
    quantity,
    unitMultiplier,
    weight
  )
  const brandNameLowerCase = brandName ? brandName.toLowerCase() : brandName
  const brandNameModify = brandNameLowerCase
    ? brandNameLowerCase[0].toUpperCase() + brandNameLowerCase.substring(1)
    : brandNameLowerCase
  const newBrandName = brandNameModify == null ? 'Sin marca' : brandNameModify
  const newName =
    name == null
      ? 'Producto sin nombre y descripción'
      : convertToPascalCase(name)
  const truncateName = isMobile ? 27 : 32

  return (
    <>
      <BigScreen>
        <Container isWrap>
          <Horizontal size={2} />
          <Row>
            <Text
              color='gray'
              fontSize='sm'
              fontWeight='medium'
              truncate={70}
            >
              {newBrandName}
            </Text>
          </Row>
          <Row minWidth='254px'>
            <Text
              color='black'
              fontSize='md'
              truncate={70}
            >
              {newName}
            </Text>
          </Row>
          <Horizontal size={12} />
          <Row>
            {!hideQuantity && (
              <Text fontSize='sm'>
                Cantidad: {unitFinal} {measurementUnit}
              </Text>
            )}
          </Row>
        </Container>
      </BigScreen>

      <SmallScreen>
        <>
          <Row>
            <Text
              color='gray'
              fontSize='sm'
              fontWeight='medium'
              truncate={30}
            >
              {newBrandName}
            </Text>
          </Row>
          <Row width='100%'>
            <Text
              color='black'
              fontSize='md'
              truncate={truncateName}
            >
              {newName}
            </Text>
          </Row>
        </>
      </SmallScreen>
    </>
  )
}
