import {
  Column,
  Container,
  Row,
  Spacer,
  Text,
  ToggleSwitch
} from '@smu-chile/pkg-unimarc-components'
import styles from '../Orders.module.css'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'

interface OrdersHeaderProps {
  numberOrderMinimun: boolean
  ordersType: boolean
  sequence?: string
  sourceFlow?: string
  changeOrderType: () => void
}

export const OrdersHeader = ({
  numberOrderMinimun,
  ordersType,
  sequence,
  sourceFlow,
  changeOrderType
}: OrdersHeaderProps): React.ReactElement => {
  return (
    <Row>
      <Column>
        <Text
          fontSize='2xl'
          fontWeight='semibold'
          id={getBemId('orders-header', 'title')}
        >
          Mis compras
        </Text>
        <Spacer.Horizontal size={8} />
        <Text
          color='gray'
          fontSize='lg'
          id={getBemId('orders-header', 'subtitle')}
        >
          {sourceFlow === 'ClaimFlow'
            ? 'Escoge una de tus compras para atender tu solicitud.'
            : 'Revisa o repite tus compras.'}
        </Text>
      </Column>
      {!sequence && !numberOrderMinimun && (
        <Column
          alignItems='end'
          justifyContent='end'
        >
          <Spacer.Horizontal size={12} />
          <Container
            alignItems='end'
            id={getBemId('orders-header', 'switch')}
            justifyContent='end'
          >
            <ToggleSwitch
              customClass={styles['switch--toggle']}
              customClassInput={styles['switch--toggle']}
              customClassLeft={styles['switch--toggle-text']}
              customClassRight={styles['switch--toggle-text']}
              leftLabel='Realizadas'
              onClick={() => {
                return changeOrderType()
              }}
              rightLabel='Rechazadas'
              value={ordersType}
            />
          </Container>
        </Column>
      )}
    </Row>
  )
}
