import {
  Column,
  Container,
  ModalBottomSheet,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { useRouter } from 'next/router'

interface ModalRepeatPurchaseProps {
  isMobile: boolean
  openModalRepeatPurchase: boolean
  repatPurchaseImage: string
  setOpenModalRepeatPurchase: React.Dispatch<React.SetStateAction<boolean>>
}
export const ModalRepeatPurchase = ({
  isMobile,
  openModalRepeatPurchase,
  repatPurchaseImage,
  setOpenModalRepeatPurchase
}: ModalRepeatPurchaseProps) => {
  const router = useRouter()
  const customMaxWidth = isMobile ? '265px' : '340px'
  const customImageSize = isMobile ? '198px' : '164px'

  const handleGoToCart = () => {
    setOpenModalRepeatPurchase(false)
    router.push(process.env.NEXT_PUBLIC_CARTURL)
  }

  const handleKeepShopping = () => {
    setOpenModalRepeatPurchase(false)
    router.push(process.env.NEXT_PUBLIC_HOMEURL)
  }

  return (
    <>
      <ModalBottomSheet
        isMobile={isMobile}
        isOpen={openModalRepeatPurchase}
        onClose={() => {
          return setOpenModalRepeatPurchase(false)
        }}
        primaryBtnLabel='Ir al carro'
        primaryCTA={handleGoToCart}
        secondaryBtnLabel='Seguir comprando'
        secondaryCTA={handleKeepShopping}
      >
        <>
          <Column
            alignItems='center'
            margin='36px 0 0 0'
            width='250px'
          >
            <Picture
              alt='banana'
              height='auto'
              src={repatPurchaseImage}
              width={customImageSize}
            />
            <Container
              justifyContent='center'
              margin='27px 0'
            >
              <Text
                fontSize='xl'
                fontWeight='medium'
                textAlign='center'
              >
                Tu carro está listo
              </Text>
            </Container>
          </Column>
          <Row maxWidth={customMaxWidth}>
            <Text
              fontWeight='regular'
              isFlex
              textAlign='center'
            >
              Tus productos ya fueron agregados. ¡Sólo te queda un paso para que
              sean tuyos!
            </Text>
          </Row>
        </>
      </ModalBottomSheet>
    </>
  )
}
