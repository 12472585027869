import { Column, Row, Text } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'
import { handleStatusTextOrdersList } from 'shared/helpers'

interface OrderStateProps {
  order: Order
  textHeight?: string
  textSize?: 'md' | 'lg' | 'xl'
}

export const OrderState = ({
  order,
  textHeight,
  textSize = 'lg'
}: OrderStateProps): React.ReactElement => {
  const { customColor, text } = handleStatusTextOrdersList(order)
  const { isMobile } = useMobile()

  return (
    <Row clickable='pointer'>
      <Column clickable='pointer'>
        <Text
          clickable='pointer'
          customColor={getGlobalStyle(customColor)}
          fontSize={textSize}
          fontWeight={isMobile ? 'medium' : 'regular'}
          height={textHeight}
          lineHeight={isMobile ? '15px' : '19px'}
          textAlign='left'
        >
          {text}
        </Text>
      </Column>
    </Row>
  )
}
