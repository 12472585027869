import React, { Fragment } from 'react'
import { useRouter } from 'next/router'
import {
  Chip,
  Column,
  Container,
  Divider,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  eventClick,
  getParamsInURL,
  IEntriesProps,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { ICTAs } from 'shared/interfaces/ICTAs'
import { ResponseDocument } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IDocument'
import { getSettingsPurchaseCTAs } from './helpers/getSettingsCTAs'
import { getSettingsGlobal } from './helpers/getSettingsGlobal'
import { MENU_ORDER_DETAIL_BUTTONS } from 'shared/constants'
import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'
import style from '../../OrderDetail.module.css'
import { TTokens } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ITokens'

interface PurchaseCTAsProps {
  addWebviewParamsInUrl?: string
  cfData: IEntriesProps
  documentData?: ResponseDocument
  enabledCancelPurshase: boolean
  enabledDownloadReceipt: boolean
  enabledIconLoader: boolean
  hideOptionByStatus?: boolean
  isLoadingDocumentData?: boolean
  tagInfo: {
    orderState: string
    sequence: string
  }
  ticketNumber: string
  setOpenModalCreateList?: React.Dispatch<React.SetStateAction<boolean>>
  setOpenFeedbackReceipt?: React.Dispatch<React.SetStateAction<boolean>>
  setOpenWindowFeedback?: React.Dispatch<React.SetStateAction<boolean>>
}

export const PurchaseCTAs = ({
  addWebviewParamsInUrl,
  cfData,
  documentData,
  enabledCancelPurshase,
  enabledDownloadReceipt,
  enabledIconLoader,
  hideOptionByStatus = false,
  isLoadingDocumentData,
  tagInfo,
  ticketNumber,
  setOpenModalCreateList,
  setOpenFeedbackReceipt,
  setOpenWindowFeedback
}: PurchaseCTAsProps) => {
  const router = useRouter()
  const { isMobile } = useMobile()
  const paramsApp = getParamsInURL('source')
  const seller = getParamsInURL('seller')
  const url = `https://unimarc2212.acepta.com/ca4webv3/PdfViewMedia?url=${documentData?.data?.document}`
  const iconStatusPostPicking = enabledDownloadReceipt
    ? 'ArrowRightNavigate2'
    : 'Loading'
  const confirmedIcon = enabledIconLoader
    ? 'ArrowRightNavigate2'
    : iconStatusPostPicking
  const mobileFontWeight = isMobile ? 'regular' : 'medium'
  const button1 = cfData?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_ORDER_DETAIL_BUTTONS[0].toString()
  })
  const button2 = cfData?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_ORDER_DETAIL_BUTTONS[1].toString()
  })
  const button3 = cfData?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_ORDER_DETAIL_BUTTONS[2].toString()
  })
  const button4 = cfData?.includes?.Entry?.find((data) => {
    return data?.sys?.id === MENU_ORDER_DETAIL_BUTTONS[3].toString()
  })
  const {
    arrowSize,
    paddingContainer,
    paddingDivider,
    rowHeight,
    widthColum,
    widthColumLoading
  } = getSettingsGlobal(isMobile)

  const { sequence, orderState } = tagInfo

  const handleDownloadReceipt = (documentData: ResponseDocument) => {
    if (!isLoadingDocumentData) {
      eventClick({
        event: 'interacciones_mis_compras',
        eventAction: 'clic',
        eventCategory: 'detalle-de-compra',
        estado_compra: orderState,
        event_label: 'Descargar boleta',
        id_compra: sequence
      })
      if (!isMobile && !documentData.error) {
        window.open(url)
      }
      if (isMobile && !documentData.error) {
        paramsApp ? router.replace(url) : window.open(url)
      }
      if (
        documentData.error ||
        documentData.data.document == 'Reintente Por favor'
      ) {
        router.push(
          `/FailedReceipt?og=${ticketNumber}&seller=${seller}${addWebviewParamsInUrl}`
        )
      }
    }
  }
  const handleOpenMyListsModal = () => {
    if (paramsApp) {
      router.push(
        `/OrderDetails?og=${ticketNumber}&seller=${seller}&source=APP&CreateList`
      )
    } else {
      setOpenModalCreateList(true)
    }
    eventClick({
      event: 'interacciones_mis_listas',
      eventAction: 'clic',
      eventCategory: 'inicio_mis_listas',
      event_label: 'crear_nueva_lista_mis_compras'
    })
  }

  const handleCancelPurchase = () => {
    eventClick({
      event: 'interacciones_mis_compras',
      eventAction: 'clic',
      eventCategory: 'detalle-de-compra',
      estado_compra: orderState,
      event_label: 'cancelar-compra',
      id_compra: sequence
    })
    return router.push(
      `/CancelsPurchase?og=${ticketNumber}&seller=${seller}${addWebviewParamsInUrl}`
    )
  }

  const spliceButtons = (buttons: ICTAs[]) => {
    // hide the first two options when the status is 'incomplete'
    if (hideOptionByStatus) {
      buttons.splice(0, 2)
    }
  }

  const getSettingsButtons = () => {
    const {
      colorFirstIcon: colorFirstIconDownload,
      colorText: colorTextDownload
    } = getSettingsPurchaseCTAs(enabledDownloadReceipt)

    const { colorFirstIcon: colorFirstIconCancel, colorText: colorTextCancel } =
      getSettingsPurchaseCTAs(enabledCancelPurshase)

    const buttons: ICTAs[] = [
      {
        enableCTA: true,
        iconText: button1?.fields?.iconText as keyof typeof Icons,
        styles: {
          alignmentIcon:
            enabledDownloadReceipt || enabledIconLoader
              ? widthColum
              : widthColumLoading,
          clasName:
            enabledIconLoader || enabledDownloadReceipt ? null : style.loading,
          clickPointer: 'pointer',
          colorArrowIcon:
            !enabledDownloadReceipt && enabledIconLoader
              ? '--color-neutral-gray-silver'
              : '--color-neutral-gray-dark',
          colorFirstIcon: colorFirstIconDownload,
          colorText: colorTextDownload,
          nameIcon: confirmedIcon,
          sizeIcon:
            enabledDownloadReceipt || enabledIconLoader ? arrowSize : 22.8
        },
        labelColorText: button1?.fields?.labelColorText,
        labelColorBackground: button1?.fields?.labelColorBackground,
        labelText: button1?.fields?.labelText,
        showButtom: button1?.fields?.showbuttom,
        showLabel: button1?.fields?.showLabel,
        subtitle: button1?.fields?.subtitulo,
        title: button1?.fields?.label,
        url: () => {
          enabledDownloadReceipt
            ? handleDownloadReceipt(documentData)
            : setOpenFeedbackReceipt(true)
        }
      },
      {
        enableCTA: true,
        iconText: button2?.fields?.iconText as keyof typeof Icons,
        styles: {
          alignmentIcon: widthColum,
          clickPointer: 'pointer',
          colorArrowIcon: enabledCancelPurshase
            ? '--color-neutral-gray-dark'
            : '--color-neutral-gray-silver',
          colorFirstIcon: colorFirstIconCancel,
          colorText: colorTextCancel,
          nameIcon: 'ArrowRightNavigate2',
          sizeIcon: arrowSize
        },
        labelColorText: button2?.fields?.labelColorText,
        labelColorBackground: button2?.fields?.labelColorBackground,
        labelText: button2?.fields?.labelText,
        showButtom: button2?.fields?.showbuttom,
        showLabel: button2?.fields?.showLabel,
        subtitle: button2?.fields?.subtitulo,
        title: button2?.fields?.label,
        url: () => {
          enabledCancelPurshase
            ? handleCancelPurchase()
            : setOpenWindowFeedback(true)
        }
      },
      {
        enableCTA: true,
        iconText: button3?.fields?.iconText as keyof typeof Icons,
        styles: {
          alignmentIcon: widthColum,
          clickPointer: 'pointer',
          colorArrowIcon: '--color-neutral-gray-dark',
          colorFirstIcon: '--color-text-black',
          nameIcon: 'ArrowRightNavigate2',
          sizeIcon: arrowSize
        },
        labelColorText: button3?.fields?.labelColorText,
        labelColorBackground: button3?.fields?.labelColorBackground,
        labelText: button3?.fields?.labelText,
        showButtom: button3?.fields?.showbuttom,
        showLabel: button3?.fields?.showLabel,
        subtitle: button3?.fields?.subtitulo,
        title: button3?.fields?.label,
        url: () => {
          handleOpenMyListsModal()
        }
      },
      {
        enableCTA: true,
        iconText: button4?.fields?.iconText as keyof typeof Icons,
        styles: {
          alignmentIcon: widthColum,
          clickPointer: 'pointer',
          colorArrowIcon: '--color-neutral-gray-dark',
          colorFirstIcon: '--color-text-black',
          colorText: 'black',
          nameIcon: 'ArrowRightNavigate2',
          sizeIcon: arrowSize
        },
        labelColorText: button4?.fields?.labelColorText,
        labelColorBackground: button4?.fields?.labelColorBackground,
        labelText: button4?.fields?.labelText,
        showButtom: button4?.fields?.showbuttom,
        showLabel: button4?.fields?.showLabel,
        subtitle: button4?.fields?.subtitulo,
        title: button4?.fields?.label,
        url: () => {
          return router.push(
            `/ClaimFlow?og=${ticketNumber}&seller=${seller}${addWebviewParamsInUrl}`
          )
        }
      }
    ]

    spliceButtons(buttons)

    return buttons
  }

  const CTAs: ICTAs[] = getSettingsButtons()

  return (
    <Column>
      {CTAs.map(
        (
          {
            enableCTA,
            iconText,
            labelColorText,
            labelColorBackground,
            labelText,
            showButtom,
            showLabel,
            styles,
            subtitle,
            title,
            url
          },
          index
        ) => {
          if (showButtom) {
            return (
              <Fragment key={title}>
                <>
                  {index !== 0 && (
                    <Container padding={paddingDivider}>
                      <Divider
                        color={getGlobalStyle('--color-neutral-gray')}
                        thickness={0}
                        verticalMargin={0}
                      />
                    </Container>
                  )}
                  <Container
                    alignItems='center'
                    clickable={styles.clickPointer}
                    maxHeight={rowHeight}
                    minHeight={rowHeight}
                    padding={paddingContainer}
                  >
                    <Row
                      alignItems='center'
                      clickable={styles.clickPointer}
                      onClick={
                        enableCTA
                          ? url
                          : () => {
                              return null
                            }
                      }
                    >
                      <Column customWidth='5'>
                        <Icon
                          clickable={styles.clickPointer}
                          color={getGlobalStyle(
                            styles.colorFirstIcon as TTokens
                          )}
                          name={iconText}
                          sizes='sm'
                          strokeWidth='1'
                        />
                      </Column>
                      <SmallScreen>
                        <Spacer.Vertical size={24} />
                      </SmallScreen>
                      <Column clickable={styles.clickPointer}>
                        <SmallScreen>
                          <Spacer.Horizontal size={1} />
                        </SmallScreen>
                        <Row clickable={styles.clickPointer}>
                          <Text
                            clickable={styles.clickPointer}
                            color={styles.colorText}
                            fontWeight={mobileFontWeight}
                          >
                            {title}
                          </Text>
                          {showLabel && (
                            <>
                              <Spacer.Vertical size={12} />
                              <Column customWidth='10'>
                                <Spacer.Horizontal size={2} />
                                <Chip
                                  backgroundColor={labelColorBackground}
                                  borderRadius='42px'
                                  color={labelColorText}
                                  fontSize={9}
                                  height='20px'
                                  label={labelText}
                                />
                              </Column>
                            </>
                          )}
                        </Row>
                        <BigScreen>
                          <>
                            <Spacer.Horizontal size={2} />
                            <Row clickable={styles.clickPointer}>
                              <Text
                                clickable={styles.clickPointer}
                                color={styles.colorText}
                                fontSize='md'
                                fontWeight='regular'
                              >
                                {subtitle}
                              </Text>
                            </Row>
                          </>
                        </BigScreen>
                      </Column>
                      <Column
                        clickable={styles.clickPointer}
                        customClassName={styles.clasName}
                        customWidth={styles.alignmentIcon}
                      >
                        <Icon
                          clickable={styles.clickPointer}
                          color={getGlobalStyle(
                            styles.colorArrowIcon as TTokens
                          )}
                          customSize={styles.sizeIcon}
                          name={styles.nameIcon}
                        />
                      </Column>
                    </Row>
                  </Container>
                </>
              </Fragment>
            )
          }
          return null
        }
      )}
    </Column>
  )
}
