import {
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import React from 'react'

interface DeliveryDateProps {
  deliveryDate: string
}
export const DeliveryDate = ({ deliveryDate }: DeliveryDateProps) => {
  const { isMobile } = useMobile()
  const paddingMobile = isMobile ? '0' : '18px 10px'
  return (
    <Container
      isWrap
      padding={paddingMobile}
    >
      <Row alignItems={isMobile ? 'center' : 'start'}>
        <Icon
          color={getGlobalStyle('--color-base-black')}
          customSize={24}
          name='Clock2'
        />
        <Spacer.Vertical size={8} />
        <Text
          fontSize='md'
          fontWeight='medium'
        >
          Fecha y horario:
        </Text>
      </Row>
      <Spacer.Horizontal size={4} />
      <Row padding='0 0 0 32px'>
        <Text fontSize='md'>{deliveryDate}</Text>
      </Row>
    </Container>
  )
}
