import React from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { CardPayment } from './CardPayment'
import { WebPayPayment } from './WebPayPayment'
import { IOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { GiftCardPayment } from './GiftCardPayment'
import { KlapPayment } from './KlapPayment'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import {
  BANK_STATE_PAYMENT,
  KLAP_PAY_NAMES,
  PAYMENT_UNIPAY,
  PAYMENT_CARD_TYPE,
  PAYMENT_GIFT_CARD_NAME,
  PAYMENT_WEB_PAY_NAMES
} from 'shared/helpers/paymentSystems'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { BankStatePayment } from './BankStatePayment'
interface OrderDetailProps {
  bankStateImage: string
  order?: IOrder
}

export const PaymentMethods = ({
  bankStateImage,
  order
}: OrderDetailProps): React.ReactElement => {
  const { isMobile } = useMobile()
  const paddingMobile = isMobile ? '0' : '18px 15px'

  const giftCardCaption =
    order?.paymentData?.transactions[0]?.payments[0]?.giftCardCaption ?? ''
  const paymentSystemName =
    order?.paymentData?.transactions[0]?.payments[0]?.paymentSystemName ?? ''
  const paymentSystem =
    order?.paymentData?.transactions[0]?.payments[0]?.paymentSystem ?? ''
  const cardNumber = order?.inscription?.cardNumber ?? ''
  const cardType = order?.inscription?.cardType ?? ''
  const cardBrand = order?.inscription?.cardBrand ?? ''
  const paymentName = order?.inscription?.paymentName ?? ''
  const provider = order?.inscription?.provider ?? ''
  const unipayData = order?.customData?.customApps?.find((item) => {
    return item.id === 'unipaydata'
  })
  const validatePayment = PAYMENT_UNIPAY.includes(paymentSystem)
  const transformCard = (cardType || cardBrand).toLowerCase()

  const getPaymentMethod = () => {
    // BANCO ESTADO
    if (BANK_STATE_PAYMENT.includes(provider)) {
      return (
        <BankStatePayment
          bankStateImage={bankStateImage}
          cardNumber={cardNumber}
        />
      )
    }
    // DEBIT AND CREDIT CARDS
    if (
      ((cardType || cardBrand) && PAYMENT_CARD_TYPE.includes(transformCard)) ||
      PAYMENT_UNIPAY.includes(paymentSystem)
    ) {
      return (
        <CardPayment
          cardNumber={cardNumber || ''}
          cardType={validatePayment ? paymentSystemName : transformCard}
          isMobile={isMobile}
          quotaValue={unipayData?.fields?.quotaValue}
          quotas={unipayData?.fields?.quotas}
          rate={unipayData?.fields?.rate}
          totalCreditCost={unipayData?.fields?.totalCreditCost}
        />
      )
    }
    // WEBPAY PAYMENTS
    if (
      PAYMENT_WEB_PAY_NAMES.includes(paymentName) ||
      PAYMENT_WEB_PAY_NAMES.includes(paymentSystemName)
    ) {
      return <WebPayPayment paymentName={paymentName || paymentSystemName} />
    }
    // GIFT CARD
    if (
      PAYMENT_GIFT_CARD_NAME.includes(paymentName) ||
      PAYMENT_GIFT_CARD_NAME.includes(paymentSystemName)
    ) {
      return <GiftCardPayment number={giftCardCaption} />
    }
    // KLAP PAYMENT
    if (KLAP_PAY_NAMES.includes(paymentSystemName)) {
      return <KlapPayment paymentName={paymentSystemName} />
    }

    return null
  }

  return (
    <>
      <Container
        isWrap
        padding={paddingMobile}
      >
        <Row alignItems={isMobile ? 'center' : 'start'}>
          <Icon
            color={getGlobalStyle('--color-base-black')}
            customSize={24}
            name='Card'
          />
          <Spacer.Vertical size={8} />
          <Text
            fontSize='md'
            fontWeight='medium'
          >
            Medio de pago:
          </Text>
        </Row>
        <Column
          alignItems='center'
          padding='0 0 0 32px'
        >
          <Spacer.Horizontal size={4} />
          {getPaymentMethod()}
        </Column>
      </Container>
    </>
  )
}
