import { useEffect, useState } from 'react'
import {
  Column,
  Container,
  Row,
  Spacer,
  ToggleSwitch
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { EmptyOrders } from '../EmptyOrders'
import { GoBack } from 'components'
import {
  addAttempt,
  getAttempts,
  initStorage,
  restartAttempt
} from 'shared/helpers'
import { MobilePagination } from './MobilePagination'
import { ShelfListContainer } from './ShelfListContainer'
import { FiltersMobile } from './Filters'
import { LoaderSmallScreen } from '../PageOrdersLoaders'
import {
  handleChange,
  handleClearInput,
  handleKeyNumberToSearch,
  handleSearchKeyOnClick,
  OrdersProps
} from '../OrdersBigScreen'
import {
  dataLayerUniqueTypeEvent,
  getParamsInURL,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { SearchInput } from '../SearchInput'
import { IOptionSelect } from 'components/SelectFilter'
import { SearchResult } from '../SearchInput/SearchResult'
import { ORDER_PER_PAGE_MOBILE } from 'shared/constants'
import styles from '../Orders.module.css'

export const OrdersSmallScreen = ({
  currentPage,
  data,
  flagEmptyOrders,
  flagEmptyOrdersIncomplete,
  inputListener,
  isError,
  isFetching,
  isLoading,
  isLoggedIn,
  numberOrderMinimun,
  objetSrcImagesOrders,
  ordersType,
  sequence,
  sourceFlow,
  titleBack,
  setCurrentPage,
  setInputListener,
  setIsError,
  setNumberOrderMinimun,
  setOrder,
  setOrdersType,
  setSequence
}: OrdersProps): React.ReactElement => {
  const { innerWidth } = useMobile()
  const isParamsApp = getParamsInURL('source')
  const isMobile = innerWidth <= 360
  const [totalPage, setTotalPage] = useState(1)
  const [orderList, setOrderList] = useState([])
  const [loadingOrders, setLoadingOrders] = useState(true)

  const orderByOptions = [
    { label: 'Más recientes', value: 0, placeholder: 'Más recientes' },
    { label: 'Más antiguas', value: 1, placeholder: 'Más antiguas' }
  ]
  const [orderBy, setOrderBy] = useState<IOptionSelect>(orderByOptions[0])

  const fontSize = isMobile ? 'sm' : 'md'

  useEffect(() => {
    if (data) {
      try {
        const {
          data: { orders, total }
        } = data
        if (sequence.length > 0 && currentPage > 1) {
          setCurrentPage(1)
        }
        let pages =
          total % ORDER_PER_PAGE_MOBILE === 0
            ? Math.floor(total / ORDER_PER_PAGE_MOBILE)
            : Math.floor(total / ORDER_PER_PAGE_MOBILE) + 1
        if (pages > 15) {
          pages = 15
        }
        setTotalPage(pages)
        setOrderList([...orders])
        restartAttempt()
      } catch (error) {
        initStorage()
        setIsError(true)
        if (Number(getAttempts()) === 3) {
          restartAttempt()
        } else {
          addAttempt()
        }
      }
      setLoadingOrders(false)
    }
  }, [data])

  const changeOrderType = () => {
    setLoadingOrders(true)
    setOrdersType(!ordersType)
    setCurrentPage(1)
    setOrderList([])
  }

  useEffect(() => {
    dataLayerUniqueTypeEvent({ page: 'mis-compras' })
  }, [])

  if (isLoading || !isLoggedIn) {
    return <LoaderSmallScreen />
  }

  if (
    ((ordersType && flagEmptyOrders) ||
      (!ordersType && flagEmptyOrdersIncomplete) ||
      (sequence.length < 7 && numberOrderMinimun) ||
      isError ||
      !data ||
      numberOrderMinimun) &&
    !loadingOrders
  ) {
    return (
      <EmptyOrders
        changeOrderType={() => {
          return changeOrderType()
        }}
        inputListener={inputListener}
        isFetching={isFetching}
        numberOrderMinimun={numberOrderMinimun}
        ordersType={ordersType}
        sequence={sequence}
        setCurrentPage={setCurrentPage}
        setInputListener={setInputListener}
        setIsError={setIsError}
        setNumberOrderMinimun={setNumberOrderMinimun}
        setOrderList={setOrderList}
        setSequence={setSequence}
      />
    )
  }

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-background-white')}
      isWrap
      justifyContent='center'
    >
      <Column maxWidth={getGlobalStyle('--width-max-mobile')}>
        <Row backgroundColor={getGlobalStyle('--color-base-white')}>
          <Column alignItems='center'>
            {!isParamsApp && <GoBack title={titleBack} />}
            <Spacer.Horizontal size={24} />
            <ToggleSwitch
              customClass={styles['switch--toggle']}
              customClassInput={styles['switch--toggle']}
              customClassLeft={styles['switch--toggle-text']}
              customClassRight={styles['switch--toggle-text']}
              leftLabel='Realizadas'
              onClick={() => {
                return changeOrderType()
              }}
              rightLabel='Rechazadas'
              textSize={fontSize}
              value={ordersType}
            />
            <Spacer.Horizontal size={16} />
            <Spacer.Horizontal size={2} />
          </Column>
        </Row>

        <Spacer.Horizontal size={12} />

        <Column padding='0 16px'>
          {!!ordersType &&
            !isLoading &&
            !isFetching &&
            sourceFlow === 'OrderDetails' && (
              <Row
                backgroundColor={getGlobalStyle('--color-base-white')}
                border={`${getGlobalStyle(
                  '--border-width-3xs'
                )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
                borderRadius={getGlobalStyle('--border-radius-md')}
                isWrap
              >
                <Column padding='8px 12px 0 12px'>
                  <SearchInput
                    inputListener={inputListener}
                    onchange={(event) => {
                      handleChange(event, setInputListener)
                    }}
                    onclear={() => {
                      handleClearInput(
                        inputListener,
                        sequence,
                        setInputListener,
                        setIsError,
                        setOrderList,
                        setNumberOrderMinimun,
                        setSequence
                      )
                    }}
                    onclick={() => {
                      handleSearchKeyOnClick(
                        inputListener,
                        setCurrentPage,
                        setNumberOrderMinimun,
                        setSequence
                      )
                    }}
                    onkeydown={(event) => {
                      handleKeyNumberToSearch(
                        event,
                        setCurrentPage,
                        setNumberOrderMinimun,
                        setSequence
                      )
                    }}
                    sequence={sequence}
                  />
                </Column>
                <Spacer.Horizontal size={16} />
                <Row justifyContent='center'>
                  <FiltersMobile
                    onChangeOrder={setOrder}
                    orderBy={orderBy}
                    orderByOptions={orderByOptions}
                    setOrderBy={setOrderBy}
                  />
                </Row>
              </Row>
            )}
          <Spacer.Horizontal size={4} />
          {sequence && !isFetching && orderList?.length === 1 && (
            <>
              <Spacer.Horizontal size={8} />
              <SearchResult />
              <Spacer.Horizontal size={8} />
            </>
          )}
          <ShelfListContainer
            loading={isLoading || isFetching}
            objetSrcImagesOrders={objetSrcImagesOrders}
            orderList={orderList}
            sourceFlow={sourceFlow}
          />
        </Column>

        {(totalPage > currentPage || currentPage > 1) &&
        orderList.length > 0 ? (
          <MobilePagination
            onChangePage={(pageNumber) => {
              return setCurrentPage(pageNumber)
            }}
            pages={totalPage}
            selectedPage={currentPage}
          />
        ) : (
          <Row margin='6px 0 0'>
            <Spacer.Horizontal
              backgroundColor={getGlobalStyle('--color-background-white')}
              size={4}
            />
          </Row>
        )}
      </Column>
    </Container>
  )
}
