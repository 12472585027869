import { Column, Spacer, Text } from '@smu-chile/pkg-unimarc-components'
import { formatDate, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { shippingDate, statusCancelling } from 'shared/helpers'
import { OrderState } from '../OrderState'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'

interface OrderInfoSmallScreenProps {
  order: Order
}

export const OrderInfoSmallScreen = ({
  order
}: OrderInfoSmallScreenProps): React.ReactElement => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth <= 360
  const fontSize = isMobile ? 'sm' : 'md'

  const { creationDate, sequence, status } = order

  return (
    <Column
      alignItems='start'
      alignSelf='start'
      clickable='pointer'
      height='100%'
      justifyContent='start'
    >
      <Text
        color='gray'
        fontSize='sm'
        lineHeight='11px'
        textAlign='left'
      >
        {`${formatDate(creationDate).replace(/-/gi, '/')}`}
      </Text>
      <Spacer.Horizontal size={4} />
      <Text
        color='black'
        fontSize='md'
        fontWeight='medium'
        lineHeight='15px'
        textAlign='left'
      >
        {`N° ${sequence}`}
      </Text>
      <Spacer.Horizontal size={4} />
      <OrderState
        order={order}
        textSize='md'
      />
      <Spacer.Horizontal size={4} />
      {!statusCancelling.includes(status) && (
        <Text
          color='black'
          fontSize={fontSize}
          lineHeight='15px'
          textAlign='left'
        >
          {shippingDate(order)}
        </Text>
      )}
    </Column>
  )
}
