import {
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ButtonPage } from './ButtonPage'
import { ContainerPages } from 'components/ContainerPages'
import { GoBack } from 'components'
import { ImagePage } from './ImagePage'
import { TextFirst } from './TextFirst'
import { TextSecond } from './TextSecond'
import { TextThird } from './TextThird'
import {
  dataLayerUniqueTypeEvent,
  getParamsInURL,
  useContentful
} from '@smu-chile/pkg-unimarc-hooks'
import { useEffect } from 'react'
import { CLAIMFLOW_SUCCESS_LETTUCE_IMG } from 'shared/constants'
import { PageConfirmateLoader } from './PageConfirmateLoader'
import { ButtonPurchase } from './ButtonPurchase'
import styles from '../../Components.module.css'

interface PageConfirmateProps {
  sequence: string
  status: string
}

export const PageConfirmate = ({
  sequence,
  status
}: PageConfirmateProps): React.ReactElement => {
  const lettuceImg = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-lettuce-img',
    options: {
      'sys.id': CLAIMFLOW_SUCCESS_LETTUCE_IMG[0]
    },
    type: 'assets'
  })

  useEffect(() => {
    dataLayerUniqueTypeEvent({
      estado_compra: status,
      id_compra: sequence,
      page: 'mis-compras-cancelación-en-proceso'
    })
  }, [])

  if (lettuceImg.isLoading) {
    return <PageConfirmateLoader />
  }

  const imgSrc = lettuceImg.data?.['items'][0]?.fields?.file?.url || ''

  const isParamsApp = getParamsInURL('source')
  const tktNumber = getParamsInURL('og')
  const seller = getParamsInURL('seller')

  const webviewHeight = isParamsApp ? '95vh' : ''

  return (
    <>
      <BigScreen>
        <ContainerPages>
          <Container
            alignItems='center'
            backgroundColor={getGlobalStyle('--color-base-white')}
            border={`${getGlobalStyle(
              '--border-width-3xs'
            )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
            borderRadius={getGlobalStyle('--border-radius-md')}
            customHeight='auto'
            isWrap
            justifyContent='center'
          >
            <Row
              isWrap
              padding='24px 32px'
            >
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <ImagePage imgSrc={imgSrc} />
              </Column>
            </Row>
            <Row isWrap>
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <TextFirst />
              </Column>
            </Row>
            <Row
              isWrap
              padding='24px 32px'
            >
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <TextSecond />
              </Column>
            </Row>
            <Row
              isWrap
              padding='24px 170px'
            >
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <TextThird />
              </Column>
            </Row>
            <Row
              isWrap
              padding='32px'
            >
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <ButtonPurchase
                  seller={seller}
                  tktNumber={tktNumber}
                />
                <Spacer.Horizontal size={16} />
                <ButtonPage isParamsApp={isParamsApp} />
              </Column>
            </Row>
          </Container>
        </ContainerPages>
      </BigScreen>

      <SmallScreen>
        <ContainerPages>
          {!isParamsApp && (
            <Row
              backgroundColor={getGlobalStyle('--color-base-white')}
              boxShadow={getGlobalStyle('--box-shadow-2xs')}
              id='container-go-back--my-account'
            >
              <GoBack title='Cancelación en proceso' />
            </Row>
          )}
          <Column
            alignItems='center'
            isWrap
            justifyContent='center'
            padding='12px 16px 12px'
          >
            <Container
              backgroundColor={getGlobalStyle('--color-base-white')}
              border={`${getGlobalStyle(
                '--border-width-3xs'
              )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
              borderRadius={getGlobalStyle('--border-radius-md')}
              className={styles.containerMinHeightWithBack}
              isWrap
              justifyContent='between'
              minHeight={webviewHeight}
              padding='28px 12px'
              tagName='section'
            >
              <Column
                alignItems='center'
                justifyContent='center'
              >
                <ImagePage imgSrc={imgSrc} />
                <Spacer.Horizontal size={24} />
                <TextFirst />
                <Spacer.Horizontal size={16} />
                <Spacer.Horizontal size={1} />
                <TextSecond />
                <Spacer.Horizontal size={24} />
                <TextThird />
                <Spacer.Horizontal size={64} />
              </Column>
              <Column alignSelf='end'>
                <ButtonPurchase
                  seller={seller}
                  tktNumber={tktNumber}
                />
                <Spacer.Horizontal size={16} />
                <ButtonPage isParamsApp={isParamsApp} />
              </Column>
            </Container>
          </Column>
        </ContainerPages>
      </SmallScreen>
    </>
  )
}
