import { Text } from '@smu-chile/pkg-unimarc-components'

export const TextThird = () => {
  return (
    <Text
      fontWeight='medium'
      textAlign='center'
    >
      Se gestionará la devolución de tu dinero al método de pago que utilizaste
    </Text>
  )
}
