import { Color } from '@smu-chile/pkg-unimarc-components'

interface SettingsPurchaseProps {
  colorFirstIcon?: string
  colorText?: Color
}

export const getSettingsPurchaseCTAs = (
  enabledStyles?: boolean
): SettingsPurchaseProps => {
  if (!enabledStyles) {
    return {
      colorFirstIcon: '--color-neutral-gray-silver',
      colorText: 'inactive'
    }
  }

  return {
    colorFirstIcon: '--color-text-black',
    colorText: 'black'
  }
}
