import {
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { SmallScreen } from '@smu-chile/pkg-unimarc-components/helpers'
import { PAYMENT_WEB_PAY_NAMES } from 'shared/helpers/paymentSystems'
import { TextPostPicking } from '../TextPostPicking'

export const WebPayPayment = ({
  paymentName
}: {
  paymentName: string
}): React.ReactElement => {
  let type = ''

  const getIcon = (paymentName: string) => {
    switch (paymentName) {
      // WEBPAY
      case PAYMENT_WEB_PAY_NAMES[0]:
        return (
          <Icon
            name='WebPayPlus'
            sizes='lg'
          />
        )
      // WEBPAY ONE CLICK CREDITO
      case PAYMENT_WEB_PAY_NAMES[1]:
        type = 'Crédito'
        return (
          <Icon
            name='WebPayOneClickColor'
            sizes='lg'
          />
        )
      // WEBPAY ONE CLICK DEBITO
      case PAYMENT_WEB_PAY_NAMES[2]:
        type = 'Débito'
        return (
          <Icon
            name='WebPayOneClickColor'
            sizes='lg'
          />
        )
      default:
        return null
    }
  }

  return (
    <Container
      isWrap
      padding='10px 0px'
    >
      <Row alignItems='center'>
        <Container>
          <Row alignItems='center'>
            {getIcon(paymentName)}
            <Spacer.Vertical size={8} />
            <Text fontWeight='medium'>{type}</Text>
          </Row>
        </Container>
      </Row>

      <SmallScreen>
        {paymentName === PAYMENT_WEB_PAY_NAMES[0] && (
          <>
            <Spacer.Horizontal size={16} />
            <Row>
              <TextPostPicking />
            </Row>
          </>
        )}
      </SmallScreen>
    </Container>
  )
}
