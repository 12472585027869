import {
  Column,
  Horizontal,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

import { currencyFormat } from '@smu-chile/pkg-unimarc-hooks/shared/helpers'
import { Totalizer } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { totalizerIdName } from '..'

interface ResumeItemProps {
  booleanIcon: boolean
  itemName: string
  itemValue: number | string
  latest?: boolean
  totalizers?: Totalizer[]
}

const fontWeight = (value: string) => {
  return value === 'Total' ? 'semibold' : 'regular'
}

export const ResumeItem = ({
  booleanIcon,
  itemName,
  itemValue,
  latest,
  totalizers
}: ResumeItemProps): React.ReactElement => {
  const { isMobile } = useMobile()
  const fontText = isMobile ? 'md' : 'lg'
  const horizontalSpace = latest ? 12 : 8
  const minWidth = isMobile ? '190px' : '0'
  return (
    <>
      <Horizontal size={horizontalSpace} />
      <Row>
        <Column>
          <Row alignItems='center'>
            <Column
              minWidth={minWidth}
              width='auto'
            >
              <Text
                fontSize={fontText}
                fontWeight={fontWeight(itemName)}
              >
                {itemName}
              </Text>
            </Column>
            {booleanIcon && (
              <>
                <BigScreen>
                  <Spacer.Vertical size={4} />
                </BigScreen>

                <Column
                  justifyContent='center'
                  maxWidth='15px'
                >
                  <Icon
                    color={getGlobalStyle('--color-base-black')}
                    name='Warning'
                    sizes='xs'
                  />
                </Column>
              </>
            )}
          </Row>
        </Column>

        <Column alignItems='end'>
          {itemName !== totalizerIdName(totalizers, 'Shipping') && (
            <Text
              fontSize={fontText}
              fontWeight={fontWeight(itemName)}
            >
              {currencyFormat({ number: itemValue, bool: false })}
            </Text>
          )}
          {itemName === totalizerIdName(totalizers, 'Shipping') && (
            <Text
              fontSize={fontText}
              fontWeight={fontWeight(itemName)}
            >
              {itemValue}
            </Text>
          )}
        </Column>
      </Row>
    </>
  )
}
