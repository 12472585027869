import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ShelfListLoader } from '../../Shelf'

export const LoaderSmallScreen = (): React.ReactElement => {
  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-background-white')}
      isWrap
      justifyContent='center'
    >
      <Row
        backgroundColor={getGlobalStyle('--color-base-white')}
        border={`${getGlobalStyle('--border-width-3xs')} solid ${getGlobalStyle(
          '--color-neutral-gray-divider'
        )}`}
        borderRadius={getGlobalStyle('--border-radius-md')}
        isWrap
        margin='4px 0'
        padding='16px 20px'
      >
        <Backbone
          borderRadius={getGlobalStyle('--border-radius-md')}
          height={75}
          width={100}
        />
      </Row>
      <Row
        backgroundColor={getGlobalStyle('--color-base-white')}
        border={`${getGlobalStyle('--border-width-3xs')} solid ${getGlobalStyle(
          '--color-neutral-gray-divider'
        )}`}
        borderRadius={getGlobalStyle('--border-radius-md')}
        isWrap
        margin='4px 0'
        padding='16px 20px'
      >
        <Backbone
          borderRadius={getGlobalStyle('--border-radius-md')}
          height={75}
          width={100}
        />
        <Spacer.Horizontal size={12} />
        <Backbone
          borderRadius={getGlobalStyle('--border-radius-md')}
          height={50}
          width={100}
        />
        <Spacer.Horizontal size={16} />
        <Column>
          <ShelfListLoader />
          <ShelfListLoader />
          <ShelfListLoader />
          <ShelfListLoader />
          <ShelfListLoader />
        </Column>
      </Row>
    </Container>
  )
}
