import { Container, Icon, Picture } from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useState } from 'react'
import style from '../../../../OrderDetail.module.css'

interface ImageCardProps {
  altText?: string
  customIconSize?: number
  hasURL?: boolean
  imageSize?: string
  imageUrl: string
}

export const ImageCard = ({
  altText,
  customIconSize,
  hasURL = false,
  imageSize,
  imageUrl
}: ImageCardProps) => {
  const { isMobile } = useMobile()
  const sizeImage = imageSize || (isMobile ? '73px' : '84px')
  const finalIconSize = customIconSize || (isMobile ? 73 : 84)
  const [isErrorImg, setIsErrorImg] = useState(false)
  const description = altText || 'picture'

  return (
    <Container
      alignItems='center'
      margin='0px 0px'
    >
      {isErrorImg || !imageUrl ? (
        <Icon
          customSize={finalIconSize}
          name='ShelvesDefaultImage'
        />
      ) : (
        <Picture
          alt={description}
          border='none'
          borderRadius='none'
          customClassName={style.picture}
          draggable={false}
          hasURL={hasURL}
          height={sizeImage}
          onError={() => {
            setIsErrorImg(true)
          }}
          src={imageUrl}
          width={sizeImage}
        />
      )}
    </Container>
  )
}
