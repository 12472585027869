import { InputIcon } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import classNames from 'classnames'
import { FeedbackSeeker } from './Feedback'
import orderStyles from '../Orders.module.css'

export interface SearchInputProps {
  inputListener: string
  sequence: string
  onchange?: (e?: React.ChangeEvent<HTMLInputElement>) => void
  onclear: () => void
  onclick: () => void
  onkeydown: (e?: React.KeyboardEvent<HTMLInputElement>) => void
}

export const SearchInput = ({
  inputListener,
  onchange,
  onclear,
  onclick,
  onkeydown,
  sequence
}: SearchInputProps): React.ReactElement => {
  return (
    <>
      <InputIcon
        border={`1px solid ${getGlobalStyle('--color-neutral-gray-silver')}`}
        borderRadius={getGlobalStyle('--border-radius-full')}
        colorIcon={getGlobalStyle('--color-base-black')}
        customSizeIcon={16}
        fontSize='md'
        height='35px'
        iconWidth='16'
        id='input__search-orders'
        inputCustomClass={classNames(
          orderStyles.changeOrder,
          'input-search-sequence'
        )}
        onChangeSearch={onchange}
        onClearInput={onclear}
        onClickSearch={onclick}
        onFocusSearch={onchange}
        onKeyPressSearch={onkeydown}
        padding='7px 16px 7px 0'
        placeholder='Buscar por Nº de compra'
        value={inputListener}
        width='280px'
      />
      {((inputListener.length >= 1 && inputListener.length <= 6 && !sequence) ||
        (sequence &&
          inputListener.length <= 6 &&
          inputListener.length >= 1)) && <FeedbackSeeker />}
    </>
  )
}
