import {
  Backbone,
  Column,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

const defaultLoaderProps = {
  backgroundColor: getGlobalStyle('--color-loaders-background'),
  foregroundColor: getGlobalStyle('--color-loaders-fore-ground'),
  speed: 2,
  width: 100
}

export const ShelfListLoaderBig = (): React.ReactElement => {
  return (
    <Row maxWidth='100%'>
      <Column maxWidth='60px'>
        <Container
          alignItems='center'
          backgroundColor={getGlobalStyle('--color-neutral-gray-light')}
          borderRadius='100%'
          justifyContent='center'
          maxHeight='60px'
          maxWidth='60px'
          minHeight='60px'
        >
          <Backbone
            borderRadius='100%'
            height={50}
            type='Generic'
            {...defaultLoaderProps}
          />
        </Container>
      </Column>

      <Column padding='0px 0px 0px 16px'>
        <Row>
          <Column maxWidth='184px'>
            <Backbone
              borderRadius='8px'
              height={38}
              type='Generic'
              {...defaultLoaderProps}
            />
          </Column>
        </Row>
        <Spacer.Horizontal size={12} />
        <Row>
          <Column maxWidth='229px'>
            <Backbone
              borderRadius='8px'
              height={47}
              type='Generic'
              {...defaultLoaderProps}
            />
          </Column>
        </Row>
      </Column>

      <Column
        maxWidth='323px'
        minHeight='100%'
      >
        <Row justifyContent='end'>
          <Column
            margin='0'
            maxHeight='27px'
            maxWidth='86px'
          >
            <Backbone
              borderRadius='8px'
              height={35}
              type='Generic'
              {...defaultLoaderProps}
            />
          </Column>
        </Row>
        <Spacer.Horizontal size={32} />
        <Row
          alignItems='end'
          justifyContent='end'
        >
          <Column
            margin='0 12px 0 0'
            maxWidth='155px'
          >
            <Backbone
              borderRadius='8px'
              height={35}
              type='Generic'
              {...defaultLoaderProps}
            />
          </Column>
          <Column maxWidth='155px'>
            <Backbone
              borderRadius='8px'
              height={35}
              type='Generic'
              {...defaultLoaderProps}
            />
          </Column>
        </Row>
      </Column>
    </Row>
  )
}
