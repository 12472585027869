import {
  Column,
  Container,
  Divider,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import React from 'react'
import styles from '../../OrderDetail.module.css'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { IWebTracking } from 'shared/interfaces/IShipping'
import { TTokens } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ITokens'

export const TrackingOrder = ({
  alignItemColumn,
  customColorText,
  icons,
  status,
  text
}: IWebTracking): React.ReactElement => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const customWidth = isMobile ? '100' : '105'
  const containerIconSize = isMobile ? '36px' : '48px'
  const sizeIcon = isMobile ? 20 : 25

  return (
    <Container isWrap>
      <SmallScreen>
        <>
          <Row padding='0 16px'>
            <Text
              customColor={getGlobalStyle(customColorText)}
              fontWeight='medium'
            >
              {text}
            </Text>
          </Row>
          <Spacer.Horizontal size={16} />
        </>
      </SmallScreen>

      <Row>
        <Column
          isWrap
          position='relative'
        >
          <Row
            className={styles['container-divider']}
            customWidth='83.5'
            position='absolute'
          >
            <Divider
              color={getGlobalStyle('--color-neutral-gray-dark')}
              verticalMargin={0}
            />
          </Row>

          {(status === 'cancelling' ||
            status === 'canceled' ||
            status === 'incomplete') && (
            <Row
              className={styles['container-line']}
              customWidth='83.5'
              position='absolute'
            >
              <Divider
                color={getGlobalStyle('--color-neutral-gray-silver')}
                verticalMargin={0}
              />
            </Row>
          )}

          <Row customWidth={customWidth}>
            {icons &&
              icons.map((item, index) => {
                const border = `1px solid ${getGlobalStyle(
                  item.colorBorder as TTokens
                )}`
                const fontWeightText =
                  item.colorText === 'warning' ||
                  item.colorText === 'error' ||
                  item.colorText === 'success'
                    ? 'medium'
                    : 'regular'
                return (
                  <React.Fragment key={index}>
                    <Column
                      alignItems={alignItemColumn}
                      customWidth='10'
                      style={{ zIndex: 3 }}
                    >
                      <Container
                        alignItems='center'
                        background={getGlobalStyle(
                          item.colorBackIcon as TTokens
                        )}
                        backgroundColor={getGlobalStyle(
                          item.colorBackIcon as TTokens
                        )}
                        border={border}
                        borderRadius='50%'
                        justifyContent='center'
                        maxWidth={containerIconSize}
                        minHeight={containerIconSize}
                      >
                        <Icon
                          color={getGlobalStyle(item.colorIcon as TTokens)}
                          customSize={sizeIcon}
                          name={item.nameIcon}
                        />
                      </Container>
                      <BigScreen>
                        <>
                          <Spacer.Horizontal size={12} />
                          <Text
                            customColor={getGlobalStyle(
                              item.customColorText as TTokens
                            )}
                            fontWeight={fontWeightText}
                            textAlign='center'
                          >
                            {item.text}
                          </Text>
                        </>
                      </BigScreen>
                    </Column>
                  </React.Fragment>
                )
              })}
          </Row>
        </Column>
      </Row>
    </Container>
  )
}
