import React from 'react'
import { useRouter } from 'next/router'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'
import {
  eventClick,
  getParamsInURL,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { ImageCard } from './components/ImageCard'
import { IOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { ListProducts, statusPostPicking, statusPrePicking } from './components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { getTotalQuantityProducts } from 'shared/helpers'
import { GenericImage } from './components/GenericImage'
import styles from '../../OrderDetail.module.css'

interface ProductsProps {
  order: IOrder
  text?: string
}

export const Products = (props: ProductsProps): React.ReactElement => {
  const { isMobile } = useMobile()
  const router = useRouter()
  const { ticketNumber } = props.order
  let totalQuantityProducts = 0

  if (statusPrePicking.includes(props.text)) {
    const countItems = getTotalQuantityProducts(props.order.items)
    totalQuantityProducts = countItems
  }
  if (statusPostPicking.includes(props.text)) {
    const countItemsSent = getTotalQuantityProducts(props.order.itemsSent)
    const countItemsAdded = getTotalQuantityProducts(props.order.itemsAdded)
    totalQuantityProducts = countItemsSent + countItemsAdded
  }
  const paramsApp = getParamsInURL('source')
  const seller = getParamsInURL('seller')
  const addParamsInUrl = !paramsApp ? '' : '&source=APP'
  const underlineBtn = paramsApp ? 'none' : 'always'
  const quantityImages = isMobile ? 7 : 8
  const showImagesNumber =
    props.order.items?.length === quantityImages
      ? quantityImages
      : quantityImages - 1

  return (
    <>
      <BigScreen>
        <Container
          customWidth='100'
          isWrap
          tagName='article'
        >
          <ListProducts
            order={props.order}
            text={props.text}
          />
        </Container>
      </BigScreen>
      <SmallScreen>
        <Container
          clickable='pointer'
          isWrap
          onClick={() => {
            eventClick({
              event: 'interacciones-mis-compras',
              eventCategory: 'ver-todos-los-productos'
            })
            return router.push(
              `/ProductsDetails?og=${ticketNumber}&seller=${seller}${addParamsInUrl}`
            )
          }}
          padding='16px 0 16px 12px'
          tagName='article'
        >
          <Row
            margin='-3px 0px'
            padding='0 12px 0 0'
          >
            <Column>
              <Row>
                <Text
                  customColor={getGlobalStyle('--color-text-black')}
                  fontSize='md'
                  fontWeight='medium'
                >
                  Productos
                </Text>
                <Spacer.Vertical size={4} />
                <Text
                  customColor={getGlobalStyle('--color-text-gray-light')}
                  fontSize='md'
                >
                  ({totalQuantityProducts})
                </Text>
              </Row>
            </Column>
            <Column
              alignItems='end'
              justifyContent='end'
              margin='-1px 5px 0 0'
            >
              <Spacer.Horizontal size={4} />
              <Text
                fontSize='sm'
                underline={underlineBtn}
              >
                Ver mis productos
              </Text>
            </Column>
            <Column customWidth='06'>
              <Spacer.Horizontal size={2} />
              <Icon
                color={getGlobalStyle('--color-neutral-gray-dark')}
                customSize={20}
                name='ArrowRightNavigate2'
              />
            </Column>
          </Row>
          <Row
            className={`${styles.container} ${styles.witdSpacer} ${styles.gradient} `}
          >
            {props.order.items
              .filter((_item, _i) => {
                return _i < showImagesNumber
              })
              .map((_item) => {
                const { imageUrl } = _item

                return (
                  <React.Fragment key={`key-${_item.id}`}>
                    <ImageCard imageUrl={imageUrl} />
                  </React.Fragment>
                )
              })}
            {totalQuantityProducts > showImagesNumber &&
              props.order.items.length > quantityImages && (
                <GenericImage quantity={totalQuantityProducts} />
              )}
          </Row>
        </Container>
      </SmallScreen>
    </>
  )
}
