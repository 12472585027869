import {
  Column,
  Container,
  DesktopModalWrapper,
  MembershipHeader,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { BigScreen, getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { Image } from '@smu-chile/pkg-unimarc-components/stories/organisms/AddToListModal/Image'
import { CarouselBanners } from '@smu-chile/pkg-unimarc-components/stories/organisms/Banners/CarouselBanners'
import {
  currencyFormat,
  useSession,
  useUserSavings
} from '@smu-chile/pkg-unimarc-hooks'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import {
  setMembershipButtons,
  setMembershipData
} from 'shared/helpers/membershipHeader'
import { IMembershipDataCF } from 'shared/interfaces/IMembership'

interface MembershipHeaderBrowseProps {
  membershipDataCF?: IMembershipDataCF
  userData?: ResponseOrderFormProps
}

export const MembershipHeaderBrowse = ({
  membershipDataCF,
  userData
}: MembershipHeaderBrowseProps) => {
  const router = useRouter()
  const { isLoggedIn } = useSession()
  const [isOpen, setIsOpen] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [membershipUserData, setMembershipUserData] = useState(null)

  const userSavings = useUserSavings({
    reactQuery: {
      enabled: isLoggedIn,
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })

  const username = useMemo(() => {
    if (
      !!membershipUserData?.backgroundColor &&
      !!userData?.data?.clientProfileData?.firstName
    ) {
      return userData?.data?.clientProfileData?.firstName
    }

    return undefined
  }, [membershipUserData, userData])

  const accountNumber = currencyFormat({
    number: userSavings?.data?.totalSavings ?? 0,
    bool: false
  })

  const banners = membershipDataCF?.imageLevels?.map((banner) => {
    return { img: `https:${banner?.file?.url}` }
  })

  const handleOpenModal = (buttonLabel: string) => {
    setIsOpen(true)
    if (buttonLabel.includes('ahorr')) {
      setModalType('ahorro')
    }
    if (buttonLabel.includes('beneficio')) {
      setModalType('beneficio')
    }
  }

  const handleClickModalButton = () => {
    if (modalType === 'ahorro') {
      if (membershipUserData?.userLevel === 'Socio Club') {
        handleCloseModal()
      } else {
        router.push(process.env.NEXT_PUBLIC_HOME_ALLIANCES_URL)
      }
    }
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setMembershipUserData(setMembershipData(membershipDataCF?.membershipLevels))
  }, [membershipDataCF])

  return (
    <BigScreen>
      <>
        <MembershipHeader
          buttons={setMembershipButtons(
            membershipDataCF?.headerButtons?.items,
            accountNumber,
            handleOpenModal
          )}
          fontColor={membershipUserData?.fontColor}
          mainBackgroundColor={membershipUserData?.backgroundColor}
          type='full'
          userIcon={membershipUserData?.img}
          userLevel={membershipUserData?.userLevel}
          userName={username}
        />
        <DesktopModalWrapper
          blockId={getBemId('membership', 'modal')}
          bodyChildrenProps={{
            minHeight: modalType === 'ahorro' ? 'none' : '442px'
          }}
          borderEdge={modalType === 'ahorro' ? 'none' : 'bottom'}
          footerChildrenProps={{
            default: true,
            buttonDefaultProps: {
              label:
                membershipUserData?.userLevel === 'Socio Club'
                  ? 'Entendido'
                  : 'Ver panoramas'
            },
            onClick: handleClickModalButton
          }}
          hasfooter={modalType === 'ahorro' ? true : false}
          headerCloseIcon={{
            default: true,
            name: 'CloseThin',
            sizes: '2xs'
          }}
          headerTitle={modalType === 'ahorro' ? null : 'Mis beneficios'}
          modalConfigsProps={{
            isOpen,
            toggle: handleCloseModal,
            toggleOutside: handleCloseModal,
            minHeight: '500px'
          }}
          styleProps={{
            height: '500px'
          }}
        >
          {modalType === 'ahorro' && (
            <Column
              alignItems='center'
              gap='18px'
              margin='-20px 0 0'
            >
              <Text
                fontSize='xl'
                fontWeight='medium'
                id={getBemId('modal', 'title', 'ahorro')}
              >
                ¡Felicidades!
              </Text>
              <Container id={getBemId('modal', 'image', 'ahorro')}>
                <Image
                  altPicture='Club Unimarc image'
                  srcPicture={membershipDataCF?.imageSaving}
                />
              </Container>
              <Text
                id={getBemId('modal', 'text', 'ahorro')}
                textAlign='center'
              >
                Haz ahorrado {accountNumber} en tus compras realizadas en las
                tiendas de Unimarc, app y web.
              </Text>
            </Column>
          )}
          {modalType === 'beneficio' && (
            <Container>
              <CarouselBanners
                banners={[...banners]}
                infinite={false}
                itemsToShow={1}
                minHeight='500px'
                showDots={false}
              />
            </Container>
          )}
        </DesktopModalWrapper>
      </>
    </BigScreen>
  )
}
