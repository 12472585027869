import {
  Backbone,
  Container,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ContainerPages } from 'components/ContainerPages'

export const CancelPurshaseLoader = () => {
  return (
    <>
      <Container
        alignItems='center'
        backgroundColor={getGlobalStyle('--color-background-white')}
        isWrap
        justifyContent='center'
        tagName='main'
      >
        <BigScreen>
          <ContainerPages>
            <Container
              alignItems='center'
              backgroundColor={getGlobalStyle('--color-base-white')}
              borderRadius={getGlobalStyle('--border-radius-md')}
              customHeight='auto'
              isWrap
              justifyContent='center'
            >
              <Row padding='24px 32px'>
                <Backbone
                  borderRadius={getGlobalStyle('--border-radius-md')}
                  height={600}
                  width={100}
                />
              </Row>
            </Container>
          </ContainerPages>
        </BigScreen>
      </Container>
      <SmallScreen>
        <Container
          alignItems='center'
          isWrap
          justifyContent='center'
        >
          <Row
            isWrap
            padding='50px 15px'
          >
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-md')}
              height={50}
              width={100}
            />
            <Spacer.Horizontal size={16} />
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-md')}
              height={330}
              width={100}
            />
            <Spacer.Horizontal size={16} />
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-md')}
              height={70}
              width={100}
            />
          </Row>
        </Container>
      </SmallScreen>
    </>
  )
}
