import {
  Column,
  Container,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getGlobalStyle,
  SmallScreen,
  BigScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ContainerPages } from 'components/ContainerPages'
import { ImagePage } from './ImagePage'
import { TextPage } from './TextPage'
import { ButtonFirst, ButtonProps } from './ButtonFirst'
import { TitlePage } from './TitlePage'
import { IMembershipDataCF } from 'shared/interfaces/IMembership'
import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { AlignItems } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'

interface ReloadPageProps {
  buttonProps: ButtonProps
  code?: string
  imagePage: string
  membershipDataCF?: IMembershipDataCF
  textPrimary: string
  textSecondary: string
  title?: string
  subtitle?: string
  alignTitle?: AlignItems
  userData?: ResponseOrderFormProps
  widthTextSecondaryDesktop?: string
  widthTextSecondaryMobile?: string
}
export const ReloadPage = ({
  alignTitle,
  buttonProps,
  code,
  imagePage,
  membershipDataCF,
  textPrimary,
  textSecondary,
  title = 'Socio Diamante',
  subtitle,
  userData,
  widthTextSecondaryDesktop,
  widthTextSecondaryMobile
}: ReloadPageProps) => {
  return (
    <>
      <BigScreen>
        <ContainerPages
          membershipDataCF={membershipDataCF}
          userData={userData}
        >
          <Column
            backgroundColor={getGlobalStyle('--color-base-white')}
            border={`${getGlobalStyle(
              '--border-width-3xs'
            )} solid ${getGlobalStyle('--color-neutral-gray-divider')}`}
            borderRadius={getGlobalStyle('--border-radius-md')}
            customHeight='623px'
            padding='24px 32px'
          >
            <TitlePage
              alignItemsTitle={alignTitle}
              title={title}
            />
            {subtitle && (
              <>
                <Spacer.Horizontal size={8} />
                <Column alignItems='start'>
                  <Text
                    customColor={getGlobalStyle('--color-neutral-gray-dark')}
                    id={getBemId('reloadPage', 'subtitle')}
                  >
                    {subtitle}
                  </Text>
                </Column>
              </>
            )}

            <Spacer.Horizontal customSize={19} />
            <div
              style={{
                border: `0.3px solid ${getGlobalStyle(
                  '--color-neutral-gray-divider'
                )}`,
                width: '100%'
              }}
            />
            <Spacer.Horizontal size={160} />
            <ImagePage image={imagePage} />
            <Spacer.Horizontal size={16} />
            <TextPage
              codeError={code}
              textPrimary={textPrimary}
              textSecondary={textSecondary}
              widthTextSecondaryDesktop={widthTextSecondaryDesktop}
            />
            {/* Texto */}
            <Spacer.Horizontal customSize={60} />
            {/* boton  */}
            <ButtonFirst
              onClickFirstButton={buttonProps.onClickFirstButton}
              onClickSecondaryButton={buttonProps.onClickSecondaryButton}
              prevIcon={buttonProps.prevIcon}
              prevIconColor={buttonProps.prevIconColor}
              prevIconSize={buttonProps.prevIconSize}
              textFirstButton={buttonProps.textFirstButton}
              textSecondaryButton={buttonProps.textSecondaryButton}
            />
            <Spacer.Horizontal customSize={212} />
          </Column>
        </ContainerPages>
      </BigScreen>

      <SmallScreen>
        <>
          <TitlePage title={title} />
          <Container
            isWrap
            margin='16px'
          >
            <Spacer.Horizontal customSize={78} />
            <ImagePage image={imagePage} />
            <Spacer.Horizontal size={24} />
            <TextPage
              codeError={code}
              textPrimary={textPrimary}
              textSecondary={textSecondary}
              widthTextSecondaryMobile={widthTextSecondaryMobile}
            />
            {/* Texto */}
            <Spacer.Horizontal customSize={73} />
            {/* boton  */}
            <ButtonFirst
              onClickFirstButton={buttonProps.onClickFirstButton}
              onClickSecondaryButton={buttonProps.onClickSecondaryButton}
              prevIcon={buttonProps.prevIcon}
              prevIconColor={buttonProps.prevIconColor}
              prevIconSize={buttonProps.prevIconSize}
              textFirstButton={buttonProps.textFirstButton}
              textSecondaryButton={buttonProps.textSecondaryButton}
            />
          </Container>
        </>
      </SmallScreen>
    </>
  )
}
