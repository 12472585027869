import { Container, Picture } from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'

interface ImageZendeskProps {
  srcImageZendesk: string
}

export const ImageZendesk = ({ srcImageZendesk }: ImageZendeskProps) => {
  const { isMobile } = useMobile()
  return (
    <Container
      alignItems='center'
      alignSelf='center'
      customWidth='auto'
      id={getBemId('imageZendesk', 'container')}
      width='auto'
    >
      <Picture
        alt='ticket'
        height={isMobile ? '32px' : '40px'}
        id={getBemId('imageZendesk', 'picture')}
        src={srcImageZendesk}
        width={isMobile ? '32px' : '40px'}
      />
    </Container>
  )
}
