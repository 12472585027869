import { Column, Container } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { OrderAction, OrderIcon, OrderInfo } from '../components'
import { orderIconTheme } from 'shared/helpers/orderIconTheme'
import { eventClick } from '@smu-chile/pkg-unimarc-hooks'
import { Order } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewOrders'

interface ShelfListProps {
  order: Order
  sourceFlow?: string
  objetSrcImagesOrders?: {
    srcCancel: string
    srcStore: string
    srcMoto: string
  }
}

export const ShelfList = ({
  order,
  sourceFlow,
  objetSrcImagesOrders
}: ShelfListProps): React.ReactElement => {
  const srcImageOrder = orderIconTheme(
    order,
    objetSrcImagesOrders?.srcCancel,
    objetSrcImagesOrders?.srcMoto,
    objetSrcImagesOrders?.srcStore
  )
  const { orderId, addressType } = order || {}
  let sizeImgIcon = 40
  if (addressType === 'pickup') {
    sizeImgIcon = 44
  }
  const arrayOrder = orderId.split('-')
  const ticketNumber = arrayOrder[0]
  const seller = arrayOrder[1]

  return (
    <>
      <Container
        alignItems='center'
        backgroundColor={getGlobalStyle('--color-background-white')}
        border={`${getGlobalStyle('--border-width-3xs')} solid ${getGlobalStyle(
          '--color-neutral-gray-divider'
        )}`}
        borderRadius={getGlobalStyle('--border-radius-md')}
        clickable='pointer'
        customHeight='125px'
        customWidth='848px'
        height='125px'
        justifyContent='center'
        margin='12px 0'
        maxWidth='848px'
        minHeight='125px'
        onClick={() => {
          eventClick({
            event: 'interacciones-mis-compras',
            eventCategory: 'ver compra'
          })
          const sourceHelpCenter =
            sourceFlow === 'ClaimFlow' ? '&HelpCenter=true' : ''
          window.location.href = `/${sourceFlow}?og=${ticketNumber}&seller=${seller}${sourceHelpCenter}`
        }}
        padding='10px 24px'
        width='848px'
      >
        <Column
          alignSelf='center'
          clickable='pointer'
          maxWidth='60px'
        >
          <OrderIcon
            customSizeIcon={sizeImgIcon}
            srcImageOrder={srcImageOrder}
          />
        </Column>

        <Column
          clickable='pointer'
          padding='0px 0px 0px 16px'
        >
          <OrderInfo order={order} />
        </Column>

        <Column
          alignItems='end'
          clickable='pointer'
          maxWidth='323px'
        >
          <OrderAction
            order={order}
            sourceFlow={sourceFlow}
          />
        </Column>
      </Container>
    </>
  )
}
