import { useRouter } from 'next/router'
import {
  Chip,
  Column,
  Container,
  Icon,
  IconProps,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { getSettingsOrderDetail } from 'components/SideNav/helper/getSettingsOrderDetail'
import { TFontSize } from '@smu-chile/pkg-unimarc-components/shared/interfaces/TFontSize'
import { normalizeString, toCamelCase } from '@smu-chile/pkg-unimarc-hooks'

export interface NavItemProps {
  active?: boolean
  fontSize?: TFontSize
  icon: IconProps['name']
  isLogout: boolean
  labelColorText?: string
  labelColorBackground?: string
  labelText?: string
  showLabel?: boolean
  strokeWidthIcon?: string
  text: string
  url?: string
  sendEvent?: () => void
}

export const NavItem = ({
  active,
  fontSize = 'md',
  isLogout,
  icon,
  labelColorText,
  labelColorBackground,
  labelText,
  showLabel,
  strokeWidthIcon = '1',
  text,
  url = '/',
  sendEvent
}: NavItemProps): React.ReactElement<NavItemProps> => {
  const router = useRouter()
  const containerPadding = active ? '0 4px' : '0'
  const rowPadding = active ? '0 8px' : '0 12px'

  const { handleLogout } = getSettingsOrderDetail()

  const handleClick = () => {
    // sendEvent - a function wich call for sending analytic's events
    if (sendEvent) sendEvent()
    if (isLogout) {
      return handleLogout()
    } else if (router.pathname === url) {
      return router.reload()
    }
    return router.push(url)
  }

  return (
    <Container
      alignItems='center'
      clickable='pointer'
      id={getBemId('Menu_Lateral', toCamelCase(normalizeString(text)))}
      minHeight='56px'
      padding={containerPadding}
    >
      <Row
        alignItems='center'
        backgroundColor={
          active ? getGlobalStyle('--color-background-gray-light') : undefined
        }
        borderRadius={getGlobalStyle('--border-radius-full')}
        clickable='pointer'
        justifyContent='between'
        minHeight='40px'
        onClick={handleClick}
        padding={rowPadding}
        tagName='section'
      >
        <Row
          alignItems='center'
          clickable='pointer'
        >
          <Icon
            clickable='pointer'
            color='black'
            customSize={20}
            name={icon}
            sizes='md'
            strokeWidth={strokeWidthIcon}
          />
          <Spacer.Vertical size={12} />
          <Text
            clickable='pointer'
            color='black'
            fontSize={fontSize}
            underline='none'
          >
            {text}
          </Text>
          {showLabel && (
            <>
              <Spacer.Vertical size={8} />
              <Column customWidth='30'>
                <Spacer.Horizontal size={2} />
                <Chip
                  backgroundColor={labelColorBackground}
                  borderRadius='42px'
                  color={labelColorText}
                  fontSize={9}
                  height='20px'
                  label={labelText}
                />
              </Column>
            </>
          )}
        </Row>
        {!isLogout && (
          <Icon
            clickable='pointer'
            color={getGlobalStyle('--color-text-gray-light')}
            customSize={18}
            name='ArrowRight'
            strokeWidth='1'
          />
        )}
      </Row>
    </Container>
  )
}
